import React, { useState } from "react";
import { Container, Row, Card, CardBody, Col } from "reactstrap";
import CompHeader from "components/Headers/CompHeader.js";
import {
	ComposableMap,
	Geographies,
	Geography,
	ZoomableGroup,
} from "react-simple-maps";
import ReactTooltip from "react-tooltip";
const INDIA_TOPO_JSON = require("../../india-districts.json");
const PROJECTION_CONFIG = {
	scale: 350,
	center: [78.9629, 22.5937],
};

const data = require("../../covid-districts.json");

const DEFAULT_COLOR = "#EEE";

const geographyStyle = {
	default: {
		outline: "none",
	},
	hover: {
		fill: "#ccc",
		transition: "all 250ms",
		outline: "none",
	},
	pressed: {
		outline: "none",
	},
};

// const colorScale = scaleQuantile()
// .domain(data.map(d => d.value))
// .range(COLOR_RANGE);

const CovidMap = (props) => {
	const [tooltipContent, setTooltipContent] = useState("");

	const onMouseEnter = (geo, current = { zone: "NA" }) => {
		console.log("dfd");
		// return () => {
		setTooltipContent(`${geo.properties.NAME_2}: ${current.zone}`);
		// };
	};

	const onMouseLeave = () => {
		setTooltipContent("");
	};

	//  console.log('data',data, 'indi',INDIA_TOPO_JSON)
	return (
		<>
			<CompHeader />
			<Container fluid>
				<Row className="mt-5">
					<Col className="mb-5 mb-xl-0" xl="12">
						<Card className="shadow">
							<CardBody>
								<h2>Covid Map</h2>
								<ComposableMap
									projectionConfig={PROJECTION_CONFIG}
									projection="geoMercator"
									width={600}
									height={220}
									data-tip=""
								>
									<ZoomableGroup zoom={1}>
										<Geographies geography={INDIA_TOPO_JSON}>
											{({ geographies }) =>
												geographies.map((geo) => {
													// console.log('geo',geo,' data',data)
													const current = data.zones.find(
														(s) => s.district === geo.properties.NAME_2
													);
													//console.log("Cure",current ? this.colorScale(current.value):"")
													return (
														<Geography
															key={geo.rsmKey}
															geography={geo}
															fill={current ? current.zone : DEFAULT_COLOR}
															style={geographyStyle}
															onMouseEnter={() => onMouseEnter(geo, current)}
															onMouseLeave={() => onMouseLeave}
														/>
													);
												})
											}
										</Geographies>
									</ZoomableGroup>
								</ComposableMap>
								<ReactTooltip>{tooltipContent}</ReactTooltip>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</>
	);
};

export default CovidMap;
