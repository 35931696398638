import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { Container } from "reactstrap";
import routes from "routes.js";
class Open extends React.Component {
	componentDidUpdate(e) {
		document.documentElement.scrollTop = 0;
		document.scrollingElement.scrollTop = 0;
		this.refs.mainContent.scrollTop = 0;
	}
	getRoutes = (routes) => {
		return routes.map((prop, key) => {
			if (prop.layout === "/open") {
				return (
					<Route
						path={prop.layout + prop.path}
						component={prop.component}
						key={key}
					/>
				);
			} else {
				return null;
			}
		});
	};

	render() {
		return (
			<>
				<div className="main-content" ref="mainContent">
					<Switch>
						{this.getRoutes(routes)}
						<Redirect
							from="*"
							to="/open/approve_inventory/5ec4f44941ad32bcd7e92522/5ea689e8d7756058c750eb0b"
						/>
					</Switch>
					<Container fluid>{/* <AdminFooter /> */}</Container>
				</div>
			</>
		);
	}
}

export default Open;
