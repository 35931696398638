import React from "react";
import {
	Container,
	Card,
	Form,
	FormGroup,
	InputGroup,
	InputGroupAddon,
	Input,
	InputGroupText,
	Button,
	CardBody,
	NavItem,
	NavLink,
	Nav,
	TabContent,
	Modal,
	Progress,
	TabPane,
} from "reactstrap";
import Select, { createFilter } from "react-select";
import XLSX from "xlsx";
import ReactDatetime from "react-datetime";
import BootstrapTable from "react-bootstrap-table-next";
// core components
import CompHeader from "components/Headers/CompHeader.js";
import classnames from "classnames";
import {
	fetchCampaigns,
	fetchDistinctFilters,
	addCampaign,
	deleteCamps,
} from "actions/campActions";
import { fetchUserDetails } from "actions/userActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Moment from "react-moment";
import moment from "moment";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { validateForm } from "../../helpers/validations";
import { nFormatter } from "../../helpers/helpers";
import HasPolicies from "../../helpers/hasPolicies";
import { Link } from "react-router-dom";
import _ from "lodash";
import { CSVLink } from "react-csv";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { Trans } from "react-i18next";
import i18n from "../../i18n";

const { SearchBar } = Search;

function dateFormatter(cell, row) {
	return <Moment format="MMMM Do YYYY">{cell}</Moment>;
}

function suggButtonFormatter(cell, row) {
	return (
		<Link to={"/admin/sugg_inventory/" + cell}>
			<Button className="btn-icon btn-2" color="primary" type="button">
				<span className="btn-inner--icon">
					<i className="fas fa-plus" />
				</span>
			</Button>
		</Link>
	);
}

function bookButtonFormatter(cell, row) {
	return (
		<Link to={"/admin/booked_inventory/" + row._id}>
			<Button className="btn-icon btn-2" color="primary" type="button">
				<span className="btn-inner--icon">
					<i className="ni ni-bold-right" />
				</span>
			</Button>
		</Link>
	);
}

function editButtonFormatter(cell, row) {
	return (
		<Link to={"/admin/edit_campaign/" + row._id}>
			<Button className="btn-icon btn-2" color="info" type="button">
				<span className="btn-inner--icon">
					<i className="fas fa-edit" />
				</span>
			</Button>
		</Link>
	);	
}

function ProgressFormatter(cell, row) {
	return (
		<Progress
			max="100"
			style={{ width: "auto" }}
			value={cell}
			color="primary"
		/>
	);
}

function budgetFormatter(cell, row) {
	return nFormatter(cell, 1);
}

function createdFormatter(cell, row) {
	if (cell) {
		return cell.email;
	}
	return "";
}

function stateFormatter(cell, row) {
	return cell.join(", ");
}

var columns = [
	{
		key: "name",
		dataField: "name",
		text: <Trans>Campaign Name</Trans>,
	},
	{
		key: "brand",
		dataField: "brand",
		text: <Trans>Brand</Trans>,
	},
	{
		key: "created_by",
		dataField: "created_by",
		text: <Trans>Created By</Trans>,
		formatter: createdFormatter,
	},
	{
		key: "budget",
		dataField: "budget",
		text: <Trans>Budget</Trans>,
		formatter: budgetFormatter,
	},
	{
		key: "start_date",
		dataField: "start_date",
		text: <Trans>Start Date</Trans>,
		formatter: dateFormatter,
	},
	{
		key: "end_date",
		dataField: "end_date",
		text: <Trans>End Date</Trans>,
		formatter: dateFormatter,
	},
	{
		key: "states",
		dataField: "states",
		text: <Trans>States</Trans>,
		formatter: stateFormatter,
	},
	{
		key: "bd_progress",
		dataField: "bd_progress",
		text: <Trans>Budget Used</Trans>,
		formatter: ProgressFormatter,
	},

	{
		key: "see_plan",
		dataField: "id",
		text: <Trans>See Plan</Trans>,
		formatter: bookButtonFormatter,
	},
];

class TableComponent extends React.Component {
	state = {
		exampleModal: false,
		brand: "",
		campaign_name: "",
		start_date: null,
		end_date: null,
		budget: "",
		states: null,
		cities: null,
		media_type: "",
		errors: {},
		toSuggInventory: false,
		deleteModal: false,
		active_camp: "",
		plan: "",
		selectedCamps: [],
	};

	toggleModal = (state) => {
		this.setState({
			[state]: !this.state[state],
		});
	};

	componentDidUpdate(prevProps, prevState) {
		if (this.props.adding_camp !== prevProps.adding_camp) {
			this.setState({ exampleModal: false });
		}

		if (this.props.active_camp !== prevProps.active_camp) {
			// console.log("campId", this.props.active_camp);
			const campId = this.props.active_camp._id;
			// console.log("campId", campId);
			this.setState({ active_camp: campId, toSuggInventory: true });
			// this.props.history.push("/admin/sugg_inventory/" + campId);
			// window.location.href = "http://localhost:3000/admin/sugg_inventory/"+campId
		}
	}

	componentDidMount() {
		if (
			HasPolicies(["add_to_plan_policy"]) &&
			!columns.find((c) => c.text_key === "add_to_plan")
		) {
			columns = columns.concat({
				dataField: "_id",
				text: <Trans>Add To Plan</Trans>,
				formatter: suggButtonFormatter,
				text_key: "add_to_plan",
			});
		}

		if (
			HasPolicies(["campaign_update_policy"]) &&
			!columns.find((c) => c.text_key === "edit_plan")
		) {
			columns = columns.concat({
				dataField: "id",
				text: <Trans>Edit Plan</Trans>,
				formatter: editButtonFormatter,
				text_key: "edit_plan",
			});
		}
	}

	handleOnSelect = (row, isSelect) => {
		if (isSelect) {
			this.setState(() => ({
				selected: [...this.state.selected, row.id],
			}));
		} else {
			this.setState(() => ({
				selected: this.state.selected.filter((x) => x !== row.id),
			}));
		}
	};

	handleOnSelectAll = (isSelect, rows) => {
		const ids = rows.map((r) => r.id);
		if (isSelect) {
			this.setState(() => ({
				selected: ids,
			}));
		} else {
			this.setState(() => ({
				selected: [],
			}));
		}
	};

	handleInputChange = (e) => {
		const target = e.target;
		const name = target.name;
		const value = target.value;

		var errors = { ...this.state.errors };
		errors[name] = null;

		this.setState({
			[name]: value,
			errors: errors,
		});
	};

	handleFieldChange = (key, val) => {
		var errors = { ...this.state.errors };
		errors[key] = null;

		if (key === "states") {
			const { fetchDistinctFilters } = this.props;
			const fields = [
				{ type: "dependent", val: "city" },
				{ type: "original", val: "state" },
			];
			const states = val ? val.map((v) => v.label) : [];
			const query = { state: { $in: states } };
			fetchDistinctFilters({ fields: fields, query: query });
		}

		this.setState({ [key]: val, errors: errors });
	};

	handlePlanChange = (val) => {
		var errors = { ...this.state.errors };
		errors["plan"] = null;
		this.setState({ plan: val, errors: errors });
	};

	onSubmit = (data) => { };

	isValid = () => {
		const {
			brand,
			campaign_name,
			start_date,
			end_date,
			budget,
			cities,
			media_type,
			states,
		} = this.state;
		const { createCampaign } = this.props;

		var errors = validateForm([
			{ field: "brand", value: brand, validations: ["Required"] },
			{
				field: "campaign_name",
				value: campaign_name,
				validations: ["Required"],
			},
			{ field: "start_date", value: start_date, validations: ["ValidDate"] },
			{ field: "end_date", value: end_date, validations: ["ValidDate"] },
			{ field: "budget", value: budget, validations: ["Required"] },
			{ field: "states", value: states, validations: ["Required"] },
			{ field: "cities", value: cities, validations: ["Required"] },
			{ field: "media_type", value: media_type, validations: ["Required"] },
		]);

		// console.log(errors);

		if (
			!errors["end_date"] &&
			!errors["start_date"] &&
			start_date.isAfter(end_date)
		) {
			errors["end_date"] = "End Date should be after Start Date";
		}

		if (!_.isEmpty(errors)) {
			this.setState({ errors: errors });
		} else {
			console.log("ts", this.state);
			createCampaign(this.state);
		}
	};

	validDate = (current) => {
		var yesterday = moment().subtract(1, "day");
		return current.isAfter(yesterday);
	};

	deleteCamps = () => {
		const { selectedCamps } = this.state;
		const { deleteCamps } = this.props;
		const campIds = selectedCamps.map((s) => s._id);
		deleteCamps({ campIds });
		this.toggleModal("deleteModal");
		this.setState({ selectedCamps: [] });
	};

	getCampaignsData = () => {
		const { campaigns } = this.props;
		var final = [];
		campaigns.forEach((c) => {
			var obj = Object.assign({}, c);
			obj["start_date"] = moment(obj["start_date"]).format("ll");
			obj["end_date"] = moment(obj["end_date"]).format("ll");
			obj["created_by"] = obj["created_by"] ? obj["created_by"]["email"] : "";
			obj["created_at"] = moment(obj["created_at"]).format("ll");
			obj = _.omit(obj, [
				"media_types",
				"status",
				"camp_type",
				"_id",
				"edited_at",
				"__v",
				"booked_inventory",
			]);
			final.push(obj);
		});
		return final;
	};

	newExcelSheet = () => {
		var exportFile = this.getCampaignsData()
		var wb = XLSX.utils.book_new()
		var ws = XLSX.utils.json_to_sheet(exportFile)

		XLSX.utils.book_append_sheet(wb, ws, "sheet1");
		XLSX.writeFile(wb, "Inventory Campaigns.xlsx");
	}

	render() {
		const {
			campaigns,
			adding_camp,
			filters,
			showCreateBtn,
			showPlan,
			showProgress,
		} = this.props;

		const {
			brand,
			campaign_name,
			start_date,
			end_date,
			budget,
			errors,
			selectedCamps,
		} = this.state;

		const selectRow = {
			mode: "checkbox",
			clickToSelect: true,
			onSelect: (row, isSelect, rowIndex, e) => {
				if (isSelect) {
					let selCamps = selectedCamps.concat(campaigns[rowIndex]);
					this.setState({ selectedCamps: selCamps });
				} else {
					let selC = campaigns[rowIndex];
					let selCamps = selectedCamps.filter((s) => s._id !== selC._id);
					this.setState({ selectedCamps: selCamps });
				}
			},
			onSelectAll: (isSelect, rows, e) => {
				if (isSelect) {
					this.setState({ selectedCamps: rows });
				} else {
					this.setState({ selectedCamps: [] });
				}
			},
		};

		const mColumns = [];

		columns.forEach((c) => {
			if (
				(!showPlan && c.key === "see_plan") ||
				(!showProgress && c.key === "bd_progress")
			) {
			} else {
				mColumns.push(c);
			}
		});

		let distMediaTypes = [],
			distStates = [],
			distCities = [];

		distMediaTypes = [
			{ label: "All", value: "all" },
			{ label: "Traditional OOH", value: "ooh" },
			{ label: "Digital OOH", value: "digital" },
		];

		if (filters) {
			const { state, city } = filters;

			if (state) {
				distStates = state.map((m) => ({ label: m, value: m }));
				distStates = _.sortBy(distStates, "label");
			}

			if (city) {
				distCities = city.map((m) => ({ label: m, value: m }));

				distCities = _.sortBy(distCities, "label");

				distCities = _.uniqBy(distCities, "value");

				distCities.unshift({ label: "All", value: "all" });
			}
		}

		return (
			<ToolkitProvider
				keyField="_id"
				data={campaigns}
				columns={mColumns}
				exportCSV
				condensed
				search
			>
				{(props) => (
					<div>
						{HasPolicies(["export data policy"]) && (
							// <CSVLink
							// 	style={{ color: "#fff", width: "100%" }}
							// 	target=""
							// 	data={this.getCampaignsData()}
							// 	filename={"Campaigns Data.csv"}
							// >
							// 	<Button type="button" className="btn btn-default">
							// 		<Trans>Export CSV</Trans>!!
							// 	</Button>
							// </CSVLink>
							<Button type="button" className="btn btn-default" onClick={this.newExcelSheet}>
								<Trans>Export Excel</Trans>!!
							</Button>
						)}


						{HasPolicies(["create campaign policy"]) && (
							<Button
								className={
									showCreateBtn ? "btn-icon btn-3 pull-right" : "d-none"
								}
								style={{ marginLeft: "5px" }}
								color="primary"
								type="button"
								onClick={() => this.toggleModal("exampleModal")}
							>
								<span className="btn-inner--icon">
									<i className="ni ni-bag-17" />
								</span>
								<span className="btn-inner--text">
									<Trans>Create Campaign</Trans>
								</span>
							</Button>
						)}

						{HasPolicies(["delete_campaign_policy"]) &&
							selectedCamps &&
							selectedCamps.length > 0 && (
								<Button
									className={"btn-icon btn-3 pull-right"}
									color="danger"
									type="button"
									onClick={() => this.toggleModal("deleteModal")}
								>
									<span className="btn-inner--icon">
										<i className="fas fa-trash" />
									</span>
									<span className="btn-inner--text">
										<Trans>Delete Campaigns</Trans>
									</span>
								</Button>
							)}
						<SearchBar {...props.searchProps} style={{ marginTop: "10px" }} />
						<hr />
						<h3>
							<Trans>Total Campaigns Created</Trans>
							{"(" + this.getCampaignsData().length + ")"}
						</h3>
						<BootstrapTable
							wrapperClasses="table-responsive" // This is the style provided by bootstrap 4, this will set the parent div with that class
							{...props.baseProps}
							selectRow={selectRow}
						/>

						{/* Add Campaign Modal */}
						<Modal
							className="modal-dialog-centered"
							isOpen={this.state.exampleModal}
							toggle={() => this.toggleModal("exampleModal")}
						>
							<div
								className="modal-header text-center"
								style={{ background: "#fefefe" }}
							>
								<h3 className="modal-title" id="exampleModalLabel">
									<Trans>Create Campaign</Trans>
								</h3>
							</div>
							<div className="modal-body">
								<CardBody className="px-lg-2 py-lg-2">
									<Form role="form">
										<FormGroup className="mb-3">
											<InputGroup className="input-group-alternative">
												<InputGroupAddon addonType="prepend">
													<InputGroupText>
														<i className="ni ni-email-83" />
													</InputGroupText>
												</InputGroupAddon>
												<Input
													placeholder={i18n.t("Brand")}
													type="text"
													name="brand"
													onChange={this.handleInputChange}
													value={brand}
												/>
											</InputGroup>
											{errors.brand && (
												<small style={{ color: "#f5365c" }}>
													{" "}
													<span className="fa fa-exclamation-circle">
														{" "}
													</span>{" "}
													{errors.brand}
												</small>
											)}
										</FormGroup>
										<FormGroup className="mb-3">
											<InputGroup className="input-group-alternative">
												<InputGroupAddon addonType="prepend">
													<InputGroupText>
														<i className="ni ni-email-83" />
													</InputGroupText>
												</InputGroupAddon>
												<Input
													placeholder={i18n.t("Campaign Name")}
													type="text"
													name="campaign_name"
													onChange={this.handleInputChange}
													value={campaign_name}
												/>
											</InputGroup>
											{errors.campaign_name && (
												<small style={{ color: "#f5365c" }}>
													{" "}
													<span className="fa fa-exclamation-circle">
														{" "}
													</span>{" "}
													{errors.campaign_name}
												</small>
											)}
										</FormGroup>
										<FormGroup className="mb-3">
											<InputGroup className="input-group-alternative">
												<InputGroupAddon addonType="prepend">
													<InputGroupText>
														<i className="ni ni-calendar-grid-58" />
													</InputGroupText>
												</InputGroupAddon>
												<ReactDatetime
													inputProps={{
														placeholder: i18n.t("Start Date"),
													}}
													timeFormat={false}
													dateFormat={"ll"}
													onChange={this.handleFieldChange.bind(
														this,
														"start_date"
													)}
													value={start_date}
													isValidDate={this.validDate}
													closeOnSelect={true}
												/>
											</InputGroup>
											{errors.start_date && (
												<small style={{ color: "#f5365c" }}>
													{" "}
													<span className="fa fa-exclamation-circle">
														{" "}
													</span>{" "}
													{errors.start_date}
												</small>
											)}
										</FormGroup>
										<FormGroup className="mb-3">
											<InputGroup className="input-group-alternative">
												<InputGroupAddon addonType="prepend">
													<InputGroupText>
														<i className="ni ni-calendar-grid-58" />
													</InputGroupText>
												</InputGroupAddon>
												<ReactDatetime
													inputProps={{
														placeholder: i18n.t("End Date"),
													}}
													timeFormat={false}
													dateFormat={"ll"}
													onChange={this.handleFieldChange.bind(
														this,
														"end_date"
													)}
													value={end_date}
													isValidDate={this.validDate}
													closeOnSelect={true}
												/>
											</InputGroup>
											{errors.end_date && (
												<small style={{ color: "#f5365c" }}>
													{" "}
													<span className="fa fa-exclamation-circle">
														{" "}
													</span>{" "}
													{errors.end_date}
												</small>
											)}
										</FormGroup>
										<FormGroup className="mb-3">
											<InputGroup className="input-group-alternative">
												<InputGroupAddon addonType="prepend">
													<InputGroupText>
														<i className="ni ni-email-83" />
													</InputGroupText>
												</InputGroupAddon>
												<Input
													placeholder={i18n.t("Budget")}
													type="number"
													name="budget"
													onChange={this.handleInputChange}
													value={budget}
												/>
												<InputGroupAddon addonType="append">
													<InputGroupText>
														<span style={{ fontWeight: "bold" }}>
															{nFormatter(budget, 1)}
														</span>
													</InputGroupText>
												</InputGroupAddon>
											</InputGroup>
											{errors.budget && (
												<small style={{ color: "#f5365c" }}>
													{" "}
													<span className="fa fa-exclamation-circle">
														{" "}
													</span>{" "}
													{errors.budget}
												</small>
											)}
										</FormGroup>
										<FormGroup className="mb-3">
											<Select
												placeholder={i18n.t("Media Type")}
												className="input-group-alternative"
												filterOption={createFilter({ ignoreAccents: false })} // this makes all the difference!
												options={distMediaTypes}
												onChange={this.handleFieldChange.bind(
													this,
													"media_type"
												)}
											/>
											{errors.media_types && (
												<small style={{ color: "#f5365c" }}>
													{" "}
													<span className="fa fa-exclamation-circle">
														{" "}
													</span>{" "}
													{errors.media_types}
												</small>
											)}
										</FormGroup>
										<FormGroup>
											<Select
												isMulti
												placeholder={i18n.t("States")}
												className="input-group-alternative"
												filterOption={createFilter({ ignoreAccents: false })} // this makes all the difference!
												options={distStates}
												onChange={this.handleFieldChange.bind(this, "states")}
											/>
											{errors.states && (
												<small style={{ color: "#f5365c" }}>
													{" "}
													<span className="fa fa-exclamation-circle">
														{" "}
													</span>{" "}
													{errors.states}
												</small>
											)}
										</FormGroup>
										<FormGroup>
											<Select
												isMulti
												placeholder={i18n.t("Cities")}
												className="input-group-alternative"
												filterOption={createFilter({ ignoreAccents: false })} // this makes all the difference!
												options={distCities}
												onChange={this.handleFieldChange.bind(this, "cities")}
											/>
											{errors.cities && (
												<small style={{ color: "#f5365c" }}>
													{" "}
													<span className="fa fa-exclamation-circle">
														{" "}
													</span>{" "}
													{errors.cities}
												</small>
											)}
										</FormGroup>
									</Form>
								</CardBody>
							</div>
							<div className="modal-footer">
								{/* <Button block color="primary" type="button">
                      Add To Existing
                    </Button>  */}
								<Button
									block
									color="primary"
									type="button"
									onClick={this.isValid}
								>
									{adding_camp ? "Creating..." : "Create New"}
								</Button>
							</div>
						</Modal>

						<Modal
							className="modal-dialog-centered modal-danger"
							contentClassName="bg-gradient-danger"
							isOpen={this.state.deleteModal}
							toggle={() => this.toggleModal("deleteModal")}
						>
							<div className="modal-header">
								<h6 className="modal-title" id="modal-title-notification">
									<Trans>Your attention is required</Trans>
								</h6>
								<button
									aria-label="Close"
									className="close"
									data-dismiss="modal"
									type="button"
									onClick={() => this.toggleModal("deleteModal")}
								>
									<span aria-hidden={true}>×</span>
								</button>
							</div>
							<div className="modal-body">
								<div className="py-3 text-center">
									<i className="ni ni-bell-55 ni-3x" />
									<h4 className="heading mt-4">
										<Trans>You should read this</Trans>!
									</h4>
									<p>
										<Trans i18nKey="campaign_delete_confirm">
											Are you sure you want to delete these Campaigns
										</Trans>{" "}
										?
									</p>
								</div>
							</div>
							<div className="modal-footer">
								<Button
									className="text-white ml-auto"
									color="link"
									data-dismiss="modal"
									type="button"
									onClick={() => this.toggleModal("deleteModal")}
								>
									<Trans>No</Trans>
								</Button>

								<Button
									className="btn-white"
									color="default"
									type="button"
									onClick={() => this.deleteCamps()}
								>
									<Trans>Yes</Trans>
								</Button>
							</div>
						</Modal>
					</div>
				)}
			</ToolkitProvider>
		);
	}
}

class ShowCampaigns extends React.Component {
	state = {
		tabs: 1,
	};

	componentDidMount() {
		const { fetchCampaigns, fetchDistinctFilters } = this.props;
		fetchCampaigns({ type: "pending" });
		fetchDistinctFilters(["city", "media_type"]);
	}

	toggleNavs = (e, state, index) => {
		e.preventDefault();
		this.setState({
			[state]: index,
		});
		const { fetchCampaigns } = this.props;

		var type = "";
		switch (index) {
			case 1:
				type = "pending";
				break;
			case 2:
				type = "awaiting";
				break;
			case 3:
				type = "booked";
				break;
			default: {
			}
		}
		fetchCampaigns({ type: type });
	};

	createCampaign = (data) => {
		const { addCampaign } = this.props;
		addCampaign(data);
	};

	render() {
		const { campaigns, camps_loading, filters } = this.props;

		return (
			<>
				<CompHeader />
				<Container fluid>
					<div className="nav-wrapper">
						<Nav
							className="nav-fill flex-column flex-md-row"
							id="tabs-icons-text"
							pills
							role="tablist"
						>
							<NavItem>
								<NavLink
									aria-selected={this.state.tabs === 1}
									className={classnames("mb-sm-3 mb-md-0", {
										active: this.state.tabs === 1,
									})}
									onClick={(e) => this.toggleNavs(e, "tabs", 1)}
									href="#pablo"
									role="tab"
								>
									<i className="ni ni-cloud-upload-96 mr-2" />
									<Trans>Pending</Trans>
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink
									aria-selected={this.state.tabs === 2}
									className={classnames("mb-sm-3 mb-md-0", {
										active: this.state.tabs === 2,
									})}
									onClick={(e) => this.toggleNavs(e, "tabs", 2)}
									href="#pablo"
									role="tab"
								>
									<i className="ni ni-bell-55 mr-2" />
									<Trans>Planned</Trans>
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink
									aria-selected={this.state.tabs === 3}
									className={classnames("mb-sm-3 mb-md-0", {
										active: this.state.tabs === 3,
									})}
									onClick={(e) => this.toggleNavs(e, "tabs", 3)}
									href="#pablo"
									role="tab"
								>
									<i className="ni ni-calendar-grid-58 mr-2" />
									<Trans>Booked</Trans>
								</NavLink>
							</NavItem>
						</Nav>
					</div>
					<Card className="shadow">
						<CardBody>
							<TabContent activeTab={"tabs" + this.state.tabs}>
								<TabPane tabId="tabs1">
									{camps_loading && (
										<button
											className="btn btn-primary btn-sm mb-2"
											type="button"
											disabled
										>
											<span
												className="spinner-border spinner-border-sm"
												role="status"
												aria-hidden="true"
											></span>
											<Trans>Loading</Trans>...
										</button>
									)}
									{!camps_loading && campaigns && (
										<TableComponent
											campaigns={campaigns}
											filters={filters}
											showCreateBtn={true}
											showPlan={false}
											showProgress={false}
											{...this.props}
											createCampaign={this.createCampaign}
										/>
									)}
								</TabPane>
								<TabPane tabId="tabs2">
									{camps_loading && (
										<button
											className="btn btn-primary btn-sm mb-2"
											type="button"
											disabled
										>
											<span
												className="spinner-border spinner-border-sm"
												role="status"
												aria-hidden="true"
											></span>
											<Trans>Loading</Trans>...
										</button>
									)}
									{!camps_loading && campaigns && (
										<TableComponent
											campaigns={campaigns}
											filters={filters}
											showCreateBtn={false}
											showPlan={true}
											showProgress={true}
											{...this.props}
											createCampaign={this.createCampaign}
										/>
									)}
								</TabPane>
								<TabPane tabId="tabs3">
									{camps_loading && (
										<button
											className="btn btn-primary btn-sm mb-2"
											type="button"
											disabled
										>
											<span
												className="spinner-border spinner-border-sm"
												role="status"
												aria-hidden="true"
											></span>
											<Trans>Loading</Trans>...
										</button>
									)}
									{!camps_loading && campaigns && (
										<TableComponent
											campaigns={campaigns}
											filters={filters}
											showCreateBtn={false}
											showPlan={true}
											showProgress={true}
											{...this.props}
											createCampaign={this.createCampaign}
										/>
									)}
								</TabPane>
							</TabContent>
						</CardBody>
					</Card>
				</Container>
			</>
		);
	}
}

const mapStateToProps = ({ camps, auth }) => {
	const { camps_loading, type, campaigns, adding_camp, filters, active_camp } =
		camps;
	const { user } = auth;
	return {
		camps_loading,
		adding_camp,
		type,
		campaigns,
		filters,
		active_camp,
		user,
	};
};

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			fetchCampaigns,
			fetchDistinctFilters,
			addCampaign,
			fetchUserDetails,
			deleteCamps,
		},
		dispatch
	);

export default connect(mapStateToProps, mapDispatchToProps)(ShowCampaigns);
