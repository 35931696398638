import i18n from "./i18n";

export const inv_site_headers = {
	site_code: i18n.t("Site Code"),
	tax_id: i18n.t("Tax Id"),
	city: i18n.t("City"),
	location: i18n.t("Location"),
	media_type: i18n.t("Media Type"),
	width: i18n.t("Width"),
	height: i18n.t("Height"),
	locality: i18n.t("Locality"),
	latitude: i18n.t("Latitude"),
	longitude: i18n.t("Longitude"),
	media_owner: i18n.t("Media Owner"),
	availability: i18n.t("Availability"),
	status: i18n.t("Status"),
	poi: i18n.t("Point of Interests"),
	inventory_cost: i18n.t("Sell price"),
	cost_price: i18n.t("Cost"),
	installation_cost: i18n.t("Installation Cost"),
	trip_600mt: i18n.t("Trip (600mt)"),
	trip_back_600mt: i18n.t("Trip back (600mt)"),
	sec_600mt: i18n.t("Sec (600mt)"),
	homes_600mt: i18n.t("Homes (600mt)"),
	households_600mt: i18n.t("Households (600mt)"),
	male_600mt: i18n.t("Male (600mt)"),
	female_600mt: i18n.t("Female (600mt)"),
	p_0_5_600mt: i18n.t("Population ( 0 - 5 years ) (600mt)"),
	p_6_14_600mt: i18n.t("Population ( 6 - 14 years ) (600mt)"),
	p_15_29_600mt: i18n.t("Population ( 15 - 29 years ) (600mt)"),
	p_30_44_600mt: i18n.t("Population ( 30 - 44 years ) (600mt)"),
	p_45_64_600mt: i18n.t("Population ( 45 - 64 years ) (600mt)"),
	p_65_600mt: i18n.t("Population ( 65+ years ) (600mt)"),
	homes_ab_600mt: i18n.t("Homes (ab) (600mt)"),
	homes_c1a_600mt: i18n.t("Homes (c1a) (600mt)"),
	homes_c1b_600mt: i18n.t("Homes (c1b) (600mt)"),
	homes_c2_600mt: i18n.t("Homes (c2) (600mt)"),
	homes_c3_600mt: i18n.t("Homes (c3) (600mt)"),
	homes_d_600mt: i18n.t("Homes (d) (600mt)"),
	homes_e_600mt: i18n.t("Homes (e) (600mt)"),
	health_600mt: i18n.t("Health (600mt)"),
	entertainment_600mt: i18n.t("Entertainment (600mt)"),
	services_600mt: i18n.t("Services (600mt)"),
	pension_600mt: i18n.t("Pension (600mt)"),
	commerce_600mt: i18n.t("Commerce (600mt)"),
	various_600mt: i18n.t("Various (600mt)"),
	accommodation_600mt: i18n.t("Accomodation (600mt)"),
	government_organisation_600mt: i18n.t("Government Organisation (600mt)"),
	tourism_600mt: i18n.t("Tourism (600mt)"),
	security_institute_600mt: i18n.t("Security Institute (600mt)"),
	media_600mt: i18n.t("Media (600mt)"),
	transport_600mt: i18n.t("Transport (600mt)"),
	international_organization_600mt: i18n.t("International Organisation (600mt)"),
	education_600mt: i18n.t("Education (600mt)"),
	food_600mt: i18n.t("Food (600mt)"),
	trip_1000mt: i18n.t("Trip (1000mt)"),
	trip_back_1000mt: i18n.t("Trip back (1000mt)"),
	sec_1000mt: i18n.t("Sec (1000mt)"),
	homes_1000mt: i18n.t("Homes (1000mt)"),
	households_1000mt: i18n.t("Households (1000mt)"),
	male_1000mt: i18n.t("Male (1000mt)"),
	female_1000mt: i18n.t("Female (1000mt)"),
	p_0_5_1000mt: i18n.t("Population ( 0 - 5 years ) (1000mt)"),
	p_6_14_1000mt: i18n.t("Population ( 6 - 14 years ) (1000mt)"),
	p_15_29_1000mt: i18n.t("Population ( 15 - 29 years ) (1000mt)"),
	p_30_44_1000mt: i18n.t("Population ( 30 - 44 years ) (1000mt)"),
	p_45_64_1000mt: i18n.t("Population ( 45 - 64 years ) (1000mt)"),
	p_65_1000mt: i18n.t("Population ( 65+ years ) (1000mt)"),
	homes_ab_1000mt: i18n.t("Homes (ab) (1000mt)"),
	homes_c1a_1000mt: i18n.t("Homes (c1a) (1000mt)"),
	homes_c1b_1000mt: i18n.t("Homes (c1b) (1000mt)"),
	homes_c2_1000mt: i18n.t("Homes (c2) (1000mt)"),
	homes_c3_1000mt: i18n.t("Homes (c3) (1000mt)"),
	homes_d_1000mt: i18n.t("Homes (d) (1000mt)"),
	homes_e_1000mt: i18n.t("Homes (e) (1000mt)"),
	health_1000mt: i18n.t("Health (1000mt)"),
	entertainment_1000mt: i18n.t("Entertainment (1000mt)"),
	services_1000mt: i18n.t("Services (1000mt)"),
	pension_1000mt: i18n.t("Pension (1000mt)"),
	commerce_1000mt: i18n.t("Commerce (1000mt)"),
	various_1000mt: i18n.t("Various (1000mt)"),
	accommodation_1000mt: i18n.t("Accomodation (1000mt)"),
	government_organisation_1000mt: i18n.t("Government Organisation (1000mt)"),
	tourism_1000mt: i18n.t("Tourism (1000mt)"),
	security_institute_1000mt: i18n.t("Security Institute (1000mt)"),
	media_1000mt: i18n.t("Media (1000mt)"),
	transport_1000mt: i18n.t("Transport (1000mt)"),
	international_organization_1000mt: i18n.t("International Organisation (1000mt)"),
	education_1000mt: i18n.t("Education (1000mt)"),
	food_1000mt: i18n.t("Food (1000mt)"),
	specs: i18n.t("Specs"),
	url: i18n.t("URL"),
};
