import React from "react";
import { Modal, Button } from "reactstrap";
import { Trans } from "react-i18next";

class DeleteMemberModal extends React.Component {
	state = {};

	render() {
		const {
			show,
			delete_member_modal_data,
			closeDeleteMemberModal,
			deleteMember,
		} = this.props;
		return (
			<Modal
				className="modal-dialog-centered modal-danger"
				contentClassName="bg-gradient-danger"
				isOpen={show}
				toggle={() => closeDeleteMemberModal()}
			>
				<div className="modal-header">
					<h6 className="modal-title" id="modal-title-notification">
						<Trans>Your attention is required</Trans>
					</h6>
					<button
						aria-label="Close"
						className="close"
						data-dismiss="modal"
						type="button"
						onClick={() => closeDeleteMemberModal()}
					>
						<span aria-hidden={true}>×</span>
					</button>
				</div>
				<div className="modal-body">
					<div className="py-3 text-center">
						<i className="ni ni-bell-55 ni-3x" />
						<h4 className="heading mt-4">
							<Trans>You should read this!</Trans>
						</h4>
						<p>
							<Trans>Are you sure you want to delete user</Trans>{" "}
							{delete_member_modal_data && delete_member_modal_data.email} ?
							<Trans>
								Campaigns created by the user will be assigned to you in case of
								deletion.
							</Trans>
						</p>
					</div>
				</div>
				<div className="modal-footer">
					<Button
						className="text-white ml-auto"
						color="link"
						data-dismiss="modal"
						type="button"
						onClick={() => closeDeleteMemberModal()}
					>
						<Trans>No</Trans>
					</Button>

					<Button
						className="btn-white"
						color="default"
						type="button"
						onClick={() => deleteMember()}
					>
						<Trans>Yes</Trans>
					</Button>
				</div>
			</Modal>
		);
	}
}

export default DeleteMemberModal;
