import React, { useState, useEffect } from "react";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";
import Switch from "@material-ui/core/Switch";
import MapPanel from "./MapPanel";
import MapBox from "./MapBox";
import axios from "axios";
import { devUrl } from "../../utility";
import { mapStyle } from "../../helpers/helpers";
import {
  Card,
  Row,
  CardBody,
  Col,
  Alert,
  Button,
  Progress,
  CardHeader,
} from "reactstrap";
import { nFormatter } from "../../helpers/helpers";
import { Trans } from "react-i18next";
import _ from "lodash";

const MapWithAMarkerClusterer = withScriptjs(
  withGoogleMap((props) => (
    <GoogleMap
      ref={(map) => {
        props.updateMapRef(map);
        // console.log('map',props.mapRef)
      }}
      options={{
        styles: mapStyle,
      }}
      // onIdle={console.log('initialized',props.mapRef && props.mapRef.getBounds())}
      defaultZoom={10}
      defaultCenter={{
        lat: Number(props.center.latitude),
        lng: Number(props.center.longitude),
      }}
      onDragEnd={() => {
        console.log(
          "dragged",
          props.mapRef && props.onDragChange(props.mapRef.getBounds())
        );
      }}
    >
      {/* -------- loader ----------- */}
      {props.dataLoading && (
        <div
          className="loader-box"
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            background: "#00000066",
            height: "100%",
            width: "100%",
            display: true ? "block" : "none",
          }}
        >
          <img
            style={{
              display: "block",
              alignItems: "center",
              justifyContent: "center",
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: "45%",
              height: "70px",
              width: "70px",
            }}
            src="https://locauditooh-images.s3-ap-southeast-1.amazonaws.com/loader3.gif"
            alt="Loader"
          />
        </div>
      )}

      {/* --------- Info Details ----------- */}
      {props.showInfoBox && props.activeMarker && props.infoBoxDetails && (
        <MapPanel
          panelDetails={props.infoBoxDetails}
          onCloseClick={props.onInfoBoxCloseClick}
          setSelectedInventory={props.setSelectedInventory}
          selectedInv={props.selectedInv}
        />
      )}

      {props.inventory &&
        props.inventory.map((item, index) => {
          let position = {
            lat: item.loc.coordinates[1],
            lng: item.loc.coordinates[0],
          };

          return (
            <Marker
              key={index}
              position={position}
              icon={{
                url:
                  props.selectedInv &&
                    _.intersectionBy(props.selectedInv, [item], "_id").length > 0
                    ? require("assets/img/icons/map/green_icon.png")
                    : item.booked_camps && item.booked_camps.length > 0
                      ? require("assets/img/icons/map/gps.png")
                      : require("assets/img/icons/map/new_2.png"),
                scaledSize: new window.google.maps.Size(30, 30),
              }}
              title={item.location}
              onClick={() => props.handleInfoBox(item, position)}
            />
          );
        })}

      {props.nearby_data &&
        props.nearby_data.map((item, index) => {
          let position = {
            lat: item.loc.coordinates[1],
            lng: item.loc.coordinates[0],
          };

          return (
            <Marker
              key={index}
              position={position}
              icon={{
                url: item.icon || "",
                scaledSize: new window.google.maps.Size(30, 30),
              }}
              title={item.name}
            />
          );
        })}
    </GoogleMap>
  ))
);

const RemoteMap = (props) => {
  const [showInfoBox, updateInfoBox] = useState(false);
  const [activeMarker, updateActiveMarker] = useState(null);
  const [infoBoxDetails, updateInfoBoxDetails] = useState(null);
  const [inventory, updateInventory] = useState(0);
  const [nearby_data, updateNearby] = useState(0);
  const [inventoryTotal, updateInvTotal] = useState(0);
  const [mapCenter, updateMapCenter] = useState(0);
  const [mapRef, updateMapRef] = useState(React.createRef());
  const [dataLoading, updateDataLoading] = useState(false);
  const [showMapBox, updateMapType] = useState(false);
  const [allInvIds, setInvIds] = useState([]);
  const [selectAllInv, updateSelectAllInv] = useState(false);

  const handleInfoBox = (item, position) => {
    // console.log("pos",position,'item',item)
    updateInfoBox(true);
    updateActiveMarker(position);
    updateInfoBoxDetails(item);
  };

  const onInfoBoxCloseClick = () => {
    updateInfoBox(false);
  };

  const onDragChange = (bounds) => {
    var ne = bounds.getNorthEast();
    var sw = bounds.getSouthWest();
    // var nw = {lat: ne, lng:};
    // var se = {lat:, lng:};
    fetchDraggedInventory({ ne, sw });
  };

  const addAllToPlan = () => {
    updateSelectAllInv(!selectAllInv);

    if (!selectAllInv) {
      //  console.log('Select all')
      var modfIds = allInvIds.map((a) => {
        return { _id: a };
      });
      setSelectedInventory(modfIds, "map");
    } else {
      resetSelectedInventory();
    }
  };

  const fetchDraggedInventory = (bounds) => {
    const { filterObj, campId, simNetworks } = props;
    // console.log(filterObj);
    let query = {
      campId: campId,
      filters: filterObj,
      bounds: bounds,
      networks: simNetworks,
    };
    axios
      .post(
        `${devUrl}/v2/fetch_sugg_inventory_map`,
        query,
        {
          headers: { authorization: localStorage.getItem("plano_token") },
        }
      )
      .then((response) => {
        // console.log('remote data grid',response);
        const data = response.data.plano_inventory;
        const total = response.data.total_count;
        const nearby = response.data.nearby;
        const inv_ids = response.data.inv_ids;

        updateInvTotal(total);
        updateInventory(data);
        updateNearby(nearby);
        setInvIds(inv_ids);
        if (data.length > 0) {
          updateMapCenter(data[0]);
        }
      })
      .catch((error) => { });
  };

  const {
    selectedInv,
    setSelectedInventory,
    resetSelectedInventory,
    bookInventory,
    budgetAllocated,
    budgetProgress,
    remainingBudget,
    filterObj,
    campId,
    simNetworks,
    show,
    setDistinctFilters,
    filterChanges,
  } = props;

  useEffect(() => {
    if (show) {
      updateDataLoading(true);
      // console.log(filterObj.flag);
      // console.log("filterObj", filterObj);
      let query = { campId: campId, filters: filterObj, networks: simNetworks };
      let route = filterObj.flag === "geoLocation" ? "fetch_sugg_inventory_map" : "fetch_sugg_inventory_map"
      axios
        .post(`${devUrl}/v2/${route}`, query, {
          headers: { authorization: localStorage.getItem("plano_token") },
        })
        .then((response) => {
          // console.log('remote data grid',response);
          const data = response.data.plano_inventory;
          const total = response.data.total_count;
          const nearby = response.data.nearby;
          const inv_ids = response.data.inv_ids;
          const distinctFilters = response.data.distinctFilters;

          updateInvTotal(total);
          updateInventory(data);
          updateNearby(nearby);
          setInvIds(inv_ids);
          updateDataLoading(false);
          if (data.length > 0) {
            updateMapCenter(data[0]);
          }

          setDistinctFilters(distinctFilters);
        })
        .catch((error) => { });
    }
  }, [show, filterChanges, campId, filterObj, setDistinctFilters, simNetworks]);

  // console.log("map.props",props)
  // console.log("filter object", filterObj);
  return (
    <Card className={show ? "shadow mb-sm-3" : "d-none"}>
      <CardHeader>
        {selectedInv && selectedInv.length > 0 && (
          <Alert className="alert-default" style={{ overflow: "hidden" }}>
            <Row style={{ alignItems: "center" }}>
              <Col md="3">
                <strong>
                  {" "}
                  {selectedInv.length} <Trans>Selected</Trans>
                </strong>
              </Col>
              <Col md="6">
                <Progress max="100" value={budgetProgress} color="default" />
                <center className="notranslate">
                  <strong>{budgetAllocated} </strong>
                  {remainingBudget > 0 ? (
                    <span>
                      (<Trans>Remaining</Trans> {nFormatter(remainingBudget, 1)}
                      )
                    </span>
                  ) : (
                    <span>
                      (<Trans>Budget Exceeded by</Trans>{" "}
                      {nFormatter(Math.abs(remainingBudget), 1)})
                    </span>
                  )}
                </center>
              </Col>
              <Col md="3">
                <Button
                  className="float-right"
                  color="secondary"
                  type="button"
                  onClick={bookInventory}
                >
                  <span className="ni ni-basket"> </span>
                  <Trans>Plan</Trans>
                  {/* {fetching_booked=="pending" ? "Processing...":"Plan"} */}
                </Button>
              </Col>
            </Row>
          </Alert>
        )}
        <Row>
          <Col xs="8">
            <h3>
              <Trans>Map</Trans>
              {inventoryTotal !== 0 && (
                <span>{" (" + inventoryTotal + ")"}</span>
              )}
            </h3>
            {/* <button onClick={() => updateMapType(!showMapBox)}>MapBox</button> */}
          </Col>
          <Col
            xs="4"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Trans>Add All To The Plan</Trans>
              <Switch
                checked={selectAllInv}
                onChange={() => addAllToPlan()}
                color="default"
                inputProps={{ "aria-label": "checkbox with default color" }}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Trans>Data Layers</Trans>
              <Switch
                checked={showMapBox}
                onChange={() => updateMapType(!showMapBox)}
                color="default"
                inputProps={{ "aria-label": "checkbox with default color" }}
              />
            </div>
          </Col>
        </Row>
      </CardHeader>

      {!inventory && (
        <p
          style={{
            marginTop: "10%",
            marginBottom: "10%",
            fontSize: "1.2rem",
            justifyContent: "center",
            display: "flex",
            alignSelf: "center",
          }}
        >
          <Trans>Loading</Trans>...
        </p>
      )}

      {dataLoading === false && inventoryTotal === 0 && (
        <p
          style={{
            marginTop: "10%",
            marginBottom: "10%",
            fontSize: "1.2rem",
            justifyContent: "center",
            display: "flex",
            alignSelf: "center",
          }}
        >
          <Trans i18nKey="no_inventory_found">
            No Inventory Found for these parameters or You might have already
            added all the inventory to the Plan
          </Trans>
        </p>
      )}

      {showMapBox ? (
        <CardBody>{mapCenter !== 0 && <MapBox center={mapCenter} />}</CardBody>
      ) : (
        <CardBody>
          {mapCenter !== 0 && (
            <MapWithAMarkerClusterer
              inventory={inventory}
              nearby_data={nearby_data}
              center={mapCenter}
              handleInfoBox={handleInfoBox}
              activeMarker={activeMarker}
              infoBoxDetails={infoBoxDetails}
              showInfoBox={showInfoBox}
              onInfoBoxCloseClick={onInfoBoxCloseClick}
              onDragChange={onDragChange}
              setSelectedInventory={setSelectedInventory}
              selectedInv={selectedInv}
              dataLoading={dataLoading}
              onMapIdle={() => {
                // console.log('map is ready')
                // let ne = mapRef.getBounds().getNorthEast();
                // let sw = mapRef.getBounds().getSouthWest();
                // console.log(ne.lat() + ";" + ne.lng());
                // console.log(sw.lat() + ";" + sw.lng());
              }}
              mapRef={mapRef}
              updateMapRef={updateMapRef}
              loadingElement={
                <div style={{ height: `100%`, position: "relative" }} />
              }
              googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDj0lUV-8zun12iUwHxDt1kcYVUJyoxw0o&callback&libraries=places"
              containerElement={
                <div
                  id="wrapper "
                  style={{ height: `800px`, position: "relative" }}
                ></div>
              }
              mapElement={<div id="map" style={{ height: `100%` }}></div>}
            />
          )}
        </CardBody>
      )}
    </Card>
  );
};

export default RemoteMap;
