import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	Container,
	Card,
	Row,
	Col,
	Button,
	CardBody,
	UncontrolledCollapse,
	ListGroup,
	ListGroupItem,
	Form,
	FormGroup,
	Label,
	Input,
	CardHeader,
} from "reactstrap";
// core components
import CompHeader from "components/Headers/CompHeader.js";
import { addMember, addPlanoPolicy } from "actions/userActions";
import { validateForm } from "../../helpers/validations";
import Icon from "@material-ui/core/Icon";
import _ from "lodash";

const AdminPanel = () => {
	const [formData, setFormData] = useState({});
	const [policyFormData, setPolicyFormData] = useState({});
	const [errors, setErrors] = useState({});
	const [addUserReq, setUserReq] = useState("");
	const [addPolicyReq, setPolicyReq] = useState("");

	const { name, email, company, phone, password, country } = formData;
	const { policy_name, policy_desc } = policyFormData;

	const dispatch = useDispatch();
	const members = useSelector((state) => state.auth.members);
	const policies = useSelector((state) => state.auth.policies);

	useEffect(() => {
		setUserReq("success");
		setTimeout(() => {
			setUserReq("");
			setFormData({});
		}, 3000);
	}, [members]);

	useEffect(() => {
		setPolicyReq("success");
		setTimeout(() => {
			setPolicyReq("");
			setPolicyFormData({});
		}, 3000);
	}, [policies]);

	const handleForm = (text, key) => {
		setFormData({ ...formData, [key]: text });
	};

	const handlePolicyForm = (text, key) => {
		setPolicyFormData({ ...policyFormData, [key]: text });
	};

	const addUser = () => {
		const errors = validateForm([
			{ field: "name", value: name, validations: ["Required"] },
			{ field: "email", value: email, validations: ["Required"] },
			{ field: "company", value: company, validations: ["Required"] },
			{ field: "phone", value: phone, validations: ["Required"] },
			{ field: "password", value: password, validations: ["Required"] },
			{ field: "country", value: country, validations: ["Required"] },
		]);

		if (!_.isEmpty(errors)) {
			setErrors(errors);
		} else {
			dispatch(addMember(formData));
			setErrors({});
		}
	};

	const addPolicy = () => {
		const errors = validateForm([
			{ field: "policy_name", value: policy_name, validations: ["Required"] },
			{ field: "policy_desc", value: policy_desc, validations: ["Required"] },
		]);

		if (!_.isEmpty(errors)) {
			setErrors(errors);
		} else {
			console.log("Api hit");
			dispatch(addPlanoPolicy(policyFormData));
			setErrors({});
		}
	};

	return (
		<>
			<CompHeader />
			<Container fluid>
				<Row className="mt-5">
					<Col className="mb-5 mb-xl-0" xl="12">
						<Card className="shadow">
							<CardHeader className="border-0">
								<Row className="align-items-center">
									<div className="col">
										<h3 className="mb-0">Admin Panel</h3>
									</div>
									<div className="col text-right"></div>
								</Row>
							</CardHeader>
							<CardBody>
								<ListGroup>
									<ListGroupItem
										className="justify-content-between"
										id="add_user"
										style={{
											display: "flex",
											cursor: "pointer",
											marginTop: "20px",
										}}
									>
										Add Plano User
										<Icon>expand_more</Icon>
									</ListGroupItem>
									<UncontrolledCollapse toggler="#add_user">
										<Card>
											<CardBody style={{ background: "#f9f9f9" }}>
												<Form>
													<FormGroup>
														<Label for="name">Name</Label>
														<Input
															type="text"
															id="name"
															placeholder="Eg. Suresh"
															value={name || ""}
															onChange={(e) =>
																handleForm(e.target.value, "name")
															}
														/>
														{errors.name && (
															<small style={{ color: "#f5365c" }}>
																{" "}
																<span className="fa fa-exclamation-circle">
																	{" "}
																</span>{" "}
																{errors.name}
															</small>
														)}
													</FormGroup>
													<FormGroup>
														<Label for="email">Email</Label>
														<Input
															type="email"
															name="email"
															id="email"
															placeholder="Eg. hola@locad.net"
															value={email || ""}
															onChange={(e) =>
																handleForm(e.target.value, "email")
															}
														/>
														{errors.email && (
															<small style={{ color: "#f5365c" }}>
																{" "}
																<span className="fa fa-exclamation-circle">
																	{" "}
																</span>{" "}
																{errors.email}
															</small>
														)}
													</FormGroup>
													<FormGroup>
														<Label for="company">Company</Label>
														<Input
															type="text"
															name="company"
															id="company"
															placeholder="Eg. Locad"
															value={company || ""}
															onChange={(e) =>
																handleForm(e.target.value, "company")
															}
														/>
														{errors.company && (
															<small style={{ color: "#f5365c" }}>
																{" "}
																<span className="fa fa-exclamation-circle">
																	{" "}
																</span>{" "}
																{errors.company}
															</small>
														)}
													</FormGroup>
													<FormGroup>
														<Label for="phone">Phone</Label>
														<Input
															type="phone"
															name="phone"
															id="phone"
															placeholder="Eg. 9394893745"
															value={phone || ""}
															onChange={(e) =>
																handleForm(e.target.value, "phone")
															}
														/>
														{errors.phone && (
															<small style={{ color: "#f5365c" }}>
																{" "}
																<span className="fa fa-exclamation-circle">
																	{" "}
																</span>{" "}
																{errors.phone}
															</small>
														)}
													</FormGroup>
													<FormGroup>
														<Label for="password">Password</Label>
														<Input
															type="password"
															name="password"
															id="password"
															placeholder="Eg. no example"
															value={password || ""}
															onChange={(e) =>
																handleForm(e.target.value, "password")
															}
														/>
														{errors.password && (
															<small style={{ color: "#f5365c" }}>
																{" "}
																<span className="fa fa-exclamation-circle">
																	{" "}
																</span>{" "}
																{errors.password}
															</small>
														)}
													</FormGroup>

													<FormGroup>
														<Label for="country">Country</Label>
														<Input
															type="text"
															name="country"
															id="country"
															placeholder="Eg. India"
															value={country || ""}
															onChange={(e) =>
																handleForm(e.target.value, "country")
															}
														/>
														{errors.country && (
															<small style={{ color: "#f5365c" }}>
																{" "}
																<span className="fa fa-exclamation-circle">
																	{" "}
																</span>{" "}
																{errors.country}
															</small>
														)}
													</FormGroup>
													{addUserReq !== "success" ? (
														<Button
															color="primary"
															size="lg"
															style={{ float: "right", width: "150px" }}
															onClick={() => addUser()}
														>
															Add User
														</Button>
													) : (
														<Button
															color="success"
															size="lg"
															style={{ float: "right", width: "150px" }}
														>
															User Added
														</Button>
													)}
												</Form>
											</CardBody>
										</Card>
									</UncontrolledCollapse>

									<ListGroupItem
										className="justify-content-between"
										id="add_policy"
										style={{
											display: "flex",
											cursor: "pointer",
											marginTop: "20px",
										}}
									>
										Add Policy
										<Icon>expand_more</Icon>
									</ListGroupItem>
									<UncontrolledCollapse toggler="#add_policy">
										<Card>
											<CardBody style={{ background: "#f9f9f9" }}>
												<Form>
													<FormGroup>
														<Label for="policy_name">Policy Name</Label>
														<Input
															type="text"
															id="policy_name"
															placeholder="Eg. Read only access"
															value={policy_name || ""}
															onChange={(e) =>
																handlePolicyForm(e.target.value, "policy_name")
															}
														/>
														{errors.policy_name && (
															<small style={{ color: "#f5365c" }}>
																{" "}
																<span className="fa fa-exclamation-circle">
																	{" "}
																</span>{" "}
																{errors.policy_name}
															</small>
														)}
													</FormGroup>
													<FormGroup>
														<Label for="policy_desc">Policy Description</Label>
														<Input
															type="text"
															id="name"
															placeholder="Eg. This will allow user to have only read access"
															value={policy_desc || ""}
															onChange={(e) =>
																handlePolicyForm(e.target.value, "policy_desc")
															}
														/>
														{errors.policy_desc && (
															<small style={{ color: "#f5365c" }}>
																{" "}
																<span className="fa fa-exclamation-circle">
																	{" "}
																</span>{" "}
																{errors.policy_desc}
															</small>
														)}
													</FormGroup>
													{addPolicyReq !== "success" ? (
														<Button
															color="primary"
															size="lg"
															style={{ float: "right", width: "150px" }}
															onClick={() => addPolicy()}
														>
															Add Policy
														</Button>
													) : (
														<Button
															color="success"
															size="lg"
															style={{ float: "right", width: "150px" }}
														>
															Policy Added
														</Button>
													)}
												</Form>
											</CardBody>
										</Card>
									</UncontrolledCollapse>
									{/* <ListGroupItem className="justify-content-between">Morbi leo risus </ListGroupItem> */}
								</ListGroup>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</>
	);
};

export default AdminPanel;
