// Note: Change dev url to also same as prodUrl when generating a build
const devClientUrl = 'https://plano-v1.locad.tech';
const prodClientUrl = 'https://plano-v1.locad.tech';

export const devUrlDev = "https://plano-v1-api.locad.tech";
export const devUrlProd = "https://plano-v1-api.locad.tech";

export const reportUrlDev = "https://locaudit-v1-reports.locad.tech";
export const reportUrlProd = "https://reports.locad.net";

export const ClientUrl =
	process.env.NODE_ENV === "production" ? prodClientUrl : devClientUrl;
	
export const devUrl =
	process.env.NODE_ENV === "production" ? devUrlProd : devUrlDev;
	
export const reportUrl =
	process.env.NODE_ENV === "production" ? reportUrlProd : reportUrlDev;	
