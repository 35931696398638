import React from "react";
import { Card, Row, CardHeader } from "reactstrap";

import Users from "./Users";
import socketIOClient from "socket.io-client";
import { devUrl } from "../../../utility";

class Chat extends React.Component {
	constructor(props) {
		super(props);
		this.socket = null;
		this.state = {
			username: "",
			uid: "",
			chat_ready: false,
			users: [],
			messages: [],
			message: "",
			active_receiver: "",
			showMessages: false,
		};
	}

	componentDidMount() {
		this.initChat();
	}

	generateUID() {
		let text = "";
		let possible =
			"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
		for (let i = 0; i < 15; i++) {
			text += possible.charAt(Math.floor(Math.random() * possible.length));
		}
		localStorage.setItem("uid", text);
		return text;
	}

	setUsername(username, e) {
		this.setState(
			{
				username: username,
			},
			() => {
				this.initChat();
			}
		);
	}

	sendMessage(message, e) {
		console.log(message);
		const { active_receiver } = this.state;
		this.setState({
			messages: this.state.messages.concat([
				{
					username: localStorage.getItem("username"),
					uid: localStorage.getItem("uid"),
					message: message,
					to: active_receiver._id,
				},
			]),
		});
		this.socket.emit("message", {
			username: localStorage.getItem("username"),
			uid: localStorage.getItem("uid"),
			message: message,
			to: active_receiver._id,
		});
		this.scrollToBottom();
	}

	scrollToBottom() {
		// let messages = document.getElementsByClassName('messages')[0];
		// messages.scrollTop = messages.scrollHeight - messages.clientHeight;
	}

	setActiveReceiver(user) {
		console.log("receiver", user);
		this.setState({ active_receiver: user, showMessages: true });
	}

	resetReceiver() {
		this.setState({ active_receiver: "", showMessages: false });
	}

	initChat() {
		// localStorage.setItem('username', this.state.username);
		var user = localStorage.getItem("user");
		user = JSON.parse(user);
		var username = user.name;
		var uid = user._id;
		this.setState({
			chat_ready: true,
			username: username,
			uid: uid,
		});
		localStorage.setItem("username", username);
		localStorage.setItem("uid", uid);

		this.socket = socketIOClient(devUrl, {
			query: "username=" + username + "&uid=" + uid,
			path: "/conn/d",
		});

		this.socket.on(
			"updateUsersList",
			function (users) {
				// console.log(users);
				this.setState({
					users: users,
				});
			}.bind(this)
		);

		this.socket.on(
			"message",
			function (message) {
				this.setState({
					messages: this.state.messages.concat([message]),
				});
				this.scrollToBottom();
			}.bind(this)
		);
	}

	render() {
		const { chat_users } = this.props;
		const { users, active_receiver, showMessages, messages } = this.state;
		return (
			<Card className="shadow">
				<CardHeader className="border-0">
					<Row className="align-items-center">
						<div className="col">
							{active_receiver ? (
								<h3 className="mb-0">
									<span
										className="ni ni-bold-left"
										style={{
											padding: "5px",
											lineHeight: "1.5",
											cursor: "pointer",
										}}
										onClick={this.resetReceiver.bind(this)}
									></span>
									<span style={{ marginLeft: "5px" }}>
										{active_receiver.name}
									</span>
								</h3>
							) : (
								<h3 className="mb-0">
									<span className="ni ni-chat-round"> </span>
									<span style={{ marginLeft: "5px" }}>Chat</span>
								</h3>
							)}
						</div>
					</Row>
				</CardHeader>
				<div className="chat">
					{/* {this.state.chat_ready ? ( */}
					<React.Fragment>
						{chat_users && (
							<Users
								users={chat_users}
								online_users={users}
								sendMessage={this.sendMessage.bind(this)}
								setActiveReceiver={this.setActiveReceiver.bind(this)}
								messages={messages}
								showMessages={showMessages}
								activeReceiver={active_receiver}
							/>
						)}
					</React.Fragment>
					{/* ) : (
                    <EnterChat
                        setUsername={this.setUsername.bind(this)}
                    /> */}
					{/* )} */}
				</div>
			</Card>
		);
	}
}

export default Chat;
