import React from "react";
import { Card, Row, CardBody, Col, CardHeader } from "reactstrap";
import Select, { createFilter } from "react-select";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import _ from "lodash";
import { Trans } from "react-i18next";
import FilterMap from "./FilterMap";

const availability = ["Available", "Booked"];
const trafficDensity = ["high", "low", "medium"];
const campaignType = ["OOH", "Digital"];
const gender = ["Male", "Female"];

class Filters extends React.Component {
  state = {
    aud_gender: new Map(),
    sec_class: new Map(),
    camp_type: new Map(),
    filterObj: {},
    poi: "",
    ageVal: { min: 20, max: 40 },
    radius: 2000,
    nearbyPlaces: [],
    traffic_density: "",
    media_owner: [],
    sec_600mt: [],
    finalScore: { min: 0, max: 150 },
    areaValue: { min: 0, max: 1000 },
    selectedFemale: 0,
    selectedMale: 0,
    open: false,
  };

  secChange = (e) => {
    const item = e.target.name;
    const isChecked = e.target.checked;
    const { sec_class, filterObj } = this.state;
    const { filterChange } = this.props;
    // const { filters } = this.props;

    sec_class.set(item, isChecked);

    var a = [];
    sec_class.forEach((value, key, map) => {
      if (value) {
        a.push(key);
      }
    });
    // filterObj = filters;
    filterObj["sec_classification"] = a;
    this.setState({ sec_class, filterObj });
    let filters = {...this.props.filters, ...filterObj}
    filterChange(filters);
  };

  handleRadiusChange = (val, e) => {
    const { filterObj, nearbyPlaces } = this.state;

    filterObj["nearby"] = {
      places: nearbyPlaces.map((m) => m.value),
      radius: val,
    };
    this.setState({ filterObj, radius: val });
  };

  handleAreaChange = (val, e) => {
    const { filterObj } = this.state;
    filterObj["area_n"] = {
      $gte: val["min"],
      $lte: val["max"],
    };
    this.setState({ filterObj, areaValue: val });
  };

  handleFinalScoreChange = (val, e) => {
    const { filterObj } = this.state;
    filterObj["additional_data.final_score"] = {
      $gte: val["min"] / 100,
      $lte: val["max"] / 100,
    };
    this.setState({ filterObj, finalScore: val });
  };

  handleChangeComplete = () => {
    const { filterObj } = this.state;
    const { filterChange } = this.props;

    let filters = {...this.props.filters, ...filterObj}
    filterChange(filters);
  };

  handleNearbyChange = (val) => {
    const { filterObj, radius } = this.state;
    const { filterChange } = this.props;
    // console.log(this.props.filters);
    if (val) {
      filterObj["nearby"] = { places: val.map((m) => m.value), radius: radius };
    } else {
      delete filterObj["nearby"];
    }
    this.setState({ filterObj, nearbyPlaces: val, poi: val });
    let filters = {...this.props.filters, ...filterObj}
    filterChange(filters);
  };

  handleSelectChange = (key, val) => {
    const { filterObj } = this.state;
    const { filterChange } = this.props;

    if (val) {
      filterObj[key] = val.map((m) => m.value);
    } else {
      delete filterObj[key];
    }
    this.setState({ filterObj, [key]: val });
    let filters = {...this.props.filters, ...filterObj}
    filterChange(filters);
  };

  campTypeChange = (e) => {
    const item = e.target.name;
    const isChecked = e.target.checked;
    const { camp_type, filterObj } = this.state;
    const { filterChange } = this.props;

    camp_type.set(item, isChecked);

    var a = [];
    camp_type.forEach((value, key, map) => {
      if (value) {
        a.push(key);
      }
    });

    if (a.length === 0) {
      delete filterObj["type"];
    } else {
      filterObj["type"] = a.map((b) => _.toLower(b));
    }

    let filters = {...this.props.filters, ...filterObj}
    filterChange(filters);
    this.setState({ camp_type, filterObj });
  };

  onGenderChange = (e) => {
    const name = e.target.name;
    const checked = e.target.checked;
    const { filterObj } = this.state;

    if (checked) {
      this.setState({ selectedGender: name });
      filterObj["gender"] = name;
    } else {
      this.setState({ selectedGender: "" });
      delete filterObj["gender"];
    }

    this.setState({ filterObj });

    const { filterChange } = this.props;
    let filters = {...this.props.filters, ...filterObj}
    filterChange(filters);
  };

  render() {
    // console.log(
    //   "filterObj",
    //   this.state.filterObj,
    //   "nearbyPlaces",
    //   this.state.nearbyPlaces
    // );
    // console.log(this.props);

    const { show, nearby_places, distinctFilters } = this.props;
    const {
      selectedGender,
      filterObj,
      poi,
      traffic_density,
      camp_type,
      media_owner,
      sec_600mt,
      media_type,
      areaValue,
      format,
      finalScore,
    } = this.state;

    var nearbyPlaces = [
      { label: "Lakme Store", value: "lakme_store" },
      { label: "Hospital", value: "hospital" },
      { label: "Airport", value: "airport" },
      { label: "Mall", value: "mall" },
      { label: "Metro", value: "metro" },
      { label: "Bank", value: "bank" },
      { label: "Colegio", value: "colegio" },
      { label: "jardin_infantil", value: "jardin_infantil" },
      { label: "Lider", value: "lider" },
      { label: "Santa_isabel", value: "santa_isabel" },
      { label: "Tottus", value: "tottus" },
      { label: "Cafe", value: "cafe" },
      { label: "Jumbo", value: "jumbo" },
      { label: "School", value: "school" },
      { label: "Unimarc", value: "unimarc" },
      { label: "University", value: "university" },
      { label: "Movie_theatre", value: "movie_theatre" },
      { label: "Shopping_mall", value: "shopping_mall" },
      { label: "Restaurant", value: "restaurant" },
      { label: "Pharmacy", value: "pharmacy" },
      { label: "Gym", value: "gym" },
      { label: "Night_club", value: "night_club" },
    ];

    if (nearby_places) {
      nearbyPlaces = nearby_places.map((m) => {
        return { label: _.upperFirst(m), value: m };
      });
    }

    var trafficDensityOp = trafficDensity.map((m) => {
      return { label: _.upperFirst(m), value: m };
    });

    // console.log("Distinct Filters ", distinctFilters);

    let media_owners = [];

    if (
      distinctFilters &&
      distinctFilters.media_owners &&
      distinctFilters.media_owners.length > 0
    ) {
      media_owners = distinctFilters.media_owners.map((i) => {
        return { label: i, value: i };
      });
    }

    let sec = [];

    if (
      distinctFilters &&
      distinctFilters.sec_600mt &&
      distinctFilters.sec_600mt.length > 0
    ) {
      sec = distinctFilters.sec_600mt.map((i) => {
        return { label: i, value: i };
      });
    }

    let media_types = [];

    if (
      distinctFilters &&
      distinctFilters.media_type &&
      distinctFilters.media_type.length > 0
    ) {
      media_types = distinctFilters.media_type.map((i) => {
        return { label: i, value: i };
      });
    }

    let formats = [];
    if (
      distinctFilters &&
      distinctFilters.format &&
      distinctFilters.format.length > 0
    ) {
      formats = distinctFilters.format.map((i) => {
        return { label: i, value: i };
      });
    }
    let customTranslations = {
      select: "Choose",
      "Traffic Density": "Traffic Density",
      "Media Owner": "Media Owner",
      "Socio-Economic Class": "Socio-Economic Class",
      Format: "Format",
      "Media Type": "Media Type",
    };
    if (localStorage.getItem("lng") === "es") {
      customTranslations.select = "Seleccione";
      customTranslations["Traffic Density"] = "Densidad de tráfico";
      customTranslations["Media Owner"] = "Proveedor";
      customTranslations["Socio-Economic Class"] = "Clase socioeconómica";
      customTranslations["Format"] = "Formato";
      customTranslations["Media Type"] = "Tipo de medio";
    }
    return (
      <Card className={show ? "shadow mb-sm-3" : "d-none"}>
        <CardHeader>
          <Row>
            <Col md="11">
              <h3>Filters</h3>
            </Col>
            <Col md="1">
              <i
                title={!this.state.open ? "Show More" : "Hide"}
                class={!this.state.open ? "fa fa-caret-down" : "fa fa-caret-up"}
                aria-hidden="true"
                style={{ fontSize: 20, cursor: "pointer" }}
                onClick={() => this.setState({ open: !this.state.open })}
              ></i>
            </Col>
          </Row>
        </CardHeader>
        {this.state.open ? (
          <CardBody style={{ padding: "2rem" }}>
            <Row>
              <Col md="2">
                <h4>
                  <Trans>Audience Profile</Trans>
                </h4>
                {gender.map((g, index) => {
                  return (
                    <Col
                      className="custom-control custom-checkbox mb-3"
                      key={index}
                    >
                      <input
                        className="custom-control-input"
                        id={g}
                        name={g}
                        type="checkbox"
                        checked={selectedGender === g}
                        value={selectedGender}
                        onChange={this.onGenderChange}
                      />
                      <label className="custom-control-label" htmlFor={g}>
                        <Trans>{g}</Trans>
                      </label>
                    </Col>
                  );
                })}
              </Col>
              <Col md="2">
                <h4>
                  <Trans>Availability</Trans>
                </h4>
                {availability.map((g, index) => {
                  return (
                    <Col
                      className="custom-control custom-checkbox mb-3"
                      key={index}
                    >
                      <input
                        disabled
                        className="custom-control-input"
                        id={g + "sg"}
                        name={g + "sg"}
                        type="checkbox"
                      // value={sec_class.get({g})}
                      // onChange={this.secChange}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor={g + "sg"}
                      >
                        {g}
                      </label>
                    </Col>
                  );
                })}
              </Col>

              <Col md="2">
                <h4>
                  <Trans>Campaign Type</Trans>
                </h4>
                {campaignType.map((g, index) => {
                  return (
                    <Col
                      className="custom-control custom-checkbox mb-3"
                      key={index}
                    >
                      <input
                        className="custom-control-input"
                        id={g + "sg"}
                        name={g}
                        type="checkbox"
                        value={camp_type.get({ g })}
                        onChange={this.campTypeChange}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor={g + "sg"}
                      >
                        {g}
                      </label>
                    </Col>
                  );
                })}
              </Col>
              <Col md="4">
                <h4>POI</h4>
                <Select
                  isMulti
                  placeholder={customTranslations.select + " POIs"}
                  className="input-group-alternative"
                  filterOption={createFilter({ ignoreAccents: false })} // this makes all the difference!
                  options={nearbyPlaces}
                  value={poi}
                  onChange={this.handleNearbyChange}
                />
                {filterObj["nearby"] && (
                  <div style={{ padding: "20px 20px 20px 0px" }}>
                    <h4 style={{ marginBottom: "20px" }}>Radius (in m) </h4>
                    <InputRange
                      maxValue={10000}
                      step={100}
                      value={this.state.radius}
                      onChange={this.handleRadiusChange.bind(this)}
                    />
                  </div>
                )}
              </Col>
              {/* <Col md="4">
                <h4>
                  <Trans>Traffic Density</Trans>
                </h4>
                <Select
                  isMulti
                  placeholder={
                    customTranslations.select +
                    " " +
                    customTranslations["Traffic Density"]
                  }
                  className="input-group-alternative"
                  filterOption={createFilter({ ignoreAccents: false })} // this makes all the difference!
                  options={trafficDensityOp}
                  value={traffic_density}
                  onChange={this.handleSelectChange.bind(
                    this,
                    "traffic_density"
                  )}
                />
              </Col> */}
            </Row>

            <Row style={{ marginTop: "1rem" }}>
              <Col md="4">
                <h4>
                  <Trans>Media Owner</Trans>
                </h4>
                <Select
                  isMulti
                  placeholder={
                    customTranslations.select +
                    " " +
                    customTranslations["Media Owner"]
                  }
                  className="input-group-alternative"
                  filterOption={createFilter({ ignoreAccents: false })} // this makes all the difference!
                  options={media_owners}
                  value={media_owner}
                  onChange={this.handleSelectChange.bind(this, "media_owner")}
                />
              </Col>
              {/* <Col md="4">
                <h4>
                  <Trans>Socio-Economic Class</Trans>
                </h4>
                <Select
                  isMulti
                  placeholder={
                    customTranslations.select +
                    " " +
                    customTranslations["Socio-Economic Class"]
                  }
                  className="input-group-alternative"
                  filterOption={createFilter({ ignoreAccents: false })} // this makes all the difference!
                  options={sec}
                  value={sec_600mt}
                  onChange={this.handleSelectChange.bind(this, "sec_600mt")}
                />
              </Col> */}
              <Col md="4">
                <h4>
                  <Trans>Format</Trans>
                </h4>
                <Select
                  isMulti
                  placeholder={
                    customTranslations.select +
                    " " +
                    customTranslations["Format"]
                  }
                  className="input-group-alternative"
                  filterOption={createFilter({ ignoreAccents: false })} // this makes all the difference!
                  options={formats}
                  value={format}
                  onChange={this.handleSelectChange.bind(this, "format")}
                />
              </Col>
              <Col md="4" className="m-20" style={{ marginTop: "10px" }}>
                <h4>
                  <Trans>Area (m2)</Trans>
                </h4>
                <div style={{ padding: "20px 20px 20px 0px" }}>
                  <InputRange
                    minValue={0}
                    maxValue={1000}
                    step={5}
                    value={areaValue}
                    onChange={this.handleAreaChange.bind(this)}
                    onChangeComplete={this.handleChangeComplete}
                  />
                </div>
              </Col>
              <Col md="4" className="m-20" style={{ marginTop: "10px" }}>
                <h4>
                  <Trans>Media Type</Trans>
                </h4>
                <Select
                  isMulti
                  placeholder={
                    customTranslations.select +
                    " " +
                    customTranslations["Media Type"]
                  }
                  className="input-group-alternative"
                  filterOption={createFilter({ ignoreAccents: false })} // this makes all the difference!
                  options={media_types}
                  value={media_type}
                  onChange={this.handleSelectChange.bind(this, "media_type")}
                />
              </Col>
              <Col md="4" className="m-20" style={{ marginTop: "10px" }}>
                <h4>
                  <Trans>Final Score</Trans>
                </h4>
                <div style={{ padding: "20px 20px 20px 0px" }}>
                  <InputRange
                    minValue={0}
                    maxValue={150}
                    step={5}
                    value={finalScore}
                    onChange={this.handleFinalScoreChange.bind(this)}
                    onChangeComplete={this.handleChangeComplete}
                  />
                </div>
              </Col>
            </Row>
          </CardBody>
        ) : (
          <></>
        )}
      </Card>
    );
  }
}
export default Filters;
