import React from "react";
import {
	Container,
	Card,
	Row,
	Col,
	Button,
	Progress,
	CardBody,
	Alert,
	UncontrolledTooltip,
	Modal,
	Form,
	FormGroup,
	InputGroup,
	Input,
	InputGroupAddon,
	InputGroupText,
	CardHeader,
} from "reactstrap";
import CompHeader from "components/Headers/CompHeader.js";
import Alerts from "../alerts";
import {
	addInventory,
	fetchMediaOwnerInventory,
	updateMediaOwnerInventory,
	updateInventorySitemaps,
	updateSiteMapsUploadProgress,
	deletePlanoInventory,
	syncMediaOwnerInventory,
	disablePlanoInventory,
} from "actions/invActions";
import ReactGrid2 from "../campaigns/ReactGrid2";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import XLSX from "xlsx";
import { CSVLink } from "react-csv";
import _ from "lodash";
import { Trans } from "react-i18next";
import { inv_site_headers } from "../../helpers";
import { chileHeaders } from "../../helpers/helpers";
import i18n from "../../i18n";

var invColumns = [
	{ title: "Site Code", name: "site_code" },
	{
		title: "Sitemap",
		name: "site_map",
		render: (rowData) => <img src={rowData.site_map} alt="site map" style={{ width: 100 }} />,
	},
	{ title: "Location", name: "location" },
	{ title: "Width", name: "width" },
	{ title: "Height", name: "height" },
	{ title: "City", name: "city" },
	{ title: "State", name: "state" },
	{ title: "Media Type", name: "media_type" },
	{ title: "Lighting", name: "lighting" },
	{ title: "Price", name: "inventory_cost" },
];

var invNewColumns = [
	{ title: "Site Code", name: "site_code" },
	{ title: "State", name: "state" },
	{ title: "City", name: "city" },
	{ title: "Location", name: "location" },
	{ title: "Width", name: "width" },
	{ title: "Height", name: "height" },
	{ title: "Media Type", name: "media_type" },
	{ title: "Latitude", name: "latitude" },
	{ title: "Longitude", name: "longitude" },
	{ title: "Lighting", name: "lighting" },
	{ title: "Resolution", name: "resolution" },
	{ title: "Price", name: "inventory_cost" },
];

var invHeadserColumns = [
	{ title: "Media Owner", name: "media_owner" },
	{ title: "Site Code", name: "site_code" },
	{ title: "State", name: "state" },
	{ title: "City", name: "city" },
	{ title: "Location", name: "location" },
	{ title: "Width", name: "width" },
	{ title: "Height", name: "height" },
	{ title: "Media Type", name: "media_type" },
	{ title: "Price", name: "inventory_cost" },


];

if (localStorage.getItem("lng") === 'es') {
	console.log("language", localStorage.getItem("lng"))
	invColumns = [
		{ title: 'código del sitio', name: "site_code" },
		{
			title: 'Mapa del sitio',
			name: "site_map",
			render: (rowData) => <img src={rowData.site_map} alt="site map" style={{ width: 100 }} />,
		},
		{ title: "Localización", name: "location" },
		{ title: "Ancho", name: "width" },
		{ title: "Altura", name: "height" },
		{ title: "Ciudad", name: "city" },
		{ title: "Estado", name: "state" },
		{ title: "Tipo de medio", name: "media_type" },
		{ title: "Iluminación", name: "lighting" },
		{ title: "Precio", name: "inventory_cost" },
	];
}

class VendorInventory extends React.Component {
	state = {
		selectedInv: [],
		addingInventory: false,
		activeAlert: "",
		progressAlert: false,
		siteMapsUploadProgress: 0,
		email: null,
		syncInvModal: false,
		syncProcessing: false,
		currentPage: 0,
		limit: 10,
		limitArray: [10, 20, 30, 40, 50],
		showCard: {
			map: false,
		},
		searchKeyword: "",
	};

	constructor(props) {
		super(props);
		this.excelRef = React.createRef();
		this.photosRef = React.createRef();
	}

	componentDidMount() {
		const { userId } = this.props.match.params;
		const { fetchMediaOwnerInventory } = this.props;
		fetchMediaOwnerInventory({ page: this.state.currentPage, vendor_id: userId });
		// fetchMediaOwnerInventory({ vendor_id: userId });
	}

	setSelectedInventory = (selectedRows) => {
		// console.log("sec",selectedRows)
		this.setState({ selectedInv: selectedRows });
	};

	componentDidUpdate(prevProps, prevState) {
		// UPLOAD INVENTORY
		const { fetchMediaOwnerInventory } = this.props;
		if (this.props.added_inventory !== prevProps.added_inventory) {
			var added = this.props.added_inventory.length;
			var errors = this.props.error_inventory.length;
			var total = added + errors;
			var message = added + " out of " + total + " added successfully";
			this.setState({ addingInventory: false, activeAlert: message });
		}
		// if (this.state.searchKeyword !== prevState.searchKeyword) {
		// 	fetchMediaOwnerInventory({ search: this.state.searchKeyword });
		// }
		if (this.state.currentPage !== prevState.currentPage) {
			const { userId } = this.props.match.params;
			fetchMediaOwnerInventory({ vendor_id: userId, page: this.state.currentPage, limit: this.state.limit });
		}
		if (this.state.limit !== prevState.limit) {
			const { userId } = this.props.match.params;
			fetchMediaOwnerInventory({ vendor_id: userId, limit: this.state.limit, page: this.state.currentPage });
		}

		// UPDATE SITEMAPS UPLOAD PROGRESS
		if (this.props.site_maps_upload_progress !== prevProps.site_maps_upload_progress) {
			this.setState({
				siteMapsUploadProgress: this.props.site_maps_upload_progress,
				progressAlert: true,
			});
		}

		// UPDATE INVENTORY SITEMAPS
		if (this.props.active_sitemaps_urls !== prevProps.active_sitemaps_urls) {
			const { userId } = this.props.match.params;
			const { updateInventorySitemaps, fetchMediaOwnerInventory } = this.props;
			if (this.props.active_sitemaps_urls && this.props.active_sitemaps_urls.length) {
				updateInventorySitemaps({
					site_maps: this.props.active_sitemaps_urls,
					vendor_id: userId,
				});
			}

			if (!this.props.active_sitemaps_urls) {
				fetchMediaOwnerInventory({ vendor_id: userId });
			}
		}

		// UPDATE VENDOR INVENTORY
		if (this.props.vendor_inventory !== prevProps.vendor_inventory) {
			this.setState({ activeAlert: "", progressAlert: false, selectedInv: [] });
		}
	}

	toggleShowCard = (key) => {
		this.setState((prevState) => ({
			...prevState,
			showCard: {
				...prevState.showCard,
				[key]: !this.state.showCard[key],
			},
		}));
	};

	addInventory = (e) => {
		const files = e.target.files;
		const file = files[0];
		console.log("files", files);
		e.target.value = "";
		const { addInventory } = this.props;

		const reader = new FileReader();
		const rABS = !!reader.readAsBinaryString;

		reader.onload = (e) => {
			/* Parse data */
			const bstr = e.target.result;
			const wb = XLSX.read(bstr, {
				type: rABS ? "binary" : "array",
				bookVBA: true,
			});
			/* Get first worksheet */
			const wsname = wb.SheetNames[0];
			const ws = wb.Sheets[wsname];
			/* Convert array of arrays */
			const data = XLSX.utils.sheet_to_json(ws);
			/* Update state */
			console.log("dat", data);

			const { userName } = this.props.match.params;
			const vendor = userName;
			addInventory({ data, vendor });

			this.setState({ addingInventory: true });
		};

		if (rABS) {
			reader.readAsBinaryString(file);
		} else {
			reader.readAsArrayBuffer(file);
		}
	};

	uploadSiteMaps = (e) => {
		const files = e.target.files;
		const bucket = "locauditooh-images";
		const type = "site_map";
		const { userId } = this.props.match.params;
		const { updateSiteMapsUploadProgress } = this.props;

		updateSiteMapsUploadProgress({
			files,
			bucket,
			type,
			additional_data: userId,
		});
	};

	updateInventory = (data) => {
		const { updateMediaOwnerInventory } = this.props;
		updateMediaOwnerInventory({ data });
	};

	excelUploadAction = () => {
		this.excelRef.current.click();
	};

	fileUploadAction = () => {
		// console.log('hola')
		this.photosRef.current.click();
	};

	closeActiveAlert = () => {
		this.setState({ activeAlert: "" });
	};

	closeProgressAlert = () => {
		this.setState({ progressAlert: false });
	};

	deleteInventory = () => {
		const { selectedInv } = this.state;
		const { deletePlanoInventory } = this.props;
		const siteIds = selectedInv.map((s) => s._id);
		deletePlanoInventory({ inventory: siteIds });
	};

	disableInventory = (status) => {
		const { selectedInv } = this.state;
		const { disablePlanoInventory } = this.props;
		const siteIds = selectedInv.map((s) => s._id);
		console.log("siteIds", siteIds);
		disablePlanoInventory({ inventory: siteIds, planning_status: status });
	};

	handleInputChange = (e) => {
		const target = e.target;
		const name = target.name;
		const value = target.value;

		this.setState({ [name]: value });
	};

	toggleModal = (state) => {
		this.setState({
			[state]: !this.state[state],
		});
	};

	syncMediaOwnerInv = () => {
		const { email } = this.state;
		const { userId, userName } = this.props.match.params;
		const { syncMediaOwnerInventory } = this.props;
		syncMediaOwnerInventory({ email, userId, userName });
	};

	getVendorData = (customHeaders) => {
		const { vendor_inventory } = this.props;
		console.log("chileHeaders", vendor_inventory)
		customHeaders = [invNewColumns.map((i) => i.name).filter((f) => f && f !== "site_map")];
		if (vendor_inventory && vendor_inventory.total_count > 0) {
			var final = [];
			vendor_inventory.data.forEach((v, index) => {
				var obj = {};
				//obj = _.pick(v, customHeaders[0]);
				//obj = _.merge(obj, v.additional_data);


				if (index === 0) console.log("obj customHeaders", customHeaders);
				customHeaders[0].map(c => {
					if (v[c] !== 0 && v.additional_data[c] !== 0) obj[c] = (v[c] && v[c].toString()) ? v[c].toString() : (v.additional_data[c] && v.additional_data[c].toString()) ? v.additional_data[c].toString() : ''
					else if (v[c] === 0 && v.additional_data[c] === 0) obj[c] = v[c].toString() || v.additional_data[c].toString();

				})
				if (index === 0) console.log("obj", obj);
				// const keys = Object.keys(obj);
				// let new_obj = {};
				// keys.forEach((key) => {
				// 	new_obj[inv_site_headers[key]] = obj[key];
				// });
				final.push(obj);
			});
			return final;
			//return [{a: 1}, {a: 2}]
		} else {
			return customHeaders;
		}
	};

	getHeadersData = (customHeaders) => {
		return customHeaders = [invHeadserColumns.map((i) => i.name).filter((f) => f && f !== "site_map")];
		// return customHeaders = [invHeadserColumns.map((i) => _.snakeCase(i.title)).filter((f) => f && f !== "sitemap")];

		// console.log("customHeaders:::::::", customHeaders)
	}


	// Download Inventory sheet in excel form
	handleOnExport = () => {
		var newData = this.getVendorData()
		var wb = XLSX.utils.book_new()
		var ws = XLSX.utils.json_to_sheet(newData)

		XLSX.utils.book_append_sheet(wb, ws, "sheet1");
		XLSX.writeFile(wb, "Inventory Template.xlsx");
		console.log("names:::::", newData);
	};

	exportTemplate = () => {
		var titles = this.getHeadersData()
		var wb = XLSX.utils.book_new()
		var ws = XLSX.utils.json_to_sheet(titles)

		XLSX.utils.book_append_sheet(wb, ws, "template-Sheet");
		XLSX.writeFile(wb, "Headers Template.xlsx");
		console.log("template::::", titles);
	}

	render() {
		const { vendor_inventory } = this.props;
		const { userName } = this.props.match.params;
		const { selectedInv, addingInventory, activeAlert, siteMapsUploadProgress, progressAlert, email, syncProcessing } = this.state;

		if (vendor_inventory && vendor_inventory[0] && vendor_inventory[0]["additional_data"]) {
			_.mapKeys(vendor_inventory[0]["additional_data"], (value, key) => {
				let title = i18n.t(inv_site_headers[key]);

				if (!title) {
					title = i18n.t(_.capitalize(key));
				}

				invColumns.push({
					title: title,
					name: "additional_data." + key,
					visible: false,
				});
			});

			invColumns.push({
				title: i18n.t(_.capitalize("cost_3")),
				name: "additional_data.cost_3",
				visible: false,
			});
			invColumns.push({
				title: i18n.t(_.capitalize("sell_price_3")),
				name: "additional_data.sell_price_3",
				visible: false,
			});
			invColumns.push({
				title: i18n.t(_.capitalize("installation_cost")),
				name: "additional_data.installation_cost",
				visible: false,
			});
			invColumns = _.uniqBy(invColumns, "name");
		}

		// console.log("invCol ", invColumns);

		//let customHeaders = [invColumns.map((i) => _.snakeCase(i.title)).filter((f) => f && f !== "sitemap")];


		return (
			<>
				<CompHeader />
				<Container fluid>
					<Row className="mt-5">
						<Col className="mb-5 mb-xl-0" xl="12">
							<Card className="shadow">
								<CardHeader className="border-0">
									<Row className="align-items-center">
										<div className="col">
											<h3 className="mb-0">
												{userName} {vendor_inventory && " (" + vendor_inventory.total_count + ")"}
											</h3>
										</div>
										<div className="col text-right">
											{/* <CSVLink
												style={{ color: "#fff", width: "100%" }}
												target=""
												data={this.getVendorData(customHeaders)}
												filename={"Add Inventory Template.csv"}
											>
												<Button
													id="exportBtn"
													color="default"
													// onClick={(e) => e.preventDefault()}
													style={{ marginRight: ".5rem" }}
													size="m"
												>
													<span className="fa fa-download"></span>
												</Button>
											</CSVLink> */}
											<Button
												id="exportBtn"
												color="default"
												onClick={this.handleOnExport}
												style={{ marginRight: ".5rem" }}
												size="m"
											>
												<span className="fa fa-download"></span>
											</Button>
											<UncontrolledTooltip delay={0} placement="top" target="exportBtn">
												<Trans>Download inventory</Trans>
											</UncontrolledTooltip>
											<Button id="download_temp" color="default" size="m" onClick={this.exportTemplate}>
												<span className="fa fa-download"></span>
											</Button>
											<UncontrolledTooltip delay={0} placement="top" target="download_temp">
												<Trans>Download Template</Trans>
											</UncontrolledTooltip>
											<Button id="addInv" color="default" onClick={this.excelUploadAction} size="m">
												{addingInventory ? <span className="fa fa-spinner"></span> : <span className="fa fa-plus"></span>}
											</Button>
											<input hidden ref={this.excelRef} type="file" className="km-btn-file" onChange={this.addInventory}></input>
											<UncontrolledTooltip delay={0} placement="top" target="addInv">
												<Trans>Add Inventory</Trans>
											</UncontrolledTooltip>

											<Button id="addSiteMaps" color="default" onClick={this.fileUploadAction} size="m">
												{addingInventory ? <span className="fa fa-spinner"></span> : <span className="fa fa-image"></span>}
											</Button>
											<input hidden ref={this.photosRef} type="file" className="km-btn-file" multiple onChange={this.uploadSiteMaps}></input>
											<UncontrolledTooltip delay={0} placement="top" target="addSiteMaps">
												<Trans>Upload Site Images</Trans>
											</UncontrolledTooltip>
										</div>
									</Row>
								</CardHeader>
								<CardBody>
									<Alerts />
									{/* { ---- Alert ----- } */}
									{activeAlert && (
										<Col>
											<Alert style={{ overflow: "hidden" }} color="success">
												<Row>
													<Col lg="11">
														<strong> {activeAlert}</strong>
													</Col>
													<Col lg="1" className="col text-right" onClick={this.closeActiveAlert} style={{ cursor: "pointer" }}>
														<strong>X</strong>
													</Col>
												</Row>
											</Alert>
										</Col>
									)}

									{progressAlert && (
										<Col>
											<Alert style={{ overflow: "hidden" }} color="default">
												<Row>
													<Col lg="2">
														<Trans>Photos Uploading</Trans>
													</Col>
													<Col lg="8" style={{ alignSelf: "center" }}>
														<Progress max="100" value={siteMapsUploadProgress} color="default" />
													</Col>
													<Col lg="1" className="col text-right">
														{siteMapsUploadProgress + "%"}
													</Col>
													<Col lg="1" className="col text-right" onClick={this.closeProgressAlert} style={{ cursor: "pointer" }}>
														<strong>X</strong>
													</Col>
												</Row>
											</Alert>
										</Col>
									)}

									{/* ----- Book Inventory Button ----- */}
									{selectedInv && selectedInv.length > 0 && (
										<Alert style={{ overflow: "hidden" }} color="info">
											<strong style={{ marginTop: "8px", position: "absolute" }}>
												{" "}
												{selectedInv.length} <Trans>Selected</Trans>
											</strong>

											<Button className="float-right" color="danger" type="button" onClick={this.deleteInventory} style={{ marginLeft: "10px" }}>
												<span className="fa fa-trash"> </span> <Trans>Delete Inventory</Trans>
											</Button>

											{selectedInv && selectedInv.length === 1 && selectedInv[0]["planning_enabled"] && (
												<Button className="float-right" color="primary" type="button" onClick={() => this.disableInventory(false)}>
													<span className="far fa-calendar-times"> </span> Disable For Planning
												</Button>
											)}

											{selectedInv && selectedInv.length === 1 && !selectedInv[0]["planning_enabled"] && (
												<Button className="float-right" color="default" type="button" onClick={() => this.disableInventory(true)}>
													<span className="far fa-calendar-check"> </span> Enable For Planning
												</Button>
											)}

											{selectedInv && selectedInv.length > 1 && (
												<>
													<Button className="float-right" color="primary" type="button" onClick={() => this.disableInventory(false)}>
														<span className="far fa-calendar-times"> </span> Disable For Planning
													</Button>
													<Button className="float-right" color="default" type="button" onClick={() => this.disableInventory(true)}>
														<span className="far fa-calendar-check"> </span> Enable For Planning
													</Button>
												</>
											)}
										</Alert>
									)}

									{addingInventory ? (
										<button className="btn btn-primary btn-sm mb-2"
											type="button"
											disabled>
											<span className="spinner-border spinner-border-sm"
												role="status"
												aria-hidden="true">
												{" "}
											</span>
											<Trans>Adding</Trans>...
										</button>
									) : vendor_inventory ? (
										<>
											<ReactGrid2
												{...this.props}
												inventory={vendor_inventory?.data}
												pColumns={invColumns}
												setSelectedInventory={this.setSelectedInventory}
												updateInventory={this.updateInventory}
											/>
											<div
												style={{
													display: "flex",
													justifyContent: "space-between",
													alignSelf: "center",
													marginTop: 10,
												}}
											>
												<div>
													<select
														value={this.state.limit}
														onChange={(e) =>
															this.setState({ limit: e.target.value })
														}
													>
														{this.state.limitArray.map((e) => (
															<option key={e.toString()}>{e}</option>
														))}
													</select>
												</div>
												<div style={{ display: "flex" }}>
													<button
														style={{ marginRight: 10, cursor: "pointer" }}
														onClick={() => {
															this.setState({
																currentPage: this.state.currentPage - 1,
															});

														}}
														disabled={this.state.currentPage < 1 ? true : false}
													>
														Prev
													</button>
													<p
														style={{
															marginRight: 10,
															color: "#161616",
															fontWeight: "bold",
															marginBottom: 0,
														}}
													>
														{this.state.currentPage + 1}
													</p>
													<button
														style={{ cursor: "pointer" }}
														onClick={() => {
															this.setState({
																currentPage: this.state.currentPage + 1,
															});
															// fetchMediaOwnerInventory({
															//   page: this.state.currentPage,
															// });
														}}
														disabled={
															vendor_inventory.data.length < this.state.limit ? true : false
														}
													>
														Next
													</button>
												</div>
											</div>
										</>
									) : (
										<button
											className="btn btn-primary btn-sm mb-2"
											type="button"
											disabled
										>
											<span
												className="spinner-border spinner-border-sm"
												role="status"
												aria-hidden="true"
											></span>
											<Trans>Loading</Trans>...
										</button>
									)}
								</CardBody>
							</Card>
						</Col>
						{/* Modal */}
						<Modal className="modal-dialog-centered" isOpen={this.state.syncInvModal} toggle={() => this.toggleModal("syncInvModal")}>
							<div className="modal-header text-center" style={{ background: "#fefefe" }}>
								<h3 className="modal-title" id="syncInvModal">
									<Trans>Sync Media Owner Inventory</Trans>
								</h3>
							</div>
							<div className="modal-body">
								<CardBody className="px-lg-2 py-lg-2">
									<Form role="form">
										<FormGroup className="mb-3">
											<InputGroup className="input-group-alternative">
												<InputGroupAddon addonType="prepend">
													<InputGroupText>
														<i className="ni ni-email-83" />
													</InputGroupText>
												</InputGroupAddon>
												<Input placeholder="Media Owner's Email" type="email" name="email" onChange={this.handleInputChange} value={email} />
											</InputGroup>
										</FormGroup>
									</Form>
								</CardBody>
							</div>
							<div className="modal-footer">
								{
									<Button block color="primary" type="button" onClick={this.syncMediaOwnerInv}>
										{syncProcessing ? "Syncing..." : "Sync Now"}
									</Button>
								}
							</div>
						</Modal>
					</Row>
				</Container>
			</>
		);
	}
}

const mapStateToProps = ({ inv, auth }) => {
	// console.log('inv',inv)
	const { vendor_inventory, added_inventory, error_inventory, site_maps_upload_progress, active_sitemaps_urls } = inv;
	return {
		vendor_inventory,
		added_inventory,
		error_inventory,
		site_maps_upload_progress,
		active_sitemaps_urls,
	};
};

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			addInventory,
			fetchMediaOwnerInventory,
			updateMediaOwnerInventory,
			updateInventorySitemaps,
			updateSiteMapsUploadProgress,
			deletePlanoInventory,
			disablePlanoInventory,
			syncMediaOwnerInventory,
		},
		dispatch
	);

export default connect(mapStateToProps, mapDispatchToProps)(VendorInventory);
