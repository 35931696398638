import React from "react";
import { Translation, Trans } from "react-i18next";

// reactstrap components
import {
	Button,
	Card,
	CardHeader,
	CardBody,
	FormGroup,
	Form,
	Input,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
	Col,
} from "reactstrap";

class Register extends React.Component {
	render() {
		return (
			<>
				<Col lg="6" md="8">
					<Card className="bg-secondary shadow border-0">
						<CardHeader className="bg-transparent pb-5">
							<div className="text-muted text-center mt-2 mb-3">
								<span className="btn-inner--icon">
									<img
										alt="..."
										height="65px"
										width="60px"
										src={require("assets/img/brand/locad_icon.png")}
									/>
								</span>
							</div>
						</CardHeader>
						<CardBody className="px-lg-5 py-lg-5">
							{/* <div className="text-center text-muted mb-4">
                <small>Or sign up with credentials</small>
              </div> */}
							<Form role="form">
								<FormGroup>
									<InputGroup className="input-group-alternative mb-3">
										<InputGroupAddon addonType="prepend">
											<InputGroupText>
												<i className="ni ni-hat-3" />
											</InputGroupText>
										</InputGroupAddon>
										<Translation>
											{(t) => {
												return <Input placeholder={t("Name")} type="text" />;
											}}
										</Translation>
									</InputGroup>
								</FormGroup>
								<FormGroup>
									<InputGroup className="input-group-alternative mb-3">
										<InputGroupAddon addonType="prepend">
											<InputGroupText>
												<i className="ni ni-hat-3" />
											</InputGroupText>
										</InputGroupAddon>
										<Translation>
											{(t) => {
												return <Input placeholder={t("Company")} type="text" />;
											}}
										</Translation>
									</InputGroup>
								</FormGroup>
								<FormGroup>
									<InputGroup className="input-group-alternative mb-3">
										<InputGroupAddon addonType="prepend">
											<InputGroupText>
												<i className="ni ni-email-83" />
											</InputGroupText>
										</InputGroupAddon>
										<Translation>
											{(t) => (
												<Input
													placeholder={t("Email")}
													type="email"
													autoComplete="new-email"
												/>
											)}
										</Translation>
									</InputGroup>
								</FormGroup>
								<FormGroup>
									<InputGroup className="input-group-alternative">
										<InputGroupAddon addonType="prepend">
											<InputGroupText>
												<i className="ni ni-lock-circle-open" />
											</InputGroupText>
										</InputGroupAddon>
										<Translation>
											{(t) => (
												<Input
													placeholder={t("Password")}
													type="password"
													autoComplete="new-password"
												/>
											)}
										</Translation>
									</InputGroup>
								</FormGroup>
								{/* <div className="text-muted font-italic">
                  <small>
                    password strength:{" "}
                    <span className="text-success font-weight-700">strong</span>
                  </small>
                </div> */}

								<div className="text-center">
									<Button className="mt-4" color="primary" type="button">
										<Trans>Create account</Trans>
									</Button>
								</div>
							</Form>
						</CardBody>
					</Card>
				</Col>
			</>
		);
	}
}

export default Register;
