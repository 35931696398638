import _ from "lodash";

export const isEmpty = (value) => {
	if (
		value === null ||
		value === undefined ||
		!value.toString().trim().length
	) {
		// We can return string or jsx as the 'error' prop for the validated Component
		return true;
	}
	return false;
};

export const isValidDate = (value) => {
	if (!value) {
		return false;
	}

	return true;
};

export const isValidPassword = (text) => {
	if (isEmpty(text)) {
		return false;
	} else if (text.length < 8) {
		return false;
	}

	return true;
};

export const validateForm = (fields) => {
	let errors = {};
	fields.forEach((f) => {
		f.validations.forEach((v) => {
			switch (v) {
				case "Required":
					if (isEmpty(f.value)) {
						const fName = f.fieldName || _.capitalize(f.field);
						errors[f.field] = fName + " is Required";
					}
					break;
				case "ValidDate":
					if (!isValidDate(f.value)) {
						const fName = f.fieldName || _.capitalize(f.field);
						errors[f.field] = fName + " is not Valid Date";
					}
					break;
				default: {
				}
			}
		});
	});

	return errors;
};
