import React from "react";
import ChatBox from "./ChatBox";
import Message from "./Message";

class Messages extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			height: 0,
			messages: props.messages,
			activeReceiver: props.activeReceiver,
			gif: false,
		};
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		return {
			messages: nextProps.messages,
			activeReceiver: nextProps.activeReceiver,
		};
	}

	componentDidMount() {
		this.assignHeight();
		window.addEventListener("resize", this.assignHeight.bind(this));
	}

	assignHeight() {
		let chat_height = this.state.gif ? 200 : 35;
		let _docHeight =
			document.height !== undefined
				? document.height
				: document.body.offsetHeight;
		this.setState({
			height: _docHeight - 65 - chat_height,
		});
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.assignHeight.bind(this));
	}

	toggleGif(e) {
		this.setState(
			{
				gif: !this.state.gif,
			},
			() => {
				this.assignHeight();
			}
		);
	}

	render() {
		const { messages, activeReceiver } = this.state;
		const uid = localStorage.getItem("uid");
		let filtered = [];
		console.log("m", activeReceiver, " messages", messages);
		if (messages) {
			messages.forEach((o) => {
				if (o.uid === uid && o.to === activeReceiver.id) {
					o.ownMessage = true;
					filtered.push(o);
				}
				if (o.to === uid && o.uid === activeReceiver.id) {
					o.ownMessage = false;
					filtered.push(o);
				}
			});
		}

		return (
			<div
				className="messages"
				style={{ height: this.state.height + "px", padding: "20px" }}
			>
				{filtered.length > 0 &&
					filtered.map((message, i) => {
						return <Message key={i} message={message} />;
					})}

				{this.state.gif ? (
					<div></div>
				) : (
					<ChatBox
						sendMessage={this.props.sendMessage}
						toggleGif={this.toggleGif.bind(this)}
					/>
				)}
			</div>
		);
	}
}

export default Messages;
