import {
  GoogleMap,
  LoadScript,
  Marker,
  Autocomplete,
  Circle,
} from "@react-google-maps/api";
import React, { Fragment, useState } from "react";
import { Input, InputGroup, Row, Col, Button } from "reactstrap";
import PlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from "react-places-autocomplete";

const FilterMap = (props) => {
  //   console.log(props);
  const { filterChange, show } = props;

  const [address, setAddress] = useState("");

  const [radius, setRadius] = useState();
  const [open, setOpen] = useState(false);

  let [lat, setLat] = useState(28.7041);
  let [lngs, setLngs] = useState(77.1025);
  // console.log(props.filters);
  const [filterObj, setFilterObj] = useState({
    ...props.filters,
    flag: "geoLocation",
    lat: parseFloat(lat),
    lng: parseFloat(lngs),
  });

  const [autocomplete, setAutoComplete] = useState(null);

  const onLoad = (autocomplete) => {
    setAutoComplete(autocomplete);
  };


  const [a, setA] = useState();
  const [poligon, setPoligon] = useState();

  // console.log(window.google.maps);


  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      // console.log(autocomplete.getPlace());
      var random = autocomplete.getPlace();
      var lat = random.geometry.location.lat();
      var lng = random.geometry.location.lng();
      setLat(parseFloat(lat));
      setLngs(parseFloat(lng));
      setFilterObj({
        ...props.filters,
        ...filterObj,
        lat: parseFloat(lat),
        lng: parseFloat(lng),
      });

    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  };

  const handleSelect = async (value) => {
    // console.log(value);
    const results = await geocodeByAddress(value);
    // console.log(results);
    const ll = await getLatLng(results[0]);
    console.log(ll);
    setAddress(value);
    setLat(parseFloat(ll.lat));
    setLngs(parseFloat(ll.lng));
    setFilterObj({
      ...props.filters,
      ...filterObj,
      lat: parseFloat(ll.lat),
      lng: parseFloat(ll.lng),
    });
    // if(ll & value){
    //     handleLat(parseFloat(ll.lat));
    //     handleLng(parseFloat(ll.lng));
    // }
  };

  const onMarkerDragEnd = (coord) => {
    console.log("coord", coord.latLng.lat(coord), coord.latLng.lng(coord));
    setLat(parseFloat(coord.latLng.lat(coord)));
    setLngs(parseFloat(coord.latLng.lng(coord)));
    center.lat = parseFloat(coord.latLng.lat(coord));
    center.lng = parseFloat(coord.latLng.lng(coord));
  };

  const containerStyle = {
    width: "100%",
    height: "300px",
  };

  const center = {
    lat: parseFloat(lat),
    lng: parseFloat(lngs),
  };

  const handleRadius = (e) => {
    setRadius(parseFloat(e.target.value));
    setFilterObj({ ...props.filters, ...filterObj, rad: parseFloat(e.target.value) });
  };
  const handleLat = (e) => {
    setLat(parseFloat(e.target.value));
    setFilterObj({ ...props.filters, ...filterObj, lat: parseFloat(e.target.value) });
  };
  const handleLng = (e) => {
    setLngs(parseFloat(e.target.value));
    setFilterObj({ ...props.filters, ...filterObj, lng: parseFloat(e.target.value) });
  };

  const options = {
    strokeColor: "#FF0000",
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: "#FF0000",
    fillOpacity: 0.35,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: radius * 1000,
    zIndex: 1,
  };

  const onUnmount = (circle) => console.log("Circle", circle);

  return (
    <>
      <div
        className={show ? "shadow mb-sm-3" : "d-none"}
        style={{ backgroundColor: "#fff", padding: "1.25rem 1.25rem" }}
      >
        <Row>
          <Col md={11}>
            <h4
              style={{
                fontSize: "1.0625rem",
                // fontWeight: "bold",
                // padding: "10px 20px 0px 20px",
              }}
            >
              Geo Search with in Radius
            </h4>
          </Col>
          <Col md={1}>
            <i
              title={!open ? "Show More" : "Hide"}
              class={!open ? "fa fa-caret-down" : "fa fa-caret-up"}
              aria-hidden="true"
              style={{
                fontSize: 20,
                cursor: "pointer",
                // padding: "10px 20px 0px 20px",
              }}
              onClick={() => setOpen(!open)}
            ></i>
          </Col>
        </Row>
        {open ? (
          <>
            <Row style={{ padding: 10 }}>
              <div
                style={{
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                <Col md={4}>
                  <Input
                    type="number"
                    placeholder="latitude"
                    value={lat}
                    onChange={handleLat}
                  />
                </Col>
                <Col md={4}>
                  <Input
                    type="number"
                    placeholder="longitude"
                    value={lngs}
                    onChange={handleLng}
                  />
                </Col>
                <Col md={4}>
                  <Input
                    type="number"
                    placeholder="Radius in Kms"
                    value={radius}
                    onChange={handleRadius}
                  />
                </Col>
                <Col style={{ marginTop: "10px" }}>
                  <Button
                    color="success"
                    onClick={() => filterChange(filterObj)}
                  >
                    FIND
                  </Button>
                </Col>
              </div>
            </Row>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                position: "relative",
                padding: "10px",
                width: "100%",
              }}
            >
              <div
                style={{ position: "absolute", zIndex: "1", padding: "10px" }}
              >
              </div>
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={13}
              >
                <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
                  <input
                    type="text"
                    placeholder="Enter City Name"
                    style={{
                      boxSizing: `border-box`,
                      border: `1px solid transparent`,
                      width: `240px`,
                      height: `32px`,
                      padding: `0 12px`,
                      borderRadius: `3px`,
                      boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                      fontSize: `14px`,
                      outline: `none`,
                      textOverflow: `ellipses`,
                      position: "absolute",
                      left: "50%",
                      marginLeft: "-120px",
                    }}
                  />
                </Autocomplete>

                <Marker
                  position={center}
                  draggable
                  onDragEnd={onMarkerDragEnd}
                ></Marker>
                <Circle
                  center={center}
                  options={options}
                ></Circle>
              </GoogleMap>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default FilterMap;




