import React from "react";
import {
	Modal,
	Card,
	Button,
	CardBody,
	Form,
	FormGroup,
	Input,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	CardHeader,
} from "reactstrap";
import { Trans } from "react-i18next";

class DownloadPPTModal extends React.Component {
	state = {
		emails: "",
	};

	emailHander = (e) => {
		const target = e.target;
		const emails = target.value;
		this.setState({ emails: emails });
	};

	submitDownload = () => {
		const { downloadPPT } = this.props;
		const { emails } = this.state;
		downloadPPT(emails);
	};

	render() {
		const { show, closePPTModal } = this.props;
		const { emails } = this.state;
		return (
			<Modal
				className="modal-dialog-centered"
				size="sm"
				isOpen={show}
				toggle={() => closePPTModal()}
			>
				<div className="modal-body p-0">
					<Card className="bg-secondary shadow border-0">
						<CardHeader className="bg-transparent pb-2">
							<div className="text-muted text-center mt-2 mb-1">
								<h3>
									<Trans>Download PPT</Trans>
								</h3>
							</div>
						</CardHeader>
						<CardBody className="px-lg-3 py-lg-3">
							<Form role="form">
								<FormGroup className="mb-3">
									<InputGroup className="input-group-alternative">
										<InputGroupAddon addonType="prepend">
											<InputGroupText>
												<i className="ni ni-email-83" />
											</InputGroupText>
										</InputGroupAddon>
										<Input
											placeholder="Enter Comma Separated Emails"
											type="email"
											onChange={this.emailHander}
											value={emails}
										/>
									</InputGroup>
								</FormGroup>
								{/* <FormGroup>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input placeholder="Password" type="password" />
                      </InputGroup>
                    </FormGroup> */}
								<div className="text-center">
									<Button
										className="my-4"
										color="primary"
										type="button"
										onClick={this.submitDownload}
									>
										<Trans>Download</Trans>
									</Button>
								</div>
							</Form>
						</CardBody>
					</Card>
				</div>
			</Modal>
		);
	}
}

export default DownloadPPTModal;
