import React from "react";
import { Trans } from "react-i18next";
import {
	Card,
	Row,
	Col,
	CardBody,
} from "reactstrap";

import Messages from "./Messages";

class Users extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			users: props.users,
			online_users: props.online_users,
			activeReceiver: props.activeReceiver,
			showMessages: props.showMessages,
			reciever: "",
		};
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		return {
			users: nextProps.users,
			online_users: nextProps.online_users,
			activeReceiver: nextProps.activeReceiver,
			showMessages: nextProps.showMessages,
		};
	}

	handleMessagePanel = (user) => {
		const { setActiveReceiver } = this.props;
		//  this.setState({showMessages:true, reciever:user})
		setActiveReceiver(user);
	};

	isUserOnline = (user) => {
		const { online_users } = this.state;
		if (online_users.indexOf(user) === -1) {
			return false;
		}
		return true;
	};

	render() {
		const { messages, sendMessage } = this.props;
		const { users, showMessages, activeReceiver } = this.state;

		return (
			<div className="users" style={{ cursor: "pointer" }}>
				{!showMessages &&
					users.length &&
					users.map((user, i) => {
						return (
							<div key={i}>
								<Card
									className="card-stats mb-4 mb-lg-0"
									onClick={() => this.handleMessagePanel(user)}
								>
									<CardBody>
										<Row>
											{/* <div className="col">
                                    <CardTitle className="text-uppercase text-muted mb-0">
                                         {user}
                                    </CardTitle>
                                   
                                    </div> */}
											<Col className="col-auto">
												<div className="icon icon-shape bg-primary text-white rounded-circle shadow">
													<i className="ni ni-single-02" />
												</div>
												<span
													style={{
														position: "absolute",
														width: "15px",
														height: "15px",
														borderRadius: "50%",
														bottom: "0",
														right: "20%",
														border: "2px solid white",
														background: this.isUserOnline(user.name)
															? "#69D42C"
															: "#DBDBDB",
														// background:"#69D42C"
													}}
												></span>
											</Col>
											<div className="col">
												<p className="mb-0">{user.name}</p>

												{/* { messages && messages.length>0  &&
                                          <span className="p font-weight-bold mb-0">
                                            {messages[0]['message']['text']}
                                         </span>
                                       }          */}
											</div>

											{/* <Col>
                                   <Badge style={{background:"red", borderRadius:"50%"}}>45</Badge>
                                   </Col> */}
										</Row>
									</CardBody>
								</Card>
							</div>
						);
					})}

				{!showMessages && users.length === 0 && <h4><Trans>No Users Online</Trans></h4>}

				{showMessages && (
					<Messages
						activeReceiver={activeReceiver}
						sendMessage={sendMessage}
						messages={messages}
					/>
				)}
			</div>
		);
	}
}

export default Users;
