import React from "react";
import {
  Container,
  Card,
  Row,
  Col,
  Button,
  CardBody,
  Alert,
  UncontrolledTooltip,
  CardHeader,
} from "reactstrap";
// core components
import CompHeader from "components/Headers/CompHeader.js";
import {
  addInventory,
  fetchMediaOwnerInventory,
  updateMediaOwnerInventory,
  updateInventorySitemaps,
  updateSiteMapsUploadProgress,
} from "actions/invActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Alerts from "../alerts";
import ReactGrid2 from "../../views/campaigns/ReactGrid2";
import XLSX from "xlsx";
import { CSVLink } from "react-csv";
import _, { get } from "lodash";
import { Trans } from "react-i18next";
import { inv_site_headers } from "helpers";
import i18n from "../../i18n";
import axios from "axios";
import { devUrl } from "../../utility";
import { UilFileDownload } from '@iconscout/react-unicons'
let invColumns = [
  // { title: "Media Owner", name: "media_owner", visible: true },
  {
    title: "Sitemap",
    name: "site_map",
    render: (rowData) => (
      <img src={rowData.site_map} alt="site map" style={{ width: 100 }} />
    ),
    visible: true,
  },
  { title: "Media Owner", name: "media_owner", visible: true },
  { title: "Site Code", name: "site_code", visible: true },
  { title: "Location", name: "location", visible: true },
  { title: "Width", name: "width", visible: true },
  { title: "Height", name: "height", visible: true },
  { title: "City", name: "city", visible: true },
  { title: "State", name: "state", visible: true },
  { title: "Media Type", name: "media_type", visible: true },
  { title: "Price", name: "inventory_cost", visible: true },
];
let newInvColumns = [
  { title: "Sitemap", name: "site_map", visible: true, },
  { title: "Media Owner", name: "media_owner", visible: true },
  { title: "Site Code", name: "site_code", visible: true },
  { title: "Location", name: "location", visible: true },
  { title: "Width", name: "width", visible: true },
  { title: "Height", name: "height", visible: true },
  { title: "City", name: "city", visible: true },
  { title: "State", name: "state", visible: true },
  { title: "Media Type", name: "media_type", visible: true },
  { title: "Price", name: "inventory_cost", visible: true },
  { title: "proposed media", name: "proposed_media", visible: true },
  { title: "units", name: "units", visible: true },
  { title: "type", name: "type", visible: true },
  { title: "size", name: "size", visible: true }
];

let templateColumns = [
  { name: "site_map" },
  { name: "media_owner" },
  { name: "site_code" },
  { name: "state" },
  { name: "city" },
  { name: "network" },
  { name: "cluster" },
  { name: "location" },
  { name: "address" },
  { name: "vendor" },
  { name: "height" },
  { name: "width" },
  { name: "screen_size_inch_feet" },
  { name: "media_type" },
  { name: "units" },
  { name: "monthly_shared_cost" },
  { name: "loop_time_minuts" },
  { name: "operation_hours" },
  { name: "monthly_impression" },
  { name: "latitude" },
  { name: "longitude" },
  { name: "start_time" },
  { name: "end_time" },
  { name: "creative_format" },
  { name: "resolution" },
  { name: "appartment_price_band" },
  { name: "income_sector" },
  // { name: "inventory_cost" },
  // { name: "proposed_media" },
  // { name: "type" },
  // { name: "size" },
  // { name: "ad_slot_seconds" },  
];
console.log(
  "language1",
  localStorage.getItem("lng"),
  localStorage.getItem("lng") === "es"
);
if (localStorage.getItem("lng") === "es") {
  console.log("language", localStorage.getItem("lng"));
  invColumns = [
    { title: "Propietario de medios", name: "media_owner", visible: true },
    {
      title: "Mapa del sitio",
      name: "site_map",
      render: (rowData) => (
        <img src={rowData.site_map} alt="site map" style={{ width: 100 }} />
      ),
      visible: true,
    },
    { title: "Propietario de medios", name: "media_owner", visible: true },
    { title: "código del sitio", name: "site_code", visible: true },
    { title: "Localización", name: "location", visible: true },
    { title: "Ancho", name: "width", visible: true },
    { title: "Altura", name: "height", visible: true },
    { title: "Ciudad", name: "city", visible: true },
    { title: "Estado", name: "state", visible: true },
    { title: "Tipo de medio", name: "media_type", visible: true },
    { title: "Precio", name: "inventory_cost", visible: true },
  ];
}


class InventoryManagement extends React.Component {
  state = {
    selectedInv: [],
    showCard: {
      map: false,
    },
    showPPTModal: false,
    activeAlert: "",
    addingInventory: false,
    currentPage: 0,
    limit: 10,
    searchKeyword: "",
    limitArray: [10, 20, 30, 40, 50],
    stateCount: 0,
    cityCount: 0,
    isDownloading: false,
  };

  constructor(props) {
    super(props);
    this.inputReference = React.createRef();
    this.photosRef = React.createRef();
  }

  componentDidMount() {
    const { fetchMediaOwnerInventory } = this.props;
    fetchMediaOwnerInventory({ page: this.state.currentPage });
    this.totalState()
  }


  componentDidUpdate(prevProps, prevState) {
    // console.log(this.state.currentPage !== prevState.currentPage);
    const { fetchMediaOwnerInventory } = this.props;
    if (this.props.added_inventory !== prevProps.added_inventory) {
      var added = this.props.added_inventory.length;
      var errors = this.props.error_inventory.length;
      var total = added + errors;
      var message = added + " out of " + total + " added successfully";
      this.setState({ addingInventory: false, activeAlert: message });
    }
    if (this.state.currentPage !== prevState.currentPage) {
      fetchMediaOwnerInventory({ page: this.state.currentPage, limit: this.state.limit });
    }
    if (this.state.searchKeyword !== prevState.searchKeyword) {
      fetchMediaOwnerInventory({ search: this.state.searchKeyword });
    }
    if (this.state.limit !== prevState.limit) {
      fetchMediaOwnerInventory({ limit: this.state.limit, page: this.state.currentPage });
    }

    // UPDATE INVENTORY SITEMAPS
    if (this.props.active_sitemaps_urls !== prevProps.active_sitemaps_urls) {
      const { userId } = this.props.match.params;
      const { updateInventorySitemaps, fetchMediaOwnerInventory } = this.props;
      if (
        this.props.active_sitemaps_urls &&
        this.props.active_sitemaps_urls.length
      ) {
        updateInventorySitemaps({
          site_maps: this.props.active_sitemaps_urls,
          vendor_id: userId,
        });
      }

      if (!this.props.active_sitemaps_urls) {
        fetchMediaOwnerInventory({ vendor_id: userId });
      }
    }
  }

  nextButtonHandler = () => {
    fetchMediaOwnerInventory(2);
  };


  toggleShowCard = (key) => {
    this.setState((prevState) => ({
      ...prevState,
      showCard: {
        ...prevState.showCard,
        [key]: !this.state.showCard[key],
      },
    }));
  };

  addInventory = (e) => {
    const files = e.target.files;
    const file = files[0];
    const { addInventory } = this.props;

    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;

    reader.onload = (e) => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, {
        type: rABS ? "binary" : "array",
        bookVBA: true,
      });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws);
      /* Update state */
      // console.log("dat", data);

      const vendor_id = "5f2392a67f748f694d83db5c";
      addInventory({ data, vendor_id });

      this.setState({ addingInventory: true });
    };

    if (rABS) {
      reader.readAsBinaryString(file);
    } else {
      reader.readAsArrayBuffer(file);
    }
  };

  updateInventory = (data) => {
    console.log("inv update", data);
    const { updateMediaOwnerInventory } = this.props;
    updateMediaOwnerInventory({ data });
  };

  fileUploadAction = () => {
    this.inputReference.current.click();
  };

  photosUploadAction = () => {
    this.photosRef.current.click();
  };

  closeActiveAlert = () => {
    this.setState({ activeAlert: "" });
  };

  setSelectedInventory = (selectedRows) => {
    // console.log("sec", selectedRows);
    this.setState({ selectedInv: selectedRows });
  };

  uploadSiteMaps = (e) => {
    const files = e.target.files;
    const bucket = "locauditooh-images";
    const type = "site_map";
    const { userId } = this.props.match.params;
    const { updateSiteMapsUploadProgress } = this.props;

    updateSiteMapsUploadProgress({
      files,
      bucket,
      type,
      additional_data: userId,
    });
  };


  // total count of states
  totalState = async () => {
    const totalCount = await axios.get(`${devUrl}/findCitiesStates`, {
      headers: { authorization: localStorage.getItem("plano_token") },
    })
    console.log("total count", totalCount);
    this.setState({ stateCount: totalCount.data.stateCount, cityCount: totalCount.data.cityCount })
  }


  // select and download
  downloadInventory = () => {
    var selectData = this.state.selectedInv

    for (var i = 0; i < selectData.length; i++) {
      selectData[i] = { ...selectData[i], ...selectData[i].additional_data }

      delete selectData[i].additional_data
      delete selectData[i].loc
      delete selectData[i].planning_enabled
      delete selectData[i].id
      delete selectData[i].uploaded_by
    }

    var wb = XLSX.utils.book_new()
    var ws = XLSX.utils.json_to_sheet(selectData)

    XLSX.utils.book_append_sheet(wb, ws, "sheet1");
    XLSX.writeFile(wb, "Select INV Download.xlsx");
    console.log("Select INV Download", selectData);
  }


  // All Inventory Download

  allINVDownload = () => {
    this.setState({ isDownloading: true });
    axios.get(`${devUrl}/download_all_inventory`,
      { headers: { authorization: localStorage.getItem("plano_token") } })

      .then((Response) => {
        // console.log("helloooo", Response)
        var getData = Response.data.data

        for (var i = 0; i < getData.length; i++) {
          getData[i] = { ...getData[i], ...getData[i].additional_data }
          delete getData[i].additional_data
          delete getData[i].loc
          delete getData[i].planning_enabled
          delete getData[i].id
          delete getData[i].uploaded_by
        }
        this.setState({ isDownloading: false });
        var wb = XLSX.utils.book_new()
        var ws = XLSX.utils.json_to_sheet(getData)

        XLSX.utils.book_append_sheet(wb, ws, "sheet1");
        XLSX.writeFile(wb, "Select INV Download.xlsx");
        console.log("rquest dowmload successful", Response)
      })
      .catch((error) => {
        console.log("download Inventory rquest failed", error)
        this.setState({ isDownloading: false });
      })

  }



  // Select and Delete Site
  deleteSite = () => {
    var deleteData = this.state.selectedInv;
    const { fetchMediaOwnerInventory } = this.props;

    axios.post(`${devUrl}/delete_Select_Inventory`, {
      deleteData,
    })
      .then((Response) => {
        console.log("success Delete", Response)
        if (Response.status === 200) {
          fetchMediaOwnerInventory({ page: this.state.currentPage });
        }
      })
      .catch((error) => {
        console.log("delete Failed", error)
      })

    console.log("delete Site", deleteData)
  }




  // Download Template
  downloadTemplate = () => {
    var newData = this.customHeaders = [
      templateColumns
        .map((i) => {

          if (!i.visible) return _.snakeCase(i.name);
          else return _.snakeCase(i.name);
        })
        .filter((f) => f && f !== "site_map"),
    ];

    var wb = XLSX.utils.book_new()
    var ws = XLSX.utils.json_to_sheet(newData)

    XLSX.utils.book_append_sheet(wb, ws, "sheet1");
    XLSX.writeFile(wb, "Vendors sheet.xlsx");
    console.log("Vendors Data", newData);
  }

  render() {
    // console.log(
    //   "language3",
    //   localStorage.getItem("lng"),
    //   localStorage.getItem("lng") === "es"
    // );
    // console.log(this.props.vendor_inventory);
    const { vendor_inventory } = this.props;
    const { activeAlert, addingInventory } = this.state;
    const { isDownloading } = this.state;
    // console.log("data check::::", vendor_inventory)
    if (
      vendor_inventory &&
      vendor_inventory[0] &&
      vendor_inventory[0]["additional_data"]
    ) {
      _.mapKeys(vendor_inventory[0]["additional_data"], (value, key) => {
        let title = i18n.t(inv_site_headers[key]);
        console.log("title", title);
        if (!title) {
          title = i18n.t(_.capitalize(key));
        }

        invColumns.push({
          title: title,
          name: "additional_data." + key,
          visible: false,
        });
      });

      invColumns.push({
        title: i18n.t(_.capitalize("cost_3")),
        name: "additional_data.cost_3",
        visible: false,
      });
      invColumns.push({
        title: i18n.t(_.capitalize("sell_price_3")),
        name: "additional_data.sell_price_3",
        visible: false,
      });
      invColumns.push({
        title: i18n.t(_.capitalize("installation_cost")),
        name: "additional_data.installation_cost",
        visible: false,
      });

      invColumns = _.uniqBy(invColumns, "name");
    }

    // console.log(invColumns);

    //let customHeaders = [invColumns.map((i) => _.snakeCase(i.title)).filter((f) => f && f !== "sitemap")];
    let customHeaders = [
      invColumns
        .map((i) => {
          if (!i.visible) return _.snakeCase(i.title);
          else return _.snakeCase(i.name);
        })
        .filter((f) => f && f !== "site_map"),
    ];


    return (
      <>
        <CompHeader />
        <Container fluid>
          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-1">
                        <Trans>Inventory Management Sites</Trans>{" "}
                        {vendor_inventory &&
                          "(" + vendor_inventory.total_count + ")"}
                      </h3>

                      <h3 className="mb-0">
                        <Trans>Total Cities</Trans>{" "}
                        {vendor_inventory &&
                          "(" + this.state.cityCount + ")"}
                      </h3>

                      <h3 className="mb-0">
                        <Trans>Total States</Trans>{" "}
                        {vendor_inventory &&
                          "(" + this.state.stateCount + ")"}
                      </h3>
                    </div>
                    <div className="col text-right">
                      {/* <CSVLink
                        style={{ color: "#fff", width: "100%" }}
                        target=""
                        data={customHeaders}
                        filename={"Add Inventory Template.csv"}
                      >
                        <Button
                          id="exportBtn"
                          color="default"
                          // onClick={(e) => e.preventDefault()}
                          style={{ marginRight: ".5rem" }}
                          size="m"
                        >
                          <span className="fa fa-download"></span>
                        </Button>
                      </CSVLink>

                      <UncontrolledTooltip
                        delay={0}
                        placement="top"
                        target="exportBtn"
                      >
                        <Trans>Download Template</Trans>
                      </UncontrolledTooltip> */}

                      <Button
                        style={{ "backgroundColor": "black", "color": "white" }}
                        onClick={this.downloadTemplate}
                      >
                        <Trans>Download Template</Trans>
                      </Button>
                      {this.state.selectedInv.length == 0 ? <></> : <Button
                        style={{ "backgroundColor": "black", "color": "white" }}
                        onClick={this.downloadInventory}
                      >
                        <Trans>Download Site</Trans>
                      </Button>}
                      {this.state.selectedInv.length == 0 ? <></> : <Button
                        style={{ "backgroundColor": "red", "color": "white" }}
                        onClick={this.deleteSite}
                      >
                        <Trans>Delete Site</Trans>
                      </Button>}

                      <Button
                        style={{ "backgroundColor": "black", "color": "white" }}
                        onClick={this.allINVDownload}
                        disabled={isDownloading}
                      >
                        <Trans>{isDownloading ? <UilFileDownload/> : 'All INV Download'}</Trans>
                      </Button>

                      <Button
                        id="addInv"
                        color="default"
                        onClick={this.fileUploadAction}
                        size="m"
                      >
                        {addingInventory ? (
                          <span className="fa fa-spinner"></span>
                        ) : (
                          <span className="fa fa-plus"></span>
                        )}
                      </Button>
                      <input
                        hidden
                        ref={this.inputReference}
                        type="file"
                        className="km-btn-file"
                        onChange={this.addInventory}
                      ></input>
                      <UncontrolledTooltip
                        delay={0}
                        placement="top"
                        target="addInv"
                      >
                        <Trans>Add Inventory</Trans>
                      </UncontrolledTooltip>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Alerts />
                  {/* { ---- Alert ----- } */}
                  {activeAlert && (
                    <Col>
                      <Alert style={{ overflow: "hidden" }} color="success">
                        <Row>
                          <Col lg="11">
                            <strong> {activeAlert}</strong>
                          </Col>
                          <Col
                            lg="1"
                            className="col text-right"
                            onClick={this.closeActiveAlert}
                            style={{ cursor: "pointer" }}
                          >
                            <strong>X</strong>
                          </Col>
                        </Row>
                      </Alert>
                    </Col>
                  )}


                  {this.state.selectedInv.length == 0 ? <></> :
                    <h3 className="mb-0">
                      <Trans>Select</Trans>{""}
                      {"(" + this.state.selectedInv.length + ")"}
                    </h3>}


                  {addingInventory ? (
                    <button
                      className="btn btn-primary btn-sm mb-2"
                      type="button"
                      disabled
                    >
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      >
                        {" "}
                      </span>
                      <Trans>Adding</Trans>...
                    </button>
                  ) : vendor_inventory ? (
                    <>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search By Site-Code"
                        value={this.state.searchKeyword}
                        onChange={(e) =>
                          this.setState({ searchKeyword: e.target.value })
                        }
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          position: "absolute",
                          right: "70px",
                          zIndex: "999999",
                          width: "20%",
                          height: "37px",
                        }}
                      />

                      <ReactGrid2
                        {...this.props}
                        inventory={vendor_inventory.data}
                        pColumns={invColumns}
                        setSelectedInventory={this.setSelectedInventory}
                        updateInventory={this.updateInventory}
                      />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignSelf: "center",
                          marginTop: 10,
                        }}
                      >
                        <div>
                          <select
                            value={this.state.limit}
                            onChange={(e) =>
                              this.setState({ limit: e.target.value })
                            }
                          >
                            {this.state.limitArray.map((e) => (
                              <option key={e.toString()}>{e}</option>
                            ))}
                          </select>
                        </div>
                        <div style={{ display: "flex" }}>
                          <button
                            style={{ marginRight: 10, cursor: "pointer" }}
                            onClick={() => {
                              this.setState({
                                currentPage: this.state.currentPage - 1,
                              });

                              // fetchMediaOwnerInventory({
                              //   page: this.state.currentPage,
                              // });
                            }}
                            disabled={this.state.currentPage < 1 ? true : false}
                          >
                            Prev
                          </button>
                          <p
                            style={{
                              marginRight: 10,
                              color: "#161616",
                              fontWeight: "bold",
                              marginBottom: 0,
                            }}
                          >
                            {this.state.currentPage + 1}
                          </p>
                          <button
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              this.setState({
                                currentPage: this.state.currentPage + 1,
                              });
                              // fetchMediaOwnerInventory({
                              //   page: this.state.currentPage,
                              // });
                            }}
                            disabled={
                              vendor_inventory.data.length < this.state.limit ? true : false
                            }
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    </>
                  ) : (
                    <button
                      className="btn btn-primary btn-sm mb-2"
                      type="button"
                      disabled
                    >
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <Trans>Loading</Trans>...
                    </button>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = ({ inv }) => {
  const {
    vendor_inventory,
    added_inventory,
    error_inventory,
    site_maps_upload_progress,
    active_sitemaps_urls,
  } = inv;
  return {
    vendor_inventory,
    added_inventory,
    error_inventory,
    site_maps_upload_progress,
    active_sitemaps_urls,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      addInventory,
      fetchMediaOwnerInventory,
      updateMediaOwnerInventory,
      updateInventorySitemaps,
      updateSiteMapsUploadProgress,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InventoryManagement);
