import {
	FETCH_CAMPAIGNS_PENDING,
	FETCH_CAMPAIGNS_SUCCESS,
	ADD_CAMPAIGN,
	ADD_CAMPAIGN_PENDING,
	FETCH_DISTINCT_FILTERS,
	FETCH_CAMP_DETAILS,
	DELETE_CAMPAIGNS,
	LOC_CAMPS_CREATED,
	UPDATE_CAMPAIGN,
	UPDATE_CAMP_PENDING,
	RESET_CAMP_UPDATE,
} from "../actions/types";

const initialState = {
	camps_loading: false,
	adding_camp: false,
	error: null,
	loc_camps: [],
};

export default (state = initialState, action) => {
	switch (action.type) {
		case FETCH_CAMPAIGNS_PENDING:
			return { ...state, error: "", camps_loading: true, type: action.payload };
		case FETCH_CAMPAIGNS_SUCCESS:
			return {
				...state,
				error: "",
				camps_loading: false,
				campaigns: action.payload,
			};
		case FETCH_DISTINCT_FILTERS:
			return { ...state, filters: action.payload };
		case ADD_CAMPAIGN_PENDING:
			return { ...state, adding_camp: true };
		case FETCH_CAMP_DETAILS:
			return { ...state, camp_details: action.payload };
		case ADD_CAMPAIGN:
			return {
				...state,
				error: "",
				camps_loading: false,
				campaigns: [...state.campaigns, action.payload],
				adding_camp: false,
				active_camp: action.payload,
			};
		case DELETE_CAMPAIGNS:
			return {
				...state,
				campaigns: state.campaigns.filter(
					(item) => action.payload.indexOf(item._id) === -1
				),
			};
		case LOC_CAMPS_CREATED:
			return { ...state, loc_camps: state.loc_camps.concat(action.payload) };
		case UPDATE_CAMP_PENDING:
			return { ...state, updating_camp: "pending" };
		case UPDATE_CAMPAIGN:
			return { ...state, updating_camp: "success" };
		case RESET_CAMP_UPDATE:
			return { ...state, updating_camp: null };
		default: {
			return state;
		}
	}
};
