export const AUTH_USER = "AUTH_USER";
export const UNAUTH_USER = "UNAUTH_USER";
export const AUTH_ERROR = "AUTH_ERROR";
export const SIGNING_IN = "SIGNING_IN";
export const FETCH_CAMPAIGNS_PENDING = "FETCH_CAMPAIGNS_PENDING";
export const FETCH_CAMPAIGNS_SUCCESS = "FETCH_CAMPAIGNS_SUCCESS";
export const ADD_CAMPAIGN = "ADD_CAMPAIGN";
export const ADD_CAMPAIGN_PENDING = "ADD_CAMPAIGN_PENDING";
export const UPDATE_CAMP_PENDING = "UPDATE_CAMP_PENDING";
export const UPDATE_CAMPAIGN = "UPDATE_CAMPAIGN";
export const RESET_CAMP_UPDATE = "RESET_CAMP_UPDATE";
export const FETCH_DISTINCT_FILTERS = "FETCH_DISTINCT_FILTERS";
export const FETCH_SUGG_INVENTORY = "FETCH_SUGG_INVENTORY";
export const FETCH_NETWORKS = "FETCH_NETWORKS";
export const RESET_INVENTORY = "RESET_INVENTORY";
export const FETCH_CAMP_DETAILS = "FETCH_CAMP_DETAILS";
export const FETCH_NEARBY_PLACES = "FETCH_NEARBY_PLACES";
export const FETCH_BOOKED_INVENTORY = "FETCH_BOOKED_INVENTORY";
export const FETCH_BOOKED_INVENTORY_PENDING = "FETCH_BOOKED_INVENTORY_PENDING";
export const FETCH_BOOKED_INVENTORY_SUCCESS = "FETCH_BOOKED_INVENTORY_SUCCESS";
export const FETCH_BOOKED_INVENTORY_ERROR = "FETCH_BOOKED_INVENTORY_ERROR";
export const SEND_INV_APPROVAL = "SEND_INV_APPROVAL";
export const FETCH_CHAT_USERS = "FETCH_CHAT_USERS";
export const FETCH_APPROVAL_INVENTORY = "FETCH_APPROVAL_INVENTORY";
export const FETCH_USER_DETAILS = "FETCH_USER_DETAILS";
export const UPDATE_USER_DETAILS = "UPDATE_USER_DETAILS";
export const FETCH_ALL_POLICIES = "FETCH_ALL_POLICIES";
export const ADD_USER_ROLE = "ADD_USER_ROLE";
export const ADD_POLICY = "ADD_POLICY";
export const FETCH_USER_ROLES = "FETCH_USER_ROLES";
export const ADD_MEMBER = "ADD_MEMBER";
export const FETCH_MEMBERS = "FETCH_MEMBERS";
export const FETCH_MEDIA_OWNER_INVENTORY = "FETCH_MEDIA_OWNER_INVENTORY";
export const ADD_INVENTORY = "ADD_INVENTORY";
export const FETCH_ALL_MEDIA_OWNERS = "FETCH_ALL_MEDIA_OWNERS";
export const UPLOAD_SITEMAPS_PROGRESS = "UPLOAD_SITEMAPS_PROGRESS";
export const SET_SITEMAPS_URLS = "SET_SITEMAPS_URLS";
export const UPDATE_SITEMAPS = "UPDATE_SITEMAPS";
export const RESET_SITEMAPS_URLS = "RESET_SITEMAPS_URLS";
export const DELETE_PLANO_INVENTORY = "DELETE_PLANO_INVENTORY";
export const FETCH_DISTINCT_CITY_STATES = "FETCH_DISTINCT_CITY_STATES";
export const ADD_PROPER_MEDIA_OWNER = "ADD_PROPER_MEDIA_OWNER";
export const DELETE_CAMPAIGNS = "DELETE_CAMPAIGNS";
export const REMOVE_BOOKED_SITES = "REMOVE_BOOKED_SITES";
export const LOC_CAMPS_CREATED = "LOC_CAMPS_CREATED";
export const ADD_PROPER_MEDIA_OWNER_ERR = "ADD_PROPER_MEDIA_OWNER_ERR";
export const DELETE_MEMBER = "DELETE_MEMBER";
export const DISABLE_PLANO_INVENTORY = "DISABLE_PLANO_INVENTORY";
