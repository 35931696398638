import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { signInUser } from "../../actions/authActions";
import { Trans, Translation } from "react-i18next";

// reactstrap components
import {
	Button,
	Card,
	CardHeader,
	CardBody,
	FormGroup,
	Form,
	Input,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
	Row,
	Col,
} from "reactstrap";

class Login extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			email: "",
			password: "",
		};
	}

	handleInputChange = (e) => {
		const target = e.target;
		const name = target.name;
		const value = target.value;

		this.setState({
			[name]: value,
		});
	};

	handleSubmit = (e) => {
		e.preventDefault();
		const { email, password } = this.state;
		const { signInUser } = this.props;

		this.setState({
			signingIn: true,
		});

		signInUser({ email, password });
	};

	render() {
		var { email, password } = this.state;
		var { signing_in } = this.props;
		return (
			<>
				<Col lg="5" md="7">
					<Card className="bg-secondary shadow border-0">
						<CardHeader className="bg-transparent pb-5">
							<div className="text-muted text-center mt-2 mb-3">
								<span className="btn-inner--icon">
									<img
										alt="..."
										height="65px"
										width="auto"
										src={require("assets/img/brand/plano_logo3.png")}
									/>
								</span>
							</div>
						</CardHeader>
						<CardBody className="px-lg-5 py-lg-5">
							<div className="text-center text-muted mb-4">
                <small>Or sign in with credentials</small>
              </div>
							<Form role="form" onSubmit={this.handleSubmit}>
								<FormGroup className="mb-3">
									<InputGroup className="input-group-alternative">
										<InputGroupAddon addonType="prepend">
											<InputGroupText>
												<i className="ni ni-email-83" />
											</InputGroupText>
										</InputGroupAddon>
										<Translation>
											{(t) => {
												return (
													<Input
														name="email"
														placeholder={t("Email")}
														type="email"
														autoComplete="new-email"
														value={email}
														onChange={this.handleInputChange}
													/>
												);
											}}
										</Translation>
									</InputGroup>
								</FormGroup>
								<FormGroup>
									<InputGroup className="input-group-alternative">
										<InputGroupAddon addonType="prepend">
											<InputGroupText>
												<i className="ni ni-lock-circle-open" />
											</InputGroupText>
										</InputGroupAddon>
										<Translation>
											{(t) => {
												return (
													<Input
														name="password"
														placeholder={t("Password")}
														type="password"
														autoComplete="new-password"
														value={password}
														onChange={this.handleInputChange}
													/>
												);
											}}
										</Translation>
									</InputGroup>
								</FormGroup>
								<div className="custom-control custom-control-alternative custom-checkbox">
									<input
										className="custom-control-input"
										id=" customCheckLogin"
										type="checkbox"
									/>
									<label
										className="custom-control-label"
										htmlFor=" customCheckLogin"
									>
										<span className="text-muted">
											<Trans>Remember me</Trans>
										</span>
									</label>
								</div>
								<div className="text-center">
									<Button className="my-4" color="primary" type="submit">
										{signing_in ? (
											<Trans>Signing In...</Trans>
										) : (
											<Trans>Sign In</Trans>
										)}
									</Button>
								</div>
							</Form>
						</CardBody>
					</Card>
					<Row className="mt-3">
						<Col xs="6">
							{/* <a
                className="text-light"
                href="#pablo"
                onClick={e => e.preventDefault()}
              >
                <small>Forgot password?</small>
              </a> */}
						</Col>
						{/* <Col className="text-right" xs="6">
							<Link className="text-light" to="/auth/register">
								<small>
									<Trans>Create new account</Trans>
								</small>
							</Link>
						</Col> */}
					</Row>
				</Col>
			</>
		);
	}
}

const mapStateToProps = ({ auth }) => {
	const { user, error, signing_in } = auth;
	return {
		user,
		error,
		signing_in,
	};
};

export default connect(mapStateToProps, { signInUser })(Login);
