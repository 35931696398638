import axios from "axios";
import {
	FETCH_CAMPAIGNS_PENDING,
	FETCH_CAMPAIGNS_SUCCESS,
	ADD_CAMPAIGN,
	ADD_CAMPAIGN_PENDING,
	FETCH_DISTINCT_FILTERS,
	FETCH_CAMP_DETAILS,
	DELETE_CAMPAIGNS,
	LOC_CAMPS_CREATED,
	UPDATE_CAMPAIGN,
	UPDATE_CAMP_PENDING,
	RESET_CAMP_UPDATE,
} from "./types";
import { devUrl } from "../utility";
import xlsx from "xlsx";
import { inv_site_headers } from "../helpers";

export const fetchCampaigns =
	({ type }) =>
	(dispatch) => {
		dispatch({ type: FETCH_CAMPAIGNS_PENDING, payload: type });
		axios
			.post(
				`${devUrl}/fetch_campaigns`,
				{ type },
				{ headers: { authorization: localStorage.getItem("plano_token") } }
			)
			.then((response) => {
				// console.log(response);

				let total_budget = 0,
					total_budget_used = 0;

				response.data.camps.forEach((item, index) => {
					const { budget, budget_used } = item;
					total_budget = total_budget + budget;
					total_budget_used = total_budget_used = budget_used;
				});

				console.log("Total Budget (B) ", total_budget / 1000000000);
				console.log("Total Budget Used (B) ", total_budget_used / 1000000000);

				dispatch({
					type: FETCH_CAMPAIGNS_SUCCESS,
					payload: response.data.camps,
				});
			})
			.catch((error) => {});
	};

export const fetchDistinctFilters = (data) => (dispatch) => {
	axios
		.post(`${devUrl}/v2/fetch_distinct_filters`, data, {
			headers: { authorization: localStorage.getItem("plano_token") },
		})
		.then((response) => {
			console.log(response);
			dispatch({ type: FETCH_DISTINCT_FILTERS, payload: response.data.result });
		})
		.catch((error) => {});
};

export const fetchStatesCities =
	({ filters }) =>
	(dispatch) => {
		axios
			.post(
				`${devUrl}/fetch_states_cities`,
				{ filters },
				{ headers: { authorization: localStorage.getItem("plano_token") } }
			)
			.then((response) => {
				console.log(response);
				dispatch({
					type: FETCH_DISTINCT_FILTERS,
					payload: response.data.result,
				});
			})
			.catch((error) => {});
	};

export const addCampaign = (data) => (dispatch) => {
	dispatch({ type: ADD_CAMPAIGN_PENDING });

	axios
		.post(`${devUrl}/create_campaign`, data, {
			headers: { authorization: localStorage.getItem("plano_token") },
		})
		.then((response) => {
			// console.log(response);
			dispatch({ type: ADD_CAMPAIGN, payload: response.data.camp });
		})
		.catch((error) => {
			console.log(error);
		});
};

export const updateCampaign = (data) => (dispatch) => {
	dispatch({ type: UPDATE_CAMP_PENDING });
	axios
		.post(`${devUrl}/update_campaign`, data, {
			headers: { authorization: localStorage.getItem("plano_token") },
		})
		.then((response) => {
			console.log(response);
			dispatch({ type: UPDATE_CAMPAIGN, payload: response.data.camp });
		})
		.catch((error) => {});
};

export const resetCampUpdate = () => (dispatch) => {
	dispatch({ type: RESET_CAMP_UPDATE });
};

export const fetchCampDetails = (data) => (dispatch) => {
	axios
		.post(`${devUrl}/fetch_camp_details`, data, {
			headers: { authorization: localStorage.getItem("plano_token") },
		})
		.then((response) => {
			// console.log(response);
			dispatch({ type: FETCH_CAMP_DETAILS, payload: response.data.camp });
		})
		.catch((error) => {});
};

export const downloadCampPPT = (data) => (dispatch) => {
	const lng = localStorage.getItem("lng");

	axios
		.post(
			`${devUrl}/generatePlanoPPT`,
			{ ...data, lng },
			{
				headers: { authorization: localStorage.getItem("plano_token") },
			}
		)
		.then((response) => {
			console.log(response);
			// dispatch({ type: FETCH_CAMP_DETAILS, payload:response.data.camp });
		})
		.catch((error) => {});
};

export const makeLocCamp = (data) => (dispatch) => {
	axios
		.post(`${devUrl}/make_locaudit_camp`, data, {
			headers: { authorization: localStorage.getItem("plano_token") },
		})
		.then((response) => {
			console.log(response);
			dispatch({ type: LOC_CAMPS_CREATED, payload: data.campId });
		})
		.catch((error) => {});
};

export const exportExcel = (campaignId) => (dispatch) => {
	axios
		.post(
			devUrl + "/getPlanoCampaignExcel",
			{ campaign_id: campaignId },
			{ headers: { authorization: localStorage.getItem("plano_token") } }
		)
		.then(
			(success) => {
				if (success.data.data) {
					console.log("Got Excel Data");

					let sitesData = success.data.data;

					let items = [];

					sitesData.map((item, index) => {
						const { site_map, poi, status } = item;

						const headers_keys = Object.keys(inv_site_headers);

						let obj = {};

						headers_keys.forEach((header_item) => {
							const value = item[header_item];

							if (header_item === "availability") {
								obj[inv_site_headers[header_item]] =
									item.status === "Booked" ? "Booked" : "Available";
							} else if (header_item === "status") {
								obj[inv_site_headers[header_item]] = status ? status : "Pending";
							} else if (header_item === "url") {
								obj[inv_site_headers[header_item]] = site_map;
							} else if (header_item === "poi") {
								if (poi.length > 0) {
									poi.forEach((p, i) => {
										obj["POI " + (i + 1)] = p;
									});
								}
							} else {
								obj[inv_site_headers[header_item]] = value ? value : "-";
							}
						});

						items.push(obj);
						return null;
					});

					let dataWS = xlsx.utils.json_to_sheet(items);

					let wb = xlsx.utils.book_new();
					xlsx.utils.book_append_sheet(wb, dataWS, "plan");

					xlsx.writeFile(
						wb,
						campaignId + new Date().toString().replace(" ", "_") + "_excel.xlsx"
					);
				}
			},
			(error) => {
				if (error) {
					console.log(error);
				}
			}
		);
};

export const deleteCamps = (data) => (dispatch) => {
	axios
		.post(`${devUrl}/delete_campaigns`, data, {
			headers: { authorization: localStorage.getItem("plano_token") },
		})
		.then((response) => {
			console.log(response);
			dispatch({ type: DELETE_CAMPAIGNS, payload: data.campIds });
		})
		.catch((error) => {});
};
