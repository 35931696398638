import React, { useEffect, useState } from "react";
import {
  Modal,
  Card,
  Button,
  CardBody,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  CardHeader,
} from "reactstrap";
import { Trans } from "react-i18next";
import axios from "axios";
import { devUrl } from "../../utility";

const MakeScreenoListModal = (props) => {
  const [loading, setLoading] = useState(true);
  const [digitalInv, setDigitalInv] = useState([]);
  const [screenoCred, setScreenoCred] = useState({ email: "", password: "" });
  const { show, closeScreenoModal, campId, booked_inventory, camp_details } =
    props;
  const [createRes, setCreateRes] = useState("");

  useEffect(() => {
    if (booked_inventory) {
      setDigitalInv(
        booked_inventory.filter(
          (b) => b.additional_data.static_digital === "Digital"
        )
      );
      setLoading(false);
    }
  }, [booked_inventory]);

  const create = async () => {
    console.log(
      "screeno",
      { sites: digitalInv, screenoCred, camp_details, campId },
      localStorage.getItem("plano_token")
    );
    let res = await axios.post(
      `${devUrl}/createScreenoList`,
      { sites: digitalInv, screenoCred, camp_details, campId },
      { headers: { authorization: localStorage.getItem("plano_token") } }
    );
    setCreateRes(res.data.msg);
    setTimeout(() => setCreateRes(""), 6000);
  };

  console.log("digitalInv", digitalInv, digitalInv.length);
  console.log("screenoCred", screenoCred);
  return (
    <Modal
      className="modal-dialog-centered"
      size="sm"
      isOpen={show}
      toggle={() => closeScreenoModal()}
    >
      <div className="modal-body p-0">
        {loading && <span>Loading...</span>}
        {!loading && (
          <Card className="bg-secondary shadow border-0">
            <CardHeader className="bg-transparent pb-2">
              <div className="text-muted text-center mt-2 mb-1">
                <h3>
                  Make Screeno List of <strong>( {digitalInv.length} )</strong>{" "}
                  Digital Sites
                </h3>
              </div>
            </CardHeader>
            <CardBody className="px-lg-3 py-lg-3">
              <h5>Please enter screeno credentials</h5>
              <Form role="form">
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Email"
                      type="email"
                      onChange={(e) =>
                        setScreenoCred({
                          ...screenoCred,
                          email: e.target.value,
                        })
                      }
                      value={screenoCred.email}
                    />
                  </InputGroup>
                  <br />
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Email"
                      type="password"
                      onChange={(e) =>
                        setScreenoCred({
                          ...screenoCred,
                          password: e.target.value,
                        })
                      }
                      value={screenoCred.password}
                    />
                  </InputGroup>
                </FormGroup>
                <div className="text-center">
                  <Button
                    className="my-4"
                    color="primary"
                    type="button"
                    onClick={create}
                  >
                    <Trans>Create List</Trans>
                  </Button>
                  <br />
                  {createRes && (
                    <strong style={{ color: "green" }}>{createRes}</strong>
                  )}
                </div>
              </Form>
            </CardBody>
          </Card>
        )}
      </div>
    </Modal>
  );
};

export default MakeScreenoListModal;
