import axios from "axios";
import {
	FETCH_SUGG_INVENTORY,
	FETCH_NETWORKS,
	RESET_INVENTORY,
	FETCH_NEARBY_PLACES,
	FETCH_BOOKED_INVENTORY,
	FETCH_CHAT_USERS,
	FETCH_APPROVAL_INVENTORY,
	FETCH_BOOKED_INVENTORY_PENDING,
	FETCH_BOOKED_INVENTORY_SUCCESS,
	FETCH_BOOKED_INVENTORY_ERROR,
	FETCH_CAMP_DETAILS,
	REMOVE_BOOKED_SITES,
	FETCH_MEDIA_OWNER_INVENTORY,
	ADD_INVENTORY,
	FETCH_ALL_MEDIA_OWNERS,
	UPLOAD_SITEMAPS_PROGRESS,
	SET_SITEMAPS_URLS,
	RESET_SITEMAPS_URLS,
	DELETE_PLANO_INVENTORY,
	FETCH_DISTINCT_CITY_STATES,
	ADD_PROPER_MEDIA_OWNER,
	ADD_PROPER_MEDIA_OWNER_ERR,
	DISABLE_PLANO_INVENTORY,
} from "./types";
import { devUrl } from "../utility";
import { getKeyForUpload } from "../helpers/helpers";
import _ from "lodash";

export const fetchNetworks =
	({ campId }) =>
	(dispatch) => {
		axios
			.post(`${devUrl}/fetch_networks`, { campId }, { headers: { authorization: localStorage.getItem("plano_token") } })
			.then((response) => {
				// console.log(response);
				dispatch({ type: FETCH_NETWORKS, payload: response.data });
			})
			.catch((error) => {});
	};

export const fetchSuggInventory = (data) => (dispatch) => {
	dispatch({ type: RESET_INVENTORY });
	return axios
		.post(`${devUrl}/fetch_sugg_inventory`, data, {
			headers: { authorization: localStorage.getItem("plano_token") },
		})
		.then((response) => {
			console.log(response);
			dispatch({ type: FETCH_SUGG_INVENTORY, payload: response.data });
			return response.data;
		})
		.catch((error) => {});
};

export const resetSuggInventory = () => (dispatch) => {
	dispatch({ type: RESET_INVENTORY });
};

export const fetchNearby = (data) => (dispatch) => {
	axios
		.post(`${devUrl}/fetch_nearby_places`, data, {
			headers: { authorization: localStorage.getItem("plano_token") },
		})
		.then((response) => {
			// console.log(response);
			dispatch({ type: FETCH_NEARBY_PLACES, payload: response.data.nearby });
		})
		.catch((error) => {});
};

export const bookInventory = (data) => (dispatch) => {
	dispatch({ type: FETCH_BOOKED_INVENTORY_PENDING });
	axios
		.post(`${devUrl}/v2/book_inventory`, data, {
			headers: { authorization: localStorage.getItem("plano_token") },
		})
		.then((response) => {
			console.log(response);
			dispatch({
				type: FETCH_BOOKED_INVENTORY_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			console.log("err", error.response.data);
			dispatch({
				type: FETCH_BOOKED_INVENTORY_ERROR,
				payload: error.response.data,
			});
		});
};

export const fetchBookedInventory = (data) => (dispatch) => {
	axios
		.post(`${devUrl}/fetch_booked_inventory`, data, {
			headers: { authorization: localStorage.getItem("plano_token") },
		})
		.then((response) => {
			// console.log(response);
			dispatch({
				type: FETCH_BOOKED_INVENTORY,
				payload: response.data.booked_inventory,
			});
		})
		.catch((error) => {});
};

export const sendInvApproval = (data) => (dispatch) => {
	axios
		.post(`${devUrl}/send_inv_approval`, data, {
			headers: { authorization: localStorage.getItem("plano_token") },
		})
		.then((response) => {
			console.log(response);
			dispatch({
				type: FETCH_BOOKED_INVENTORY,
				payload: response.data.booked_inventory,
			});
		})
		.catch((error) => {});
};

export const internalApproval = (data) => (dispatch) => {
	axios
		.post(`${devUrl}/internal_approval`, data, {
			headers: { authorization: localStorage.getItem("plano_token") },
		})
		.then((response) => {
			console.log(response);
			dispatch({
				type: FETCH_BOOKED_INVENTORY,
				payload: response.data.booked_inventory,
			});
		})
		.catch((error) => {});
};

export const fetchChatUsers = (data) => (dispatch) => {
	axios
		.post(`${devUrl}/fetch_chat_users`, data, {
			headers: { authorization: localStorage.getItem("plano_token") },
		})
		.then((response) => {
			// console.log(response);
			dispatch({ type: FETCH_CHAT_USERS, payload: response.data.users });
		})
		.catch((error) => {});
};

export const fetchOpenChatUsers = (data) => (dispatch) => {
	axios
		.post(`${devUrl}/fetch_open_chat_users`, data, {
			headers: { authorization: localStorage.getItem("plano_token") },
		})
		.then((response) => {
			console.log(response);
			dispatch({ type: FETCH_CHAT_USERS, payload: response.data.users });
		})
		.catch((error) => {});
};

export const fetchApprovalInventory = (data) => (dispatch) => {
	axios
		.post(`${devUrl}/fetch_approval_inventory`, data, {
			headers: { authorization: localStorage.getItem("plano_token") },
		})
		.then((response) => {
			console.log(response);
			dispatch({ type: FETCH_APPROVAL_INVENTORY, payload: response.data });
		})
		.catch((error) => {});
};

export const approveInventory = (data) => (dispatch) => {
	axios
		.post(`${devUrl}/approve_inventory`, data, {
			headers: { authorization: localStorage.getItem("plano_token") },
		})
		.then((response) => {
			console.log(response);
			dispatch({ type: FETCH_APPROVAL_INVENTORY, payload: response.data });
		})
		.catch((error) => {});
};

export const addInventory = (data) => (dispatch) => {
	axios
		.post(`${devUrl}/add_plano_inventory`, data, {
			headers: { authorization: localStorage.getItem("plano_token") },
		})
		.then((response) => {
			console.log(response);
			dispatch({ type: ADD_INVENTORY, payload: response.data });
		})
		.catch((error) => {});
};

export const updateMediaOwnerInventory = (data) => (dispatch) => {
	axios
		.post(`${devUrl}/update_plano_inventory`, data, {
			headers: { authorization: localStorage.getItem("plano_token") },
		})
		.then((response) => {
			console.log(response);
			//  dispatch({ type: UPDATE_INVENTORY, payload:response.data });
		})
		.catch((error) => {});
};

export const updateSiteMapsUploadProgress = (data) => (dispatch) => {
	var total_images_uploaded = [];
	const { files, bucket, type, additional_data } = data;

	_.map(files, function (file) {
		var key = getKeyForUpload(type, file, additional_data);
		axios
			.post(
				`${devUrl}/generateSignedUrlForUpload`,
				{
					file_name: file.name,
					file_type: file.type,
					bucket: bucket,
					key: key,
				},
				{ headers: { authorization: localStorage.getItem("plano_token") } }
			)
			.then((res) => {
				console.log("response", res.data);
				if (res.data.error) {
					// console.log('error',error)
				} else {
					const xhr = new XMLHttpRequest();
					xhr.open("PUT", res.data.data);

					xhr.upload.onprogress = (e) => {
						const progress = Math.round((e.loaded / e.total) * 100);
						console.log("file", file.name, " progres", progress);
					};

					xhr.onreadystatechange = () => {
						if (xhr.readyState === 4) {
							if (xhr.status === 200) {
								console.log("File uploaded success");
								var site_code_arr = file.name.split(".");
								var site_code = site_code_arr[0];
								total_images_uploaded.push({
									site_code: site_code,
									url: res.data.url,
									type: file.type,
									name: file.name,
								});
								const progress = ((total_images_uploaded.length / files.length) * 100).toFixed(2);
								dispatch({ type: UPLOAD_SITEMAPS_PROGRESS, payload: progress });

								if (total_images_uploaded.length === files.length) {
									dispatch({
										type: SET_SITEMAPS_URLS,
										payload: total_images_uploaded,
									});
								}
							}
						}
					};
					xhr.send(file);
				}
			});
	});
};

export const updateInventorySitemaps = (data) => (dispatch) => {
	axios
		.post(`${devUrl}/update_inventory_sitemaps`, data, {
			headers: { authorization: localStorage.getItem("plano_token") },
		})
		.then((response) => {
			console.log(response);
			dispatch({ type: RESET_SITEMAPS_URLS });
		})
		.catch((error) => {});
};

export const fetchMediaOwnerInventory = (data) => (dispatch) => {
	// console.log(data);
	axios
		.post(`${devUrl}/fetch_media_owner_inventory?limit=${data.limit}&page=${data.page}&siteCode=${data.search}`, data, {
			headers: { authorization: localStorage.getItem("plano_token") },
		})
		.then((response) => {
			// console.log("fetch_media_owner_inventory", response);
			dispatch({ type: FETCH_MEDIA_OWNER_INVENTORY, payload: response.data });
		})
		.catch((error) => {});
};

export const fetchAllMediaOwners = (data) => (dispatch) => {
	axios
		.post(`${devUrl}/fetch_all_media_owners`, data, {
			headers: { authorization: localStorage.getItem("plano_token") },
		})
		.then((response) => {
			console.log(response);
			dispatch({
				type: FETCH_ALL_MEDIA_OWNERS,
				payload: response.data.proper_vendors,
			});
		})
		.catch((error) => {});
};

export const deletePlanoInventory = (data) => (dispatch) => {
	axios
		.post(`${devUrl}/delete_plano_inventory`, data, {
			headers: { authorization: localStorage.getItem("plano_token") },
		})
		.then((response) => {
			console.log(response);
			dispatch({ type: DELETE_PLANO_INVENTORY, payload: data.inventory });
		})
		.catch((error) => {});
};

export const disablePlanoInventory = (data) => (dispatch) => {
	axios
		.post(`${devUrl}/disable_plano_inventory`, data, {
			headers: { authorization: localStorage.getItem("plano_token") },
		})
		.then((response) => {
			console.log(response);
			dispatch({ type: DISABLE_PLANO_INVENTORY, payload: data });
		})
		.catch((error) => {});
};

export const fetchDistinctCityStates = (data) => (dispatch) => {
	axios
		.post(`${devUrl}/fetch_distinct_city_states`, data, {
			headers: { authorization: localStorage.getItem("plano_token") },
		})
		.then((response) => {
			console.log(response);
			dispatch({
				type: FETCH_DISTINCT_CITY_STATES,
				payload: response.data.result,
			});
		})
		.catch((error) => {});
};

export const addProperMediaOwner = (data) => (dispatch) => {
	axios
		.post(`${devUrl}/add_proper_media_owner`, data, {
			headers: { authorization: localStorage.getItem("plano_token") },
		})
		.then((response) => {
			console.log(response);
			dispatch({
				type: ADD_PROPER_MEDIA_OWNER,
				payload: response.data.added_media_owner,
			});
		})
		.catch((error) => {
			console.log("error", error.response.data);
			dispatch({
				type: ADD_PROPER_MEDIA_OWNER_ERR,
				payload: error.response.data,
			});
		});
};

export const syncMediaOwnerInventory = (data) => (dispatch) => {
	axios
		.post(`${devUrl}/sync_media_owner_inventory`, data, {
			headers: { authorization: localStorage.getItem("plano_token") },
		})
		.then((response) => {
			console.log(response);
			//    dispatch({ type: ADD_PROPER_MEDIA_OWNER, payload:response.data.added_media_owner });
		})
		.catch((error) => {});
};

export const removeBookedSites = (data) => (dispatch) => {
	axios
		.post(`${devUrl}/remove_booked_sites`, data, {
			headers: { authorization: localStorage.getItem("plano_token") },
		})
		.then((response) => {
			console.log("removedbooked", response);
			dispatch({ type: REMOVE_BOOKED_SITES, payload: data.invIds });
			dispatch({ type: FETCH_CAMP_DETAILS, payload: response.data.camp });
		})
		.catch((error) => {});
};
