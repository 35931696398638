import React, { useState } from "react";
import FusionCharts from "fusioncharts";
import Charts from "fusioncharts/fusioncharts.charts";
import ReactFC from "react-fusioncharts";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import { numberWithCommas } from "../../helpers/helpers";
import { Trans } from "react-i18next";
import Nav from "reactstrap/lib/Nav";
import NavItem from "reactstrap/lib/NavItem";
import NavLink from "reactstrap/lib/NavLink";
import TabContent from "reactstrap/lib/TabContent";
import TabPane from "reactstrap/lib/TabPane";
import {Modal, CardBody, Table } from "reactstrap";
import _ from "lodash";
import Moment from "react-moment";
import moment from "moment";
import i18n from "../../i18n";


ReactFC.fcRoot(FusionCharts, Charts, FusionTheme);

const MapPanel = (props) => {
	console.log("MapPanel props", props)
	const {
		id,
		site_map,
		site_code,
		city,
		media_owner,
		locality,
		inventory_cost,
		media_type,
		final_score,
		p_0_5_600mt,
		male_600mt,
		female_600mt,
		zone,
		booked_camps
	} = props.panelDetails;

	// console.table(props.panelDetails);

	const { setSelectedInventory, selectedInv } = props;
	const [modalStatus, setModalStatus] = useState(false);

	const toggleModal = () => {
		setModalStatus(!modalStatus)
	};

	const getSegmentationConfigs = (panelDetails, currentTab) => {
		const segmentations_types_600mt = [
			{ p_0_5_600mt: "(Upto 5)" },
			{ p_6_14_600mt: "(6 to 14)" },
			{ p_15_29_600mt: "(15 to 29)" },
			{ p_30_44_600mt: "(30 to 44)" },
			{ p_45_64_600mt: "(45 to 64)" },
			{ p_65_600mt: "(65+)" },
		];

		const segmentations_types_1000mt = [
			{ p_0_5_1000mt: "(Upto 5)" },
			{ p_6_14_1000mt: "(6 to 14)" },
			{ p_15_29_1000mt: "(15 to 29)" },
			{ p_30_44_1000mt: "(30 to 44)" },
			{ p_45_64_1000mt: "(45 to 64)" },
			{ p_65_1000mt: "(65+)" },
		];

		let data;

		if (currentTab === 1) {
			data = segmentations_types_600mt.map((a) => {
				const key = Object.keys(a)[0];
				return { label: a[key], value: panelDetails[key] };
			});
		}

		if (currentTab === 2) {
			data = segmentations_types_1000mt.map((a) => {
				const key = Object.keys(a)[0];
				return { label: a[key], value: panelDetails[key] };
			});
		}

		return {
			type: "doughnut2d",
			width: "100%",
			height: "400",
			dataFormat: "json",
			dataSource: {
				chart: {
					caption: i18n.t("Population Segmentation (Age in Years)"),
					theme: "fusion",
				},
				data: data,
			},
		};
	};

	const [currentTab, setCurrentTab] = useState(1);

	const handleTabChange = (tab) => {
		setCurrentTab(tab);
	};

	const male_percentage = Math.round(
		(male_600mt / (male_600mt + female_600mt)) * 100
	);

	const female_percentage = Math.round(
		(female_600mt / (male_600mt + female_600mt)) * 100
	);

	return (
		<div
			style={{
				position: "absolute",
				top: "0px",
				right: "0",
				// height:"100%",
				width: "400px",
				display: "block",
				background: "white",
				boxShadow:
					"0 1px 2px rgba(0,0,0,0.07), 0 2px 4px rgba(0,0,0,0.07), 0 4px 8px rgba(0,0,0,0.07), 0 8px 16px rgba(0,0,0,0.07), 0 16px 32px rgba(0,0,0,0.07), 0 32px 64px rgba(0,0,0,0.07)",
			}}
		>
			<div
				style={{
					background: "#333",
					height: "50px",
					width: "100%",
					display: "flex",
					justifyContent: "space-between",
				}}
			>
				<div
					style={{
						height: "100%",
						alignItems: "center",
						paddingLeft: "5px",
						display: "flex",
					}}
				>
					{selectedInv.find((f) => f._id === id) ? (
						<button
							style={{ height: "40px" }}
							className="btn btn-danger"
							onClick={() => setSelectedInventory({ _id: id }, "map")}
						>
							<Trans>Remove from Plan</Trans>
						</button>
					) : (
						<button
							style={{ height: "40px" }}
							className="btn"
							onClick={() => setSelectedInventory({ _id: id }, "map")}
						>
							<Trans>Add to Plan</Trans>
						</button>
					)}
					{booked_camps && booked_camps.length > 0 && 
					  <button
					  style={{ height: "40px" }}
					  className="btn btn-danger"
					  onClick={() => toggleModal()}
						>
							<Trans>Status</Trans>
						</button>
					}
				</div>
				<div
					onClick={props.onCloseClick}
					style={{
						padding: "5px",
						cursor: "pointer",
						height: "100%",
						color: "white",
						display: "flex",
						width: "50px",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<span className="fas fa-times"></span>
				</div>
			</div>
			<div className="inv_photo">
				<div>
					<img
						style={{ cursor: "pointer", margin: 5, width: "100%" }}
						alt={site_map}
						src={site_map}
					/>
				</div>

				{/* // ------- Inv basic --------// */}
				<div style={{ borderBottom: "1px solid", paddingBottom: "10px" }}>
					{/* <h4 style={{textAlign:"center",marginTop:"5px"}}>{site_code}</h4>   */}
					<div className="container-fluid" style={{ marginTop: "5px" }}>
						<div className="row">
							<div className="col">
								<h4 style={{ marginBottom: 0, marginTop: "5px" }}>
									<Trans>Site Code</Trans>:{" "}
								</h4>
								<span style={{ fontWeight: "300", fontSize: "0.9rem" }}>
									{site_code}
								</span>
							</div>
							<div className="col">
								<h4 style={{ marginBottom: 0, marginTop: "5px" }}>
									<Trans>Media Owner</Trans>:{" "}
								</h4>
								<span style={{ fontWeight: "300", fontSize: "0.9rem" }}>
									{media_owner}
								</span>
							</div>
						</div>
						<div className="row">
							<div className="col">
								<h4 style={{ marginBottom: 0, marginTop: "5px" }}>
									<Trans>Location</Trans>:{" "}
								</h4>
								<span style={{ fontWeight: "300", fontSize: "0.9rem" }}>
									{locality}
								</span>
							</div>
							<div className="col">
								<h4 style={{ marginBottom: 0, marginTop: "5px" }}>
									<Trans>City</Trans>:{" "} </h4>
								<span style={{ fontWeight: "300", fontSize: "0.9rem" }}>
									{city}
								</span>
							</div>
						</div>
						<div className="row">
							<div className="col">
								<h4 style={{ marginBottom: 0, marginTop: "5px" }}>
									<Trans>Price</Trans>:{" "}
								 </h4>
								<span style={{ fontWeight: "300", fontSize: "0.9rem" }}>
									{numberWithCommas(inventory_cost)}
								</span>
							</div>
							<div className="col">
								<h4 style={{ marginBottom: 0, marginTop: "5px" }}>
									<Trans>Media Type</Trans>:{" "}
								</h4>
								<span style={{ fontWeight: "300", fontSize: "0.9rem" }}>
									{media_type}
								</span>
							</div>
						</div>
						<div className="row">
							{!isNaN(male_percentage) && (
								<div className="col">
									<h4 style={{ marginBottom: 0, marginTop: "5px" }}>
										<Trans>Male</Trans>:{" "}
									</h4>
									<span style={{ fontWeight: "300", fontSize: "0.9rem" }}>
										{male_percentage}%
									</span>
								</div>
							)}
							{!isNaN(female_percentage) && (
								<div className="col">
									<h4 style={{ marginBottom: 0, marginTop: "5px" }}>
									<Trans>Female</Trans>:{" "}
									</h4>
									<span style={{ fontWeight: "300", fontSize: "0.9rem" }}>
										{female_percentage}%
									</span>
								</div>
							)}
						</div>
						<div className="row">
							{zone && (
								<div className="col">
									<h4 style={{ marginBottom: 0, marginTop: "5px" }}>
										<Trans>Zone</Trans>:{" "}
									</h4>
									<span style={{ fontWeight: "300", fontSize: "0.9rem" }}>
										{zone}
									</span>
								</div>
							)}
							<div className="col">
								<h4 style={{ marginBottom: 0, marginTop: "5px" }}>
									<Trans>Final Score</Trans>:{" "}
								</h4>
								<span style={{ fontWeight: "300", fontSize: "0.9rem" }}>
									{Math.round(final_score * 100) + "%"}
								</span>
							</div>
						</div>
					</div>
				</div>

				{/* // ------- Inv ratings --------// */}
				{p_0_5_600mt > 0 && (
					<div style={{ borderBottom: "1px solid", paddingBottom: "5px" }}>
						<Nav tabs>
							<NavItem>
								<NavLink
									className={currentTab === 1 ? "active" : ""}
									onClick={handleTabChange.bind(this, 1)}
								>
									600 meter
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink
									className={currentTab === 2 ? "active" : ""}
									onClick={handleTabChange.bind(this, 2)}
								>
									1000 meter
								</NavLink>
							</NavItem>
						</Nav>
						<TabContent activeTab={currentTab}>
							<TabPane tabId={1}>
								<ReactFC
									key={_.uniqueId()}
									{...getSegmentationConfigs(props.panelDetails, 1)}
								/>
							</TabPane>
							<TabPane tabId={2}>
								<ReactFC
									key={_.uniqueId()}
									{...getSegmentationConfigs(props.panelDetails, 2)}
								/>
							</TabPane>
						</TabContent>
					</div>
				)}
			</div>
			<Modal
							className="modal-dialog-centered"
							isOpen={modalStatus}
							toggle={() => toggleModal()}
							size="lg"
						>
							<div
								className="modal-header text-center"
								style={{ background: "#fefefe" }}
							>
								<h3 className="modal-title" id="exampleModalLabel">
									<Trans>Site Code</Trans>:&nbsp;{site_code},&nbsp;<Trans>Booked Campaign Details</Trans>
								</h3>
							</div>
							<div className="modal-body">
								<CardBody className="px-lg-2 py-lg-2" style={{maxWidth: '752px', overflowX: 'scroll'}}>
									<Table>
											<thead>
											  <tr>
												
												<th><Trans>Campaign Name</Trans></th>
												<th><Trans>Start Date</Trans></th>
												<th><Trans>End Date</Trans></th>
												<th><Trans>States</Trans></th>
												<th><Trans>Cities</Trans></th>
												<th><Trans>Media Types</Trans></th>
												{/* <th><Trans>Budget</Trans></th>
												<th><Trans>Budget Used</Trans></th> */}
											  </tr>
											</thead>
											<tbody>
											{booked_camps && booked_camps.length>0 && booked_camps.map(b => {
										return (
											<>
											  <tr>
												<td>{b.name}</td>
												<td><Moment format="MMMM Do YYYY">{b.start_date}</Moment></td>
												<td><Moment format="MMMM Do YYYY">{b.end_date}</Moment></td>
												<td>{b.states ? b.states.toString(): null}</td>
												<td>{b.cities ? b.cities.toString(): null}</td>
												<td>{b.media_types ? b.media_types.toString(): null}</td>
												{/* <td>{b.budget}</td>
												<td>{b.budget_used}</td> */}
											  </tr>
											  
										 </>
											  )
											  })}
											</tbody>
										  </Table>
									
								</CardBody>
							</div>
							<div className="modal-footer">
								
								{/* <Button
									block
									color="primary"
									type="button"
									onClick={this.isValid}
								>
									{adding_camp ? "Creating..." : "Create New"}
								</Button> */}
							</div>
						</Modal>
		</div>
		
	);
};

export default MapPanel;
