import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { signOut } from "../../actions/authActions";
// reactstrap components
import {
	DropdownMenu,
	DropdownItem,
	UncontrolledDropdown,
	DropdownToggle,
	Form,
	FormGroup,
	InputGroupAddon,
	InputGroupText,
	Input,
	InputGroup,
	Navbar,
	Nav,
	Container,
	Media,
} from "reactstrap";
import { Trans, Translation } from "react-i18next";

class AdminNavbar extends React.Component {
	logOut = () => {
		const { signOut } = this.props;
		signOut();
	};

	render() {
		const { user } = this.props;
		const { name } = user;

		return (
			<>
				<Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
					<Container fluid>
						<Link
							className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
							to="/"
						>
							{this.props.brandText}
						</Link>
						<Form className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
							<FormGroup className="mb-0">
								<InputGroup className="input-group-alternative">
									<InputGroupAddon addonType="prepend">
										<InputGroupText>
											<i className="fas fa-search" />
										</InputGroupText>
									</InputGroupAddon>
									<Translation>
										{(t) => {
											return <Input placeholder={t("Search")} type="text" />;
										}}
									</Translation>
								</InputGroup>
							</FormGroup>
						</Form>
						<Nav className="align-items-center d-none d-md-flex" navbar>
							<UncontrolledDropdown nav>
								<DropdownToggle className="pr-0" nav>
									<Media className="align-items-center">
										<span className="avatar avatar-sm rounded-circle">
											<img
												alt="..."
												src={require("assets/img/theme/user-placeholder.jpg")}
											/>
										</span>
										<Media className="ml-2 d-none d-lg-block">
											<span className="mb-0 text-sm font-weight-bold">
												{name}
											</span>
										</Media>
									</Media>
								</DropdownToggle>
								<DropdownMenu className="dropdown-menu-arrow" right>
									<DropdownItem className="noti-title" header tag="div">
										<h6 className="text-overflow m-0">
											<Trans>Welcome</Trans>
										</h6>
									</DropdownItem>
									<DropdownItem to="/admin/user-profile" tag={Link}>
										<i className="ni ni-settings-gear-65" />
										<span>
											<Trans>Settings</Trans>
										</span>
									</DropdownItem>
									<DropdownItem divider />
									<DropdownItem href="#logout" onClick={this.logOut}>
										<i className="ni ni-user-run" />
										<span>
											<Trans>Logout</Trans>
										</span>
									</DropdownItem>
								</DropdownMenu>
							</UncontrolledDropdown>
						</Nav>
					</Container>
				</Navbar>
			</>
		);
	}
}

const mapStateToProps = ({ auth }) => {
	const { user } = auth;
	return {
		user,
	};
};

export default connect(mapStateToProps, { signOut })(AdminNavbar);
