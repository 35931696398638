import { AUTH_USER, UNAUTH_USER, AUTH_ERROR, SIGNING_IN,
         UPDATE_USER_DETAILS, FETCH_ALL_POLICIES, ADD_USER_ROLE,
         FETCH_USER_ROLES, FETCH_MEMBERS, ADD_MEMBER,
         ADD_POLICY, DELETE_MEMBER } from '../actions/types';

const initialState = { 
    authenticated: !!localStorage.getItem("user"),
    user: JSON.parse(localStorage.getItem("user")) || {},
    signing_in: false,
    error: null,
    roles:[],
    members:[],
    policies:[]
};

export default (state = initialState, action)=>{
    switch(action.type){
        case AUTH_USER:
            return { ...state, error:'', authenticated: true, user:action.payload };
         case UNAUTH_USER:
            return { ...state, authenticated: false };
        case AUTH_ERROR:
            return { ...state, error: action.payload };
        case SIGNING_IN:
            return { ...state, signing_in: action.payload };
        case UPDATE_USER_DETAILS:
            return { ...state, user: action.payload }
        case FETCH_ALL_POLICIES:
            return { ...state, all_policies: action.payload }
        case ADD_USER_ROLE:
            return { ...state, roles: [...state.roles, action.payload] }
        case FETCH_USER_ROLES:
            return { ...state, roles: action.payload }
        case FETCH_MEMBERS:
            return { ...state, members: action.payload }
        case ADD_MEMBER:
            return { ...state, members: [...state.members, action.payload]}
        case ADD_POLICY:
            return { ...state, policies: [...state.policies, action.payload]}
        case DELETE_MEMBER:
            return { ...state, members: state.members.filter((f) => f._id !== action.payload._id)}
        default : {
            return state;
        }
    }
}