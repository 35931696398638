import Campaigns from "./views/campaigns/ShowCampaigns.js";
import Profile from "./views/settings/Profile.js";
import Register from "./views/auth/Register.js";
import Login from "./views/auth/Login.js";
import Tables from "./views/examples/Tables.js";
import SuggInventory from "./views/sugg_inventory/SuggInventory";
import BookInventory from "./views/book_inventory/BookInventory";
import ApproveInventory from "./views/approve_inventory/ApproveInventory";
import CovidMap from "./views/covid/map";
import Inventory from "./views/inventory_management/Inventory";
import VendorManagement from "./views/vendor_management/VendorManagement";
import VendorInventory from "./views/vendor_management/VendorInventory";
import AdminPanel from "./views/admin_panel";
import EditCampaign from "./views/campaigns/EditCampaign";
import React from "react";
import { Trans } from "react-i18next";

var routes = [
	{
		path: "/index",
		name: <Trans>Dashboard</Trans>,
		icon: "ni ni-tv-2 text-primary",
		component: Campaigns,
		layout: "/admin",
		show_sidebar: false,
	},
	{
		path: "/campaigns",
		name: <Trans>Campaigns</Trans>,
		icon: "ni ni-bag-17 text-success",
		component: Campaigns,
		layout: "/admin",
		show_sidebar: true,
	},
	{
		path: "/edit_campaign/:campId",
		name: <Trans>Edit Campaign</Trans>,
		icon: "ni ni-bag-17 text-success",
		component: EditCampaign,
		layout: "/admin",
		show_sidebar: false,
	},
	{
		path: "/sugg_inventory/:campId",
		name: <Trans>Suggested Inventory</Trans>,
		icon: "ni ni-atom text-red",
		component: SuggInventory,
		layout: "/admin",
		show_sidebar: false,
	},
	{
		path: "/booked_inventory/:campId",
		name: <Trans>Book Inventory</Trans>,
		icon: "ni ni-atom text-red",
		component: BookInventory,
		layout: "/admin",
		show_sidebar: false,
	},
	{
		path: "/tables",
		name: <Trans>Tables</Trans>,
		icon: "ni ni-atom text-red",
		component: Tables,
		layout: "/admin",
		show_sidebar: false,
	},
	{
		path: "/login",
		name: <Trans>Login</Trans>,
		icon: "ni ni-atom text-red",
		component: Login,
		layout: "/auth",
		show_sidebar: false,
	},
	{
		path: "/register",
		name: <Trans>Register</Trans>,
		icon: "ni ni-atom text-red",
		component: Register,
		layout: "/auth",
		show_sidebar: false,
	},
	{
		path: "/approve_inventory/:userId/:campId",
		name: "",
		icon: "ni ni-atom text-red",
		component: ApproveInventory,
		layout: "/open",
		show_sidebar: false,
	},
	{
		path: "/vendor_inventory/:userId/:userName",
		name: "",
		icon: "ni ni-atom text-red",
		component: VendorInventory,
		layout: "/admin",
		show_sidebar: false,
	},
	{
		path: "/covid_map",
		name: <Trans>Covid Map</Trans>,
		icon: "ni ni-atom text-red",
		component: CovidMap,
		layout: "/admin",
		show_sidebar: false,
	},
	{
		path: "/user-profile",
		name: <Trans>Profile</Trans>,
		icon: "ni ni-atom text-red",
		component: Profile,
		layout: "/admin",
		show_sidebar: false,
	},
	{
		path: "/inventory_management",
		name: <Trans>Inventory</Trans>,
		icon: "fas fa-clipboard-list",
		component: Inventory,
		layout: "/admin",
		show_sidebar: false,
		required_policies: ["inventory management"],
	},
	{
		path: "/master_vendor_management",
		name: <Trans>Vendor Management</Trans>,
		icon: "fas fa-clipboard-list",
		component: VendorManagement,
		layout: "/admin",
		show_sidebar: false,
		required_policies: ["master vendor management"],
	},
	{
		path: "/admin_panel",
		name: <Trans>Admin Panel</Trans>,
		icon: "fas fa-clipboard-list",
		component: AdminPanel,
		layout: "/admin",
		show_sidebar: false,
		required_policies: ["admin_panel"],
	},
];
export default routes;
