import React from "react";
import { connect } from "react-redux";
// reactstrap components
import {
	Button,
	Card,
	CardHeader,
	CardBody,
	FormGroup,
	Form,
	Input,
	Container,
	Row,
	Col,
} from "reactstrap";
// core components
import UserHeader from "components/Headers/UserHeader.js";
import AddRoleModal from "./AddRoleModal";
import EditRoleModal from "./EditRoleModal";
import AddMemberModal from "./AddMemberModal";
import EditMemberModal from "./EditMemberModal";
import DeleteMemberModal from "./DeleteMemberModal";
import PasswordChangeModal from "./PasswordChangeModal";
import BSTable from "../tables/ReactBootstrap";
import {
	updateUserDetails,
	fetchAllPolicies,
	addUserRole,
	fetchRoles,
	updateRole,
	addMember,
	fetchMembers,
	updateMember,
	deleteMember,
	changePassword,
} from "../../actions/userActions";
import HasPolicies from "../../helpers/hasPolicies";
import { bindActionCreators } from "redux";
import { validateForm } from "../../helpers/validations";
import _ from "lodash";
import Select from "react-select";
import { Trans } from "react-i18next";

const expandRow = {
	renderer: (row) => (
		<div>
			<h4>
				<Trans>Attached Policies</Trans>
			</h4>
			<Row>
				<Col>
					<strong>
						<Trans>Name</Trans>
					</strong>
				</Col>
				<Col>
					<strong>
						<Trans>Description</Trans>
					</strong>
				</Col>
			</Row>
			{row.policies.map((p) => {
				return (
					<Row>
						<Col>{p.name}</Col>
						<Col>{p.description}</Col>
					</Row>
				);
			})}
		</div>
	),
};

class Profile extends React.Component {
	state = {
		profile_editable: false,
		pName: null,
		pEmail: null,
		pCompany: null,
		pPhone: null,
		errors: {},
		show_add_role_modal: false,
		show_edit_role_modal: false,
		edit_modal_data: null,
		show_add_member_modal: false,
		show_edit_member_modal: false,
		show_delete_member_modal: false,
		show_change_pass_modal: false,
		edit_member_modal_data: null,
		change_pass_modal_data: null,
		add_role_errors: {},
		edit_role_errors: {},
		add_member_errors: {},
		edit_member_errors: {},
	};

	constructor(props) {
		super(props);

		this.roleColumns = [
			{
				dataField: "name",
				text: <Trans>Role</Trans>,
			},
			{
				dataField: "policies",
				text: <Trans>See Policies</Trans>,
				formatter: this.policyFormatter,
			},
		];

		if (HasPolicies(["role_management_policy"])) {
			this.roleColumns = this.roleColumns.concat({
				dataField: "_id",
				text: <Trans>{"Edit"}</Trans>,
				formatter: this.editFormatter,
			});
		}

		this.memberColumns = [
			{
				dataField: "name",
				text: <Trans>Name</Trans>,
			},
			{
				dataField: "email",
				text: <Trans>Email</Trans>,
			},
			{
				dataField: "company",
				text: <Trans>Company</Trans>,
			},
			{
				dataField: "phone",
				text: <Trans>Phone</Trans>,
			},
			{
				dataField: "role",
				text: <Trans>Role</Trans>,
				formatter: this.roleFormatter,
			},
		];

		if (
			HasPolicies(["member_management_policy"]) ||
			!HasPolicies(["read only access"])
		) {
			this.memberColumns = this.memberColumns.concat({
				dataField: "_id1",
				text: <Trans>Edit</Trans>,
				formatter: this.meditFormatter,
				isDummy: true,
			});

			this.memberColumns = this.memberColumns.concat({
				dataField: "_id2",
				text: <Trans>Change Password</Trans>,
				formatter: this.passFormatter,
				isDummy: true,
			});

			this.memberColumns = this.memberColumns.concat({
				dataField: "_id",
				text: <Trans>Delete</Trans>,
				formatter: this.deleteFormatter,
			});
		}
	}

	componentDidMount = () => {
		const { fetchAllPolicies, fetchRoles, fetchMembers } = this.props;
		fetchAllPolicies();
		fetchRoles();
		fetchMembers();
	};

	toggleProfileEditable = () => {
		const prevState = this.state.profile_editable;

		if (prevState === true) {
			this.updateProfile();
		} else {
			this.setState({
				profile_editable: !prevState,
			});
		}
	};

	profileHandler = (e) => {
		const target = e.target;
		const field = target.name;
		const value = target.value;

		this.setState({ [field]: value });
	};

	updateProfile = () => {
		const { pName, pEmail, pCompany, pPhone } = this.state;
		const { updateUserDetails } = this.props;

		const updateObj = {
			name: pName,
			email: pEmail,
			company: pCompany,
			phone: pPhone,
		};

		Object.keys(updateObj).forEach(
			(key) => updateObj[key] === null && delete updateObj[key]
		);

		let validateArr = [
			{
				field: "pName",
				value: pName,
				fieldName: "Name",
				validations: ["Required"],
			},
			{
				field: "pEmail",
				value: pEmail,
				fieldName: "Email",
				validations: ["Required"],
			},
			{
				field: "pCompany",
				value: pCompany,
				fieldName: "Company",
				validations: ["Required"],
			},
			{
				field: "pPhone",
				value: pPhone,
				fieldName: "Phone",
				validations: ["Required"],
			},
		];

		validateArr = validateArr.filter((f) => f.value !== null);

		const errors = validateForm(validateArr);

		if (!_.isEmpty(errors)) {
			this.setState({ errors: errors });
		} else {
			updateUserDetails(updateObj);
			this.setState({ profile_editable: false, errors: {} });
		}
	};

	openAddRoleModal = (e) => {
		e.preventDefault();
		this.setState({ show_add_role_modal: true });
	};

	closeAddRoleModal = () => {
		this.setState({ show_add_role_modal: false });
	};

	openEditRoleModal = (row, e) => {
		e.preventDefault();
		this.setState({ show_edit_role_modal: true, edit_modal_data: row });
	};

	closeEditRoleModal = () => {
		this.setState({ show_edit_role_modal: false });
	};

	openDeleteMemberModal = (row, e) => {
		e.preventDefault();
		this.setState({
			show_delete_member_modal: true,
			delete_member_modal_data: row,
		});
	};

	closeDeleteMemberModal = () => {
		this.setState({
			show_delete_member_modal: false,
			delete_member_modal_data: null,
		});
	};

	openPassModal = (row, e) => {
		e.preventDefault();
		this.setState({
			show_change_pass_modal: true,
			change_pass_modal_data: row,
		});
	};

	closePassModal = () => {
		this.setState({
			show_change_pass_modal: false,
			change_pass_modal_data: null,
		});
	};

	addRole = (role_name, policies) => {
		const { addUserRole } = this.props;

		let validateArr = [
			{
				field: "role_name",
				value: role_name,
				fieldName: "Role Name",
				validations: ["Required"],
			},
			{
				field: "policies",
				value: policies,
				fieldName: "Policies",
				validations: ["Required"],
			},
		];

		const errors = validateForm(validateArr);

		console.log("role", role_name, " po", policies, "err", errors);
		if (!_.isEmpty(errors)) {
			this.setState({ add_role_errors: errors });
		} else {
			addUserRole({ role_name, policies });
			this.setState({ add_role_errors: {} });
			this.closeAddRoleModal();
		}
	};

	editRole = (role_name, policies) => {
		const { edit_modal_data } = this.state;
		const updateObj = {
			name: role_name,
			policies: policies,
		};

		const roleId = edit_modal_data._id;

		Object.keys(updateObj).forEach(
			(key) => updateObj[key] === null && delete updateObj[key]
		);

		let validateArr = [
			{
				field: "role_name",
				value: role_name,
				fieldName: "Role Name",
				validations: ["Required"],
			},
			{
				field: "policies",
				value: policies,
				fieldName: "Policies",
				validations: ["Required"],
			},
		];
		validateArr = validateArr.filter((f) => f.value !== null);

		const errors = validateForm(validateArr);

		if (updateObj.policies) {
			updateObj.policies = updateObj.policies.map((p) => p.value);
		}

		if (!_.isEmpty(errors)) {
			this.setState({ edit_role_errors: errors });
		} else {
			const { updateRole } = this.props;

			updateRole({ updateObj, roleId });
			this.setState({ edit_role_errors: {} });
			this.closeEditRoleModal();
		}
	};

	openAddMemberModal = (e) => {
		e.preventDefault();
		this.setState({ show_add_member_modal: true });
	};

	closeAddMemberModal = () => {
		this.setState({ show_add_member_modal: false });
	};

	openEditMemberModal = (row, e) => {
		e.preventDefault();
		this.setState({
			show_edit_member_modal: true,
			edit_member_modal_data: row,
		});
	};

	closeEditMemberModal = () => {
		this.setState({
			show_edit_member_modal: false,
			edit_member_modal_data: null,
		});
	};

	addMember = (formData) => {
		const { addMember } = this.props;

		const { name, email, phone, password, role, business_units } = formData;
		let validateArr = [
			{
				field: "name",
				value: name,
				fieldName: "Name",
				validations: ["Required"],
			},
			{
				field: "email",
				value: email,
				fieldName: "Email",
				validations: ["Required"],
			},
			{
				field: "phone",
				value: phone,
				fieldName: "Phone",
				validations: ["Required"],
			},
			{
				field: "password",
				value: password,
				fieldName: "Password",
				validations: ["Required"],
			},
			{
				field: "role",
				value: role,
				fieldName: "Role",
				validations: ["Required"],
			},
			// {
			// 	field: "business_units",
			// 	value: business_units,
			// 	fieldName: "Business Units",
			// 	validations: ["Required"],
			// },
		];

		const errors = validateForm(validateArr);

		if (!_.isEmpty(errors)) {
			this.setState({ add_member_errors: errors });
		} else {
			addMember(formData);
			this.setState({ add_member_errors: {} });
			this.closeAddMemberModal();
		}
	};

	mFormHandler = (field, value) => {
		var formData = { ...this.state.edit_member_modal_data };
		formData[field] = value;
		this.setState({ edit_member_modal_data: formData });
	};

	editMember = () => {
		const { edit_member_modal_data } = this.state;
		const { name, email, phone, role, _id, business_units, shared_users } =
			edit_member_modal_data;
		const memberId = _id;

		const updateObj = {
			name: name,
			email: email,
			phone: phone,
			role: role._id,
			business_units: business_units,
			shared_users: shared_users,
		};

		Object.keys(updateObj).forEach(
			(key) => updateObj[key] === null && delete updateObj[key]
		);

		let validateArr = [
			{
				field: "name",
				value: name,
				fieldName: "Name",
				validations: ["Required"],
			},
			{
				field: "email",
				value: email,
				fieldName: "Email",
				validations: ["Required"],
			},
			{
				field: "phone",
				value: phone,
				fieldName: "Phone",
				validations: ["Required"],
			},
			{
				field: "role",
				value: role,
				fieldName: "Role",
				validations: ["Required"],
			},
			// {
			// 	field: "business_units",
			// 	value: business_units,
			// 	fieldName: "Business Units",
			// 	validations: ["Required"],
			// },
		];
		validateArr = validateArr.filter((f) => f.value !== null);

		const errors = validateForm(validateArr);

		console.log("edit member errors", errors);
		if (!_.isEmpty(errors)) {
			this.setState({ edit_member_errors: errors });
		} else {
			const { updateMember } = this.props;

			updateMember({ updateObj, memberId });
			this.setState({ edit_member_errors: {} });

			this.closeEditMemberModal();
		}
	};

	deleteMember = () => {
		console.log("delete_member");
		const { delete_member_modal_data } = this.state;
		const { deleteMember } = this.props;

		deleteMember(delete_member_modal_data);
		this.closeDeleteMemberModal();
	};

	changePassword = (data) => {
		console.log("change Password", data);
		const { changePassword } = this.props;
		const { change_pass_modal_data } = this.state;
		data["member_data"] = change_pass_modal_data;
		changePassword(data);
		this.closePassModal();
	};

	editFormatter = (cell, row) => {
		return (
			<Button
				className="mr-4"
				color="info"
				href="#pablo"
				onClick={(e) => this.openEditRoleModal(row, e)}
				size="sm"
			>
				<i className="far fa-edit" />
				<Trans>Edit</Trans>
			</Button>
		);
	};

	meditFormatter = (cell, row) => {
		return (
			<Button
				className="mr-4"
				color="info"
				href="#pablo"
				onClick={(e) => this.openEditMemberModal(row, e)}
				size="sm"
			>
				<i className="far fa-edit" />
				<Trans>Edit</Trans>
			</Button>
		);
	};

	policyFormatter = (cell, row) => {
		return (
			<Button
				className="mr-4"
				color="default"
				href="#pablo"
				onClick={(e) => e.preventDefault()}
				size="sm"
			>
				<Trans>See Policies</Trans>
			</Button>
		);
	};

	roleFormatter = (cell, row) => {
		return cell.name;
	};

	deleteFormatter = (cell, row) => {
		return (
			<Button
				className="mr-4"
				color="danger"
				href="#pablo"
				onClick={(e) => this.openDeleteMemberModal(row, e)}
				size="sm"
			>
				<i className="fas fa-trash" />
				Delete
			</Button>
		);
	};

	passFormatter = (cell, row) => {
		return (
			<Button
				className="mr-4"
				color="default"
				href="#pablo"
				onClick={(e) => this.openPassModal(row, e)}
				size="sm"
			>
				<i className="fas fa-key" />
				Pass
			</Button>
		);
	};

	handleLanguageChange = (data) => {
		console.log("selected language", data);
		const { value } = data;
		localStorage.setItem("lng", value);
		window.location.reload();
	};

	render() {
		const { user, all_policies, roles, members } = this.props;
		const { name, email, company, phone, pic } = user;
		const {
			profile_editable,
			pName,
			pEmail,
			pCompany,
			pPhone,
			errors,
			show_add_role_modal,
			show_edit_role_modal,
			edit_modal_data,
			show_add_member_modal,
			show_edit_member_modal,
			edit_member_modal_data,
			add_role_errors,
			edit_role_errors,
			add_member_errors,
			edit_member_errors,
			show_delete_member_modal,
			delete_member_modal_data,
			change_pass_modal_data,
			show_change_pass_modal,
		} = this.state;

		return (
			<>
				<UserHeader />
				{/* Page content */}
				<Container className="mt--7 mb-5" fluid>
					<Row>
						<Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
							<Card className="card-profile shadow">
								<Row className="justify-content-center">
									<Col className="order-lg-2" lg="3">
										<div className="card-profile-image justify-content-center">
											<a href="#pablo" onClick={(e) => e.preventDefault()}>
												<img
													alt="..."
													// className="rounded-circle"
													style={{"height":"40px", marginTop:"30px"}}
													src={"https://locad.net/assets/images/NEW-LOCAD.png"}

												/>
											</a>
										</div>
									</Col>
								</Row>
								<CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
									<div className="d-flex justify-content-between">
										<Button
											className="mr-4"
											color="info"
											href="#pablo"
											onClick={(e) => e.preventDefault()}
											size="sm"
										>
											<Trans>Connect</Trans>
										</Button>
										<Button
											className="float-right"
											color="default"
											href="#pablo"
											onClick={(e) => e.preventDefault()}
											size="sm"
										>
											<Trans>Message</Trans>
										</Button>
									</div>
								</CardHeader>
								<CardBody className="pt-0 pt-md-4">
									<Row>
										<div className="col">
											<div className="card-profile-stats d-flex justify-content-center mt-md-5"></div>
										</div>
									</Row>
									<div className="text-center">
										<h3>{name}</h3>
										<div className="h5 font-weight-300">
											<i className="ni location_pin mr-2" />
											{email}
										</div>
										<div className="h5 mt-4">
											<i className="ni business_briefcase-24 mr-2" />
											<Trans>Company</Trans>
										</div>
										<div>
											<i className="ni education_hat mr-2" />
											{company}
										</div>
										<hr className="my-4" />
										<p>
											<Trans i18nKey="profile_page_message">
												This is your profile page. You can see the progress
												you've made with your work and manage your projects or
												assigned tasks
											</Trans>
										</p>
									</div>
								</CardBody>
							</Card>
						</Col>
						<Col className="order-xl-1" xl="8">
							<Card className="bg-secondary shadow">
								<CardHeader className="bg-white border-0">
									<Row className="align-items-center">
										<Col xs="8">
											<h3 className="mb-0">
												<Trans>My account</Trans>
											</h3>
										</Col>
									</Row>
									<Row>
										<Col xs="8">
											<Select
												placeholder={"Choose Language"}
												options={[
													{ label: "English", value: "en" },
													{ label: "Spanish", value: "es" },
												]}
												onChange={this.handleLanguageChange}
											/>
										</Col>
									</Row>
								</CardHeader>
								<CardBody>
									<Form>
										<Row className="align-items-center">
											<Col xs="8">
												<h6 className="heading-small text-muted mb-4">
													<Trans>User Information</Trans>
												</h6>
											</Col>
											<Col className="text-right" xs="4">
												<Button
													color="primary"
													href="#pablo"
													onClick={this.toggleProfileEditable}
													size="sm"
												>
													{profile_editable ? (
														<Trans>{"Save"}</Trans>
													) : (
														<Trans>{"Edit"}</Trans>
													)}
												</Button>
											</Col>
										</Row>
										<div className="pl-lg-4">
											<Row>
												<Col lg="6">
													<FormGroup>
														<label
															className="form-control-label"
															htmlFor="input-username"
														>
															<Trans>Name</Trans>
														</label>
														{profile_editable ? (
															<Input
																className="form-control-alternative"
																id="input-username"
																name="pName"
																placeholder="Name"
																onChange={this.profileHandler}
																value={pName !== null ? pName : name}
																type="text"
															/>
														) : (
															<p>{name}</p>
														)}
														{errors.pName && (
															<small style={{ color: "#f5365c" }}>
																{" "}
																<span className="fa fa-exclamation-circle">
																	{" "}
																</span>{" "}
																{errors.pName}
															</small>
														)}
													</FormGroup>
												</Col>
												<Col lg="6">
													<FormGroup>
														<label
															className="form-control-label"
															htmlFor="input-email"
														>
															<Trans>Email address</Trans>
														</label>
														{profile_editable ? (
															<Input
																className="form-control-alternative"
																id="input-email"
																placeholder="jesse@example.com"
																type="email"
																name="pEmail"
																onChange={this.profileHandler}
																value={pEmail !== null ? pEmail : email}
															/>
														) : (
															<p>{email}</p>
														)}
													</FormGroup>
												</Col>
											</Row>
											<Row>
												<Col lg="6">
													<FormGroup>
														<label
															className="form-control-label"
															htmlFor="input-first-name"
														>
															<Trans>Company</Trans>
														</label>
														{profile_editable ? (
															<Input
																className="form-control-alternative"
																id="input-first-name"
																placeholder="Company Name"
																type="text"
																name="pCompany"
																onChange={this.profileHandler}
																value={pCompany !== null ? pCompany : company}
															/>
														) : (
															<p>{company}</p>
														)}
													</FormGroup>
												</Col>
												<Col lg="6">
													<FormGroup>
														<label
															className="form-control-label"
															htmlFor="input-last-name"
														>
															<Trans>Phone</Trans>
														</label>
														{profile_editable ? (
															<Input
																className="form-control-alternative"
																id="input-last-name"
																placeholder="phone"
																type="text"
																name="pPhone"
																onChange={this.profileHandler}
																value={pPhone !== null ? pPhone : phone}
															/>
														) : (
															<p>{phone}</p>
														)}
													</FormGroup>
												</Col>
											</Row>
										</div>
										<hr className="my-4" />
										{/* Roles */}
										<Row className="align-items-center">
											<Col xs="8">
												<h6 className="heading-small text-muted mb-4">
													<Trans>Roles</Trans>
												</h6>
											</Col>
											<Col className="text-right" xs="4">
												{HasPolicies(["role_management_policy"]) && (
													<Button
														color="primary"
														href="#pablo"
														onClick={this.openAddRoleModal}
														size="sm"
													>
														<Trans>Add Role</Trans>
													</Button>
												)}
											</Col>
										</Row>

										<div className="pl-lg-4">
											<Row>
												<Col md="12">
													<BSTable
														columns={this.roleColumns}
														data={roles}
														expandRowData={expandRow}
													/>
												</Col>
											</Row>
										</div>
										<hr className="my-4" />
										{/* Members */}
										<Row className="align-items-center mb-4">
											<Col xs="8">
												<h6 className="heading-small text-muted mb-4">
													<Trans>Members</Trans>
												</h6>
											</Col>
											<Col className="text-right" xs="4">
												{HasPolicies(["member_management_policy"]) && (
													<Button
														color="primary"
														href="#pablo"
														onClick={this.openAddMemberModal}
														size="sm"
													>
														<Trans>Add Member</Trans>
													</Button>
												)}
											</Col>
										</Row>
										<div className="pl-lg-4">
											<Row>
												<Col md="12">
													<BSTable
														columns={this.memberColumns}
														data={members}
													/>
												</Col>
											</Row>
										</div>
									</Form>
								</CardBody>
							</Card>
						</Col>
					</Row>

					{/* ---- Add Role Modal ---- */}
					<AddRoleModal
						show={show_add_role_modal}
						all_policies={all_policies}
						add_role_errors={add_role_errors}
						closeAddRoleModal={this.closeAddRoleModal}
						addRole={this.addRole}
					/>

					<EditRoleModal
						show={show_edit_role_modal}
						all_policies={all_policies}
						edit_role_errors={edit_role_errors}
						closeEditRoleModal={this.closeEditRoleModal}
						edit_modal_data={edit_modal_data}
						editRole={this.editRole}
					/>

					{/*--- Add Member Modal ---*/}
					<AddMemberModal
						show={show_add_member_modal}
						roles={roles}
						add_member_errors={add_member_errors}
						closeAddMemberModal={this.closeAddMemberModal}
						addMember={this.addMember}
						members={members}
					/>

					<EditMemberModal
						show={show_edit_member_modal}
						roles={roles}
						edit_member_errors={edit_member_errors}
						closeEditMemberModal={this.closeEditMemberModal}
						edit_member_modal_data={edit_member_modal_data}
						editMember={this.editMember}
						mFormHandler={this.mFormHandler}
						members={members}
					/>
					<DeleteMemberModal
						show={show_delete_member_modal}
						delete_member_modal_data={delete_member_modal_data}
						closeDeleteMemberModal={this.closeDeleteMemberModal}
						deleteMember={this.deleteMember}
					/>

					<PasswordChangeModal
						show={show_change_pass_modal}
						change_pass_modal_data={change_pass_modal_data}
						closePassModal={this.closePassModal}
						changePassword={this.changePassword}
					/>
				</Container>
			</>
		);
	}
}

const mapStateToProps = ({ auth }) => {
	const { user, all_policies, roles, members } = auth;
	return {
		user,
		all_policies,
		roles,
		members,
	};
};

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			updateUserDetails,
			fetchAllPolicies,
			addUserRole,
			fetchRoles,
			updateRole,
			addMember,
			fetchMembers,
			updateMember,
			deleteMember,
			changePassword,
		},
		dispatch
	);

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
