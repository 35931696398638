import React, { useState } from "react";
import {
	Card,
	Row,
	CardBody,
	Col,
	CardHeader,
	Progress,
	Button,
	Alert,
} from "reactstrap";
import RemoteReactGridInventory from "./RemoteReactGridInventory";
import { nFormatter } from "../../helpers/helpers";
import { Trans } from "react-i18next";

const Inventory = (props) => {
	const {
		camp_details,
		bookInventory,
		invColumns,
		budgetAllocated,
		selectedInv,
		setSelectedInventory,
		filterObj,
		fetchSuggInventory,
		simNetworks,
		filterChanges,
		budgetProgress,
		remainingBudget,
	} = props;

	const [total_inventory, updateTotalInventory] = useState(0);

	return (
		<Card className="shadow mb-sm-3">
			<CardHeader>
				<Row>
					<Col>
						<h3>
							<Trans>Inventory</Trans>
							{total_inventory !== 0 && (
								<span>{" (" + total_inventory + ")"}</span>
							)}
						</h3>
					</Col>
				</Row>
			</CardHeader>
			<CardBody>
				{/* ----- Plan Inventory Button ----- */}
				{selectedInv && selectedInv.length > 0 && (
					<Alert className="alert-default" style={{ overflow: "hidden" }}>
						<Row style={{ alignItems: "center" }}>
							<Col md="3">
								<strong> {selectedInv.length} Selected</strong>
							</Col>
							<Col md="6">
								<Progress max="100" value={budgetProgress} color="default" />
								<center>
									<strong>{budgetAllocated} </strong>
									{remainingBudget > 0 ? (
										<span>
											(<Trans>Remaining</Trans> {nFormatter(remainingBudget, 1)}
											)
										</span>
									) : (
										<span>
											(<Trans>Budget Exceeded by</Trans>{" "}
											{nFormatter(Math.abs(remainingBudget), 1)})
										</span>
									)}
								</center>
							</Col>
							<Col md="3">
								<Button
									className="float-right"
									color="secondary"
									type="button"
									onClick={bookInventory}
								>
									<span className="ni ni-basket"> </span>
									<Trans>Plan</Trans>
									{/* {fetching_booked=="pending" ? "Processing...":"Plan"} */}
								</Button>
							</Col>
						</Row>
					</Alert>
				)}

				{/* ----- Inventory ----- */}
				{camp_details && (
					<RemoteReactGridInventory
						{...props}
						pColumns={invColumns}
						setSelectedInventory={setSelectedInventory}
						campId={camp_details._id}
						filterObj={filterObj}
						simNetworks={simNetworks}
						selectedInv={selectedInv}
						fetchSuggInventory={fetchSuggInventory}
						updateTotalInventory={updateTotalInventory}
						filterChanges={filterChanges}
					/>
				)}
			</CardBody>
		</Card>
	);
};

export default Inventory;
