import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationEN from "./i18n/en/resource.json";
import translationES from "./i18n/es/resource.json";

// the translations
const resources = {
	en: {
		translation: translationEN,
	},
	es: {
		translation: translationES,
	},
};

const lng = localStorage.getItem("lng");

i18n.use(initReactI18next).init({
	resources,
	lng: lng ? lng : "en",
	keySeparator: false,
	interpolation: {
		escapeValue: false,
	},
});

export default i18n;
