import React from "react";
import {
  Container,
  Card,
  Row,
  Col,
  Button,
  CardBody,
  Alert,
  Progress,
  UncontrolledTooltip,
  CardHeader,
} from "reactstrap";
// core components
import CompHeader from "components/Headers/CompHeader.js";
import {
  fetchBookedInventory,
  sendInvApproval,
  fetchChatUsers,
  removeBookedSites,
  internalApproval,
} from "actions/invActions";
import {
  exportExcel,
  downloadCampPPT,
  makeLocCamp,
  fetchCampDetails,
} from "actions/campActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Alerts from "../alerts";
import HasPolicies from "../../helpers/hasPolicies";
import { nFormatter } from "../../helpers/helpers";
import ReactGrid2 from "../../views/campaigns/ReactGrid2";
import Chat from "./chat/Chat";
import InvMap from "../sugg_inventory/InvMap";
import DownloadPPTModal from "./DownloadPPTModal";
import MakeScreenoListModal from "./MakeScreenoListModal";
import { Trans } from "react-i18next";
import i18n from "../../i18n";
import { devUrl } from "../../utility";
import axios from "axios";
// import Alerts from "../alerts";

const invColumns = [
  // { title: 'Sitemap', field: 'site_map', render: rowData => <img src={rowData.site_map} style={{width: 100}}/> },
  { title: i18n.t("Media Owner"), name: "media_owner" },
  { title: i18n.t("Location"), name: "location" },
  { title: i18n.t("Width"), name: "width" },
  { title: i18n.t("Height"), name: "height" },
  { title: i18n.t("City"), name: "city" },
  { title: i18n.t("Media Type"), name: "media_type" },
  { title: i18n.t("Price"), name: "inventory_cost" },
  { title: i18n.t("Status"), name: "status" },
  { title: i18n.t("Approved By"), name: "booked_by" },
];

class BookInventory extends React.Component {
  state = {
    selectedInv: [],
    showCard: {
      map: false,
    },
    showPPTModal: false,
    showScreenoModal: false,
    activeAlert: "",
    createLocauditCampAlert: "",
    locCampLoader: false,
  };

  componentDidMount() {
    const { campId } = this.props.match.params;
    const { fetchBookedInventory, fetchChatUsers, fetchCampDetails } =
      this.props;
    fetchBookedInventory({ campId });
    fetchChatUsers({ campId });
    fetchCampDetails({ campId });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.loc_camps.length < this.props.loc_camps.length) {
      this.setState({ locCampLoader: false });
      this.setState({
        activeAlert: "Campaign synced with Locaudit Successfully",
      });
    }
  }

  setSelectedInventory = (selectedRows) => {
    this.setState({ selectedInv: selectedRows });
  };

  reqApprovalInventory = () => {
    const { selectedInv } = this.state;
    const { sendInvApproval } = this.props;
    const { campId } = this.props.match.params;
    const invIds = selectedInv.map((s) => s._id);
    sendInvApproval({ invIds, campId });
    this.setState({ selectedInv: [] });
  };

  internalApproval = () => {
    const { selectedInv } = this.state;
    const { internalApproval } = this.props;
    const { campId } = this.props.match.params;
    const invIds = selectedInv.map((s) => s._id);
    internalApproval({ invIds, campId });
    this.setState({ selectedInv: [] });
  };

  toggleShowCard = (key) => {
    this.setState((prevState) => ({
      ...prevState,
      showCard: {
        ...prevState.showCard,
        [key]: !this.state.showCard[key],
      },
    }));
  };

  downloadExcel = () => {
    // console.log("gola");
    const { campId } = this.props.match.params;
    const { exportExcel } = this.props;

    exportExcel(campId);
  };

  showScreenoModal = () => {
    console.log("showModal");
    this.setState({
      showScreenoModal: true,
    });
  };

  closeScreenoModal = () => {
    this.setState({
      showScreenoModal: false,
    });
  };

  showPPTModal = () => {
    console.log("showModal");
    this.setState({
      showPPTModal: true,
    });
  };

  closePPTModal = () => {
    this.setState({
      showPPTModal: false,
    });
  };

  downloadPPT = (emails) => {
    const { campId } = this.props.match.params;
    const { downloadCampPPT } = this.props;

    downloadCampPPT({ camp_id: campId, emails });
    this.closePPTModal();
    this.setState({
      activeAlert: "Request Sent. You will receive Email when PPT is Ready",
    });
  };

  closeActiveAlert = () => {
    this.setState({ activeAlert: "" });
  };

  closeActiveAlert2 = () => {
    this.setState({ createLocauditCampAlert: "" });
  };

  makeLocauditCamp = async () => {
    console.log("make loc");
    const { campId } = this.props.match.params;
    let campRes = await axios.post(
      `${devUrl}/campaignDetails`,
      { campId },
      { headers: { authorization: localStorage.getItem("plano_token") } }
    );
    console.log("response", campRes.data);
    if (new Date(campRes.data.camp.end_date).getTime() < new Date().getTime()) {
      this.setState({
        createLocauditCampAlert:
          "Please edit campaign end date since it cannot be less than today's date",
      });
    } else {
      const { makeLocCamp } = this.props;
      makeLocCamp({ campId });
      this.setState({ locCampLoader: true });
    }
  };

  removeBookedSites = () => {
    const { removeBookedSites } = this.props;
    const { campId } = this.props.match.params;
    const { selectedInv } = this.state;
    const invIds = selectedInv.map((s) => s._id);

    removeBookedSites({ campId, invIds });
    this.setState({ selectedInv: [] });
  };

  render() {
    const { booked_inventory, chat_users,   camp_details } = this.props;
    const {
      selectedInv,
      showCard,
      showPPTModal,
      showScreenoModal,
      activeAlert,
      locCampLoader,
      createLocauditCampAlert,
    } = this.state;
    return (
      <>
        <CompHeader />
        <Container fluid>
          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="9">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">
                        <Trans>Book Inventory</Trans>
                      </h3>
                    </div>
                    <div className="col text-right">
                      {HasPolicies(["export data policy"]) && (
                        <>
                          <Button
                            id="exportBtn"
                            color="default"
                            onClick={this.downloadExcel}
                            size="m"
                          >
                            <span className="fa fa-download"></span>
                          </Button>
                          <UncontrolledTooltip
                            delay={0}
                            placement="top"
                            target="exportBtn"
                          >
                            <Trans>Download Excel</Trans>
                          </UncontrolledTooltip>
                        </>
                      )}

                      <Button
                        id="showMap"
                        color={showCard.map ? "primary" : "default"}
                        onClick={() => this.toggleShowCard("map")}
                        size="m"
                      >
                        <span className="fa fa-map-marker-alt"></span>
                      </Button>
                      <UncontrolledTooltip
                        delay={0}
                        placement="top"
                        target="showMap"
                      >
                        <Trans>Map</Trans>
                      </UncontrolledTooltip>

                      {HasPolicies(["export data policy"]) && (
                        <>
                          <Button
                            id="togglePPT"
                            color="default"
                            onClick={() => this.showPPTModal()}
                            size="m"
                          >
                            <span className="fas fa-file-powerpoint"></span>
                          </Button>
                          <UncontrolledTooltip
                            delay={0}
                            placement="top"
                            target="togglePPT"
                          >
                            <Trans>Download PowerPoint</Trans>
                          </UncontrolledTooltip>
                        </>
                      )}

                      {HasPolicies(["campaign_transfer_policy"]) && (
                        <>
                          <Button
                            id="toggleChat"
                            color="default"
                            onClick={() => this.makeLocauditCamp()}
                            size="m"
                          >
                            {locCampLoader ? (
                              <span className="spinner-border spinner-border-sm"></span>
                            ) : (
                              <span className="fas fa-exchange-alt"></span>
                            )}
                          </Button>
                          <UncontrolledTooltip
                            delay={0}
                            placement="top"
                            target="toggleChat"
                          >
                            <Trans>
                              Make Campaign of Booked Sites in Locaudit
                            </Trans>
                          </UncontrolledTooltip>

                          <Button
                            id="toggleChat2"
                            color="default"
                            onClick={() => this.showScreenoModal()}
                            size="m"
                          >
                            <span className="fas fa-exchange-alt"></span>
                          </Button>
                          <UncontrolledTooltip
                            delay={0}
                            placement="top"
                            target="toggleChat2"
                          >
                            <Trans>
                              Make a list of Booked Digital Sites in Screeno
                            </Trans>
                          </UncontrolledTooltip>
                        </>
                      )}

                      {/* <Button
                        color="default"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                        size="m"
                      >
                        <span className="ni ni-chart-pie-35"></span>
                      </Button> */}
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Alerts />
                  {/* { ---- Download PPT Alert ----- } */}
                  {activeAlert && (
                    <Col>
                      <Alert style={{ overflow: "hidden" }} color="success">
                        <Row>
                          <Col lg="11">
                            <strong> {activeAlert}</strong>
                          </Col>
                          <Col
                            lg="1"
                            className="col text-right"
                            onClick={this.closeActiveAlert}
                            style={{ cursor: "pointer" }}
                          >
                            <strong>X</strong>
                          </Col>
                        </Row>
                      </Alert>
                    </Col>
                  )}
                  {createLocauditCampAlert && (
                    <Col>
                      <Alert style={{ overflow: "hidden" }} color="danger">
                        <Row>
                          <Col lg="11">
                            <strong> {createLocauditCampAlert}</strong>
                          </Col>
                          <Col
                            lg="1"
                            className="col text-right"
                            onClick={this.closeActiveAlert2}
                            style={{ cursor: "pointer" }}
                          >
                            <strong>X</strong>
                          </Col>
                        </Row>
                      </Alert>
                    </Col>
                  )}

                  {/* ----- Book Inventory Button ----- */}
                  {selectedInv &&
                    selectedInv.length > 0 &&
                    HasPolicies([
                      "approver policy",
                      "remove_booked_inventory",
                      "internal_approval_policy",
                    ]) && (
                      <Alert style={{ overflow: "hidden" }} color="info">
                        <strong
                          style={{ marginTop: "8px", position: "absolute" }}
                        >
                          {" "}
                          {selectedInv.length} <Trans>Selected</Trans>
                        </strong>
                        {HasPolicies(["approver policy"]) && (
                          <Button
                            className="float-right"
                            color="primary"
                            type="button"
                            onClick={this.reqApprovalInventory}
                          >
                            <span className="ni ni-basket"> </span>{" "}
                            <Trans>Send For Approval</Trans>
                          </Button>
                        )}
                        {HasPolicies(["internal_approval_policy"]) && (
                          <Button
                            className="float-right"
                            color="primary"
                            type="button"
                            onClick={this.internalApproval}
                          >
                            <span className="ni ni-basket"> </span>{" "}
                            <Trans>Approve Bookings</Trans>
                          </Button>
                        )}
                        {HasPolicies(["remove_booked_inventory"]) && (
                          <Button
                            className="float-right"
                            color="danger"
                            type="button"
                            style={{ marginRight: "10px" }}
                            onClick={this.removeBookedSites}
                          >
                            <span className="ni ni-basket"> </span>{" "}
                            <Trans>Remove Sites</Trans>
                          </Button>
                        )}
                      </Alert>
                    )}

                  {selectedInv &&
                    selectedInv.length > 0 &&
                    !HasPolicies([
                      "approver policy",
                      "internal_approval_policy",
                      "remove_booked_inventory",
                    ]) && (
                      <Alert style={{ height: "50px" }} color="danger">
                        <strong style={{ marginTop: "8px" }}>
                          {" "}
                          <Trans i18nKey="approval_error">
                            You do not have required permissions for Approval or
                            Removal of Inventory
                          </Trans>
                        </strong>
                      </Alert>
                    )}

                  {/* Budget Allocation */}
                  {camp_details && (
                    <Row
                      style={{
                        justifyContent: "flex-end",
                        marginBottom: "20px",
                      }}
                    >
                      <Col md="6" className="notranslate">
                        <p>
                          <strong>
                            {nFormatter(camp_details.budget_used, 1)} /{" "}
                            {nFormatter(camp_details.budget, 1)}{" "}
                            <Trans>Budget used</Trans>{" "}
                          </strong>
                          (<Trans>Remaining</Trans>{" "}
                          {nFormatter(
                            camp_details.budget - camp_details.budget_used,
                            1
                          )}
                          )
                        </p>
                        <Progress
                          max="100"
                          style={{ width: "auto" }}
                          value={camp_details.bd_progress}
                          color="primary"
                        />
                      </Col>
                    </Row>
                  )}

                  {booked_inventory && booked_inventory.length > 0 && (
                    <ReactGrid2
                      {...this.props}
                      inventory={booked_inventory}
                      pColumns={invColumns}
                      setSelectedInventory={this.setSelectedInventory}
                    />
                  )}

                  {/* ----- Map ---- */}
                  <InvMap show={showCard.map} inventory={booked_inventory} />
                </CardBody>
              </Card>
            </Col>
            <Col xl="3">
              <Chat chat_users={chat_users} />
            </Col>
          </Row>

          <DownloadPPTModal
            show={showPPTModal}
            closePPTModal={this.closePPTModal}
            showPPTModal={this.showPPTModal}
            downloadPPT={this.downloadPPT}
          />
          <MakeScreenoListModal
            camp_details={camp_details}
            booked_inventory={booked_inventory}
            campId={this.props.match.params.campId}
            show={showScreenoModal}
            closeScreenoModal={this.closeScreenoModal}
            showScreenoModal={this.showScreenoModal}
          />
        </Container>
      </>
    );
  }
}

const mapStateToProps = ({ inv, camps }) => {
  const { booked_inventory, chat_users } = inv;
  const { camp_details, loc_camps } = camps;

  return {
    booked_inventory,
    chat_users,
    camp_details,
    loc_camps,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchBookedInventory,
      sendInvApproval,
      fetchChatUsers,
      exportExcel,
      downloadCampPPT,
      makeLocCamp,
      fetchCampDetails,
      removeBookedSites,
      internalApproval,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(BookInventory);
