import React from "react";
import { Container, Button, UncontrolledTooltip, Modal } from "reactstrap";
import { Redirect } from "react-router-dom";
// core components
import CompHeader from "components/Headers/CompHeader.js";
import {
  fetchSuggInventory,
  fetchNetworks,
  fetchNearby,
  bookInventory,
  resetSuggInventory,
} from "actions/invActions";
import { fetchCampDetails } from "actions/campActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Networks from "./Networks";
import Filters from "./Filters";
import FilterMap from "./FilterMap";
import Inventory from "./Inventory";
import RemoteMap from "./RemoteMap";
import _ from "lodash";
import { nFormatter } from "../../helpers/helpers";
import axios from "axios";
import { devUrl } from "../../utility";
import { Trans } from "react-i18next";
import i18n from "../../i18n";

class SuggInventory extends React.Component {
  state = {
    networkCollapse: false,
    filterCollapse: false,
    mapCollapse: false,
    showCard: {
      map: true,
      network: false,
      filter: false,
    },
    selNetworks: new Map(),
    selectedInv: [],
    budgetProgress: 0,
    budgetAllocated: 0,
    remainingBudget: 0,
    filterChanged: false,
    filterObj: {},
    simNetworks: [],
    filterChanges: 0,
    distinctFilters: {},
    budgetErr: "",
  };

  componentDidMount() {
    // console.log('props',this.props)
    const { campId } = this.props.match.params;
    const { fetchNetworks, fetchCampDetails, fetchNearby } = this.props;
    fetchNetworks({ campId });
    fetchCampDetails({ campId });
    fetchNearby({ campId });
  }

  componentDidUpdate(prevProps, prevState) {
    // Show Budget Exceeded Modal
    if (
      prevProps.booking_err !== this.props.booking_err &&
      this.props.booking_err
    ) {
      const booking_err = this.props.booking_err;
      const totalBudget = nFormatter(booking_err.totalBudget, 1);
      const budgetDiff = nFormatter(
        booking_err.newBudgetUsed - booking_err.totalBudget,
        1
      );
      const newBudget = nFormatter(booking_err.newBudgetUsed, 1);
      const error_message = (
        <Trans
          i18nKey="budget_increase_message"
          totalBudget={totalBudget}
          budgetDiff={budgetDiff}
          newBudget={newBudget}
        >
          You have exceeded your Campaign Budget ({{ totalBudget }}) by{" "}
          {{ budgetDiff }}. So do you still want to continue with this new
          Budget ({{ newBudget }}) or you want to remove some of the inventory
        </Trans>
      );
      this.setState({ budgetErr: error_message });
      this.toggleModal("budgetModal");
    }
  }

  toggleNetworkCollapse = (key) => {
    this.setState({ networkCollapse: !this.state.networkCollapse });
  };

  toggleFiltersCollapse = () => {
    this.setState({ filterCollapse: !this.state.filterCollapse });
  };

  setSelectedInventory = (selectedRows, type) => {
    const { selectedInv } = this.state;
    // console.log("type",type)
    if (type === "map") {
      var selectedInvNew = [];
      if (!selectedRows.length) {
        selectedRows = [selectedRows]; // for handling single objects so handling it becomes easier
        const invAlreadyExists =
          _.intersectionBy(selectedInv, selectedRows, "_id").length !== 0;
        // remove from plan
        if (invAlreadyExists) {
          selectedInvNew = _.differenceBy(selectedInv, selectedRows, "_id");
        }
        // add to plan
        else {
          selectedInvNew = selectedInv.concat(selectedRows);
        }
        //  console.log("invAlreadyExists", invAlreadyExists, ' selectedInvNew',selectedInvNew)
      } else {
        selectedInvNew = selectedRows;
      }

      const { campId } = this.props.match.params;
      axios
        .post(
          devUrl + "/get_budget_inv",
          { inv_ids: selectedInvNew, campId },
          { headers: { authorization: localStorage.getItem("plano_token") } }
        )
        .then((response) => {
          const budget_used = nFormatter(response.data.budget_used, 1);
          const budget_progress = response.data.budget_progress;
          const remainingBudget = response.data.remainingBudget;

          // console.log("budget_used",budget_used,"budget_progress",budget_progress,"remainingBudget",remainingBudget,"selectedInv",selectedInv)
          this.setState({
            budgetAllocated: budget_used,
            budgetProgress: budget_progress,
            remainingBudget: remainingBudget,
          });
        });

      this.setState({ selectedInv: selectedInvNew });
    } else if (selectedRows === "all") {
    } else {
      //  console.log("selectedInv",selectedInvNew,' selectedRows',selectedRows,' selectedInv',selectedInv)
      selectedInvNew = selectedRows;
      const { campId } = this.props.match.params;
      axios
        .post(
          devUrl + "/get_budget_inv",
          { inv_ids: selectedInvNew, campId },
          { headers: { authorization: localStorage.getItem("plano_token") } }
        )
        .then((response) => {
          const budget_used = nFormatter(response.data.budget_used, 1);
          const budget_progress = response.data.budget_progress;
          const remainingBudget = response.data.remainingBudget;

          // console.log("budget_used",budget_used,"budget_progress",budget_progress,"remainingBudget",remainingBudget,"selectedInv",selectedInv)
          this.setState({
            budgetAllocated: budget_used,
            budgetProgress: budget_progress,
            remainingBudget: remainingBudget,
          });
        });
      // console.log("selectedInv",selectedInvNew,' selectedRows',selectedRows)
      this.setState({ selectedInv: selectedInvNew });
    }
  };

  setDistinctFilters = (distinctFilters) => {
    this.setState({ distinctFilters });
  };

  resetSelectedInventory = () => {
    this.setState({ selectedInv: [], budgetAllocated: 0 });
  };

  toggleShowCard = (key) => {
    this.setState((prevState) => ({
      ...prevState,
      showCard: {
        ...prevState.showCard,
        [key]: !this.state.showCard[key],
      },
    }));
  };

  networkChange = (e) => {
    const item = e.target.name;
    const isChecked = e.target.checked;
    const { campId } = this.props.match.params;
    var selNetworks = this.state.selNetworks;
    selNetworks.set(item, isChecked);

    let keys = [];
    selNetworks.forEach((value, key) => {
      if (value) {
        keys.push(key);
      }
    });
    this.setState((prevState) => ({
      selNetworks: selNetworks,
      simNetworks: keys,
    }));
    fetchSuggInventory({campId ,networks:keys });
  };

  bookInventory = (e, bookingWithIncreasedBudget) => {
    const { budgetAllocated, selectedInv } = this.state;
    const { campId } = this.props.match.params;
    const { camp_details, bookInventory } = this.props;
    const { budget } = camp_details;

    // console.log("selectedInv" , selectedInv,' budget', budget,' allocated', budgetAllocated)
    if (budgetAllocated > budget) {
      this.toggleModal("budgetModal");
    } else {
      var inv_ids = selectedInv.map((s) => s._id);
      inv_ids = _.filter(inv_ids, (i) => i);
      bookInventory({
        selectedInv,
        campId,
        inv_ids,
        bookingWithIncreasedBudget,
      });
      // this.props.history.push("/admin/booked_inventory/"+campId);
    }
  };

  filterChange = (filterObj) => {
    // console.log(this.state.filterObj)
    this.setState({
      filterObj: filterObj,
      filterChanges: this.state.filterChanges + 1,
    });
  };

  toggleModal = (key) => {
    this.setState({
      [key]: !this.state[key],
    });
  };

  invColumns = [
    { title: i18n.t("Media Owner"), name: "media_owner" },
    { title: i18n.t("Site Code"), name: "site_code" },
    { name: "booked_camps", title: i18n.t("Status") },
    { title: i18n.t("Location"), name: "location" },
    { title: i18n.t("Height"), name: "height" },
    { title: i18n.t("Width"), name: "width" },
    { title: i18n.t("Illumination"), name: "lighting" },
    { title: i18n.t("City"), name: "city" },
    { title: i18n.t("Media Type"), name: "media_type" },
    { title: i18n.t("Price"), name: "inventory_cost" },
    // { name: "homes_ab_600mt", title: i18n.t("Home(Ab)") },
    // { name: "homes_c1a_600mt", title: i18n.t("Home(c1a)") },
    // { name: "homes_c1b_600mt", title: i18n.t("Home(c1b)") },
    // { name: "homes_c2_600mt", title: i18n.t("Home(c2)") },
    // { name: "homes_c3_600mt", title: i18n.t("Home(c3)") },
    // { name: "homes_d_600mt", title: i18n.t("Home(d)") },
    // { name: "homes_e_600mt", title: i18n.t("Home(e)") },
  ];

  render() {
    const { campId } = this.props.match.params;
    const {
      inventory,
      networks,
      camp_details,
      nearby_places,
      nearby_data,
      fetching_booked,
      total_count,
    } = this.props;

    const {
      selectedInv,
      showCard,
      selNetworks,
      simNetworks,
      budgetAllocated,
      budgetProgress,
      remainingBudget,
      filterObj,
      filterChanges,
      distinctFilters,
      budgetErr,
    } = this.state;

    if (fetching_booked === "completed") {
      return <Redirect to={"/admin/booked_inventory/" + campId} />;
    }

    return (
      <>
        <CompHeader />
        <Container fluid>
          <div className="nav-wrapper">
            <div
              className="ml-auto"
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button
                id="networkBtn"
                className="btn-icon btn-2"
                color={showCard.network ? "primary" : "default"}
                type="button"
                onClick={() => this.toggleShowCard("network")}
              >
                <span className="btn-inner--icon">
                  <i className="fas fa-network-wired" />
                </span>
              </Button>
              <UncontrolledTooltip
                delay={0}
                placement="top"
                target="networkBtn"
              >
                <Trans>Networks</Trans>
              </UncontrolledTooltip>

              <Button
                id="filterBtn"
                className="btn-icon btn-2"
                color={showCard.filter ? "primary" : "default"}
                type="button"
                onClick={() => this.toggleShowCard("filter")}
              >
                <span className="btn-inner--icon">
                  <i className="fa fa-filter" />
                </span>
              </Button>
              <UncontrolledTooltip delay={0} placement="top" target="filterBtn">
                <Trans>Filters</Trans>
              </UncontrolledTooltip>

              <Button
                id="showMapBtn"
                className="btn-icon btn-2"
                color={showCard.map ? "primary" : "default"}
                type="button"
                onClick={() => this.toggleShowCard("map")}
              >
                <span className="btn-inner--icon">
                  <i className="fa fa-map-marker-alt" />
                </span>
              </Button>
              <UncontrolledTooltip
                delay={0}
                placement="top"
                target="showMapBtn"
              >
                <Trans>Show Map</Trans>
              </UncontrolledTooltip>
            </div>
          </div>

          {/* ----- Networks ---- */}
          <Networks
            networks={networks}
            show={showCard.network}
            selNetworks={selNetworks}
            networkChange={this.networkChange}
          />

          {/* ----- Filters ---- */}
          <Filters
            camp_details={camp_details}
            show={showCard.filter}
            filterChange={this.filterChange.bind(this)}
            filters={this.state.filterObj}
            nearby_places={nearby_places}
            distinctFilters={distinctFilters}
          />
          {/* Geo Location Filter */}

          <FilterMap
            camp_details={camp_details}
            filterChange={this.filterChange.bind(this)}
            filters={this.state.filterObj}
            show={showCard.filter}
            nearby_places={nearby_places}
          />

          {/* ----- Inventory ---- */}
          {!showCard.map && (
            <Inventory
              camp_details={camp_details}
              invColumns={this.invColumns}
              bookInventory={this.bookInventory}
              budgetAllocated={budgetAllocated}
              budgetProgress={budgetProgress}
              remainingBudget={remainingBudget}
              selectedInv={selectedInv}
              fetching_booked={fetching_booked}
              total_inventory={total_count}
              filterObj={filterObj}
              fetchSuggInventory={fetchSuggInventory}
              setSelectedInventory={this.setSelectedInventory}
              filterChanges={filterChanges}
              simNetworks={simNetworks}
            />
          )}

          {/* ----- Map ---- */}
          <RemoteMap
            show={showCard.map}
            inventory={inventory}
            nearby_data={nearby_data}
            filterObj={filterObj}
            campId={campId}
            simNetworks={simNetworks}
            selectedInv={selectedInv}
            setSelectedInventory={this.setSelectedInventory}
            budgetAllocated={budgetAllocated}
            budgetProgress={budgetProgress}
            remainingBudget={remainingBudget}
            fetching_booked={fetching_booked}
            camp_details={camp_details}
            bookInventory={this.bookInventory}
            toggleModal={this.toggleModal}
            resetSelectedInventory={this.resetSelectedInventory}
            filterChanges={filterChanges}
            setDistinctFilters={this.setDistinctFilters}
          />
        </Container>

        <Modal
          className="modal-dialog-centered modal-danger"
          contentClassName="bg-gradient-danger"
          isOpen={this.state.budgetModal}
          toggle={() => this.toggleModal("budgetModal")}
        >
          <div className="modal-header">
            <h6 className="modal-title" id="modal-title-notification">
              <Trans>Your attention is required</Trans>
            </h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggleModal("budgetModal")}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="py-3 text-center">
              <i className="ni ni-bell-55 ni-3x" />
              <h4 className="heading mt-4">
                <Trans>Campaign Budget Exceeded</Trans>!
              </h4>

              <p>{budgetErr}</p>
            </div>
          </div>
          <div className="modal-footer">
            <Button
              className="btn-white"
              color="default"
              type="button"
              onClick={(e) => this.bookInventory(e, true)}
            >
              <Trans>Continue With New Budget</Trans>
            </Button>

            <Button
              className="text-white ml-auto"
              color="link"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggleModal("budgetModal")}
            >
              <Trans>Remove Inventory</Trans>
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = ({ inv, camps }) => {
  // console.log('inv',inv)
  const {
    inventory,
    networks,
    nearby_places,
    nearby_data,
    fetching_booked,
    total_count,
    booking_err,
  } = inv;

  const { camp_details } = camps;
  return {
    nearby_places,
    nearby_data,
    inventory,
    total_count,
    networks,
    camp_details,
    fetching_booked,
    booking_err,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchSuggInventory,
      fetchNetworks,
      fetchCampDetails,
      fetchNearby,
      bookInventory,
      resetSuggInventory,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SuggInventory);
