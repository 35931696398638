import React from "react";
import { Trans } from "react-i18next";
import { Container, Card, CardBody, Row, Col, CardTitle } from "reactstrap";

const MapInfoBox = (props) => {
	console.log("mpro", props);
	const { media_owner, city, locality, site_code, site_map } =
		props.infoBoxDetails;
	const image_url = site_map;
	return (
		<Container style={{ width: "350px", height: "auto" }}>
			<Card>
				<CardBody className="">
					<CardTitle>Site Details {site_code}</CardTitle>
					<Row>
						<Col>
							<img
								style={{ cursor: "pointer", margin: 5, width: "100%" }}
								alt={image_url}
								src={image_url}
							/>
						</Col>
					</Row>

					<Row
						style={{
							padding: "0.5em",
							border: "1px solid #009AEF",
							marginLeft: "0px",
							marginRight: "0px",
						}}
					>
						<Col sm="6">
							<strong>
								<Trans>Media Owner</Trans>:
							</strong>
						</Col>
						<Col>{media_owner ? media_owner : "Not Available"}</Col>
					</Row>
					<Row
						style={{
							padding: "0.5em",
							border: "1px solid #009AEF",
							marginLeft: "0px",
							marginRight: "0px",
						}}
					>
						<Col sm="6">
							<strong>
								<Trans>Location</Trans>:
							</strong>
						</Col>
						<Col>{locality ? locality : "Not Available"}</Col>
					</Row>
					<Row
						style={{
							padding: "0.5em",
							border: "1px solid #009AEF",
							marginLeft: "0px",
							marginRight: "0px",
						}}
					>
						<Col sm="6">
							<strong>
								<Trans>City</Trans>:
							</strong>
						</Col>
						<Col>{city ? city : <Trans>Not Available</Trans>}</Col>
					</Row>
					{/* <Table className="mt-1" bordered striped>
                    <tbody>
                         <tr>
                            <th>Media Owner</th>
                            <td>{media_owner ? media_owner : "Not Available"}</td> */}
					{/* <th>Media Type</th>
                            <td>{media_type ? media_type : "Not Available"}</td> */}
					{/* </tr> */}
					{/* <tr>
                            <th>Latitude</th>
                            <td>{latitude ? latitude : "Not Available"}</td>
                            <th>Longitude</th>
                            <td>{longitude ? longitude : "Not Available"}</td>
                        </tr>
                        <tr>
                            <th>City</th>
                            <td>{city ? city : "Not Available"}</td>
                            <th>State</th>
                            <td>{state ? state : "Not Available"}</td>
                        </tr>

                        <tr>
                            <th>Locality</th>
                            <td>{locality ? locality : "Not Available"}</td>
                            <th>Vendor</th>
                            <td>{vendor ? vendor : "Not Available"}</td>
                        </tr>

                        <tr>
                            <th>SEC</th>
                            <td>{sec_classification ? sec_classification.toString() : "Not Available"}</td>
                        
                        </tr> */}

					{/* </tbody>
                </Table> */}
				</CardBody>
			</Card>
		</Container>
	);
};

export default MapInfoBox;
