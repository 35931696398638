import React from "react";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import CustomStore from "devextreme/data/custom_store";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import DataGrid, {
	Column,
	Selection,
	Paging,
	Pager,
	SearchPanel,
} from "devextreme-react/data-grid";
import { devUrl } from "../../utility";
import axios from "axios";
import { numberWithCommas } from "helpers/helpers";
import { Trans } from "react-i18next";
import {Modal, CardBody, Table } from "reactstrap";
import Moment from "react-moment";
import { fetchSuggInventory } from "../../actions/invActions";

class RemoteReactGridInventory extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			autoExpandAll: true,
			allMode: "allPages",
			checkBoxesMode: "onClick",
			selectionFilter: [],
			selectedRowKeys: [],
			selectedRows: [],
			allInvIds: [],
			dataSource: null,
			allSelected: false,
			modal1: false
		};

		this.onCheckBoxesModeChanged = this.onCheckBoxesModeChanged.bind(this);
		this.onAllModeChanged = this.onAllModeChanged.bind(this);
		this.onAutoExpandAllChanged = this.onAutoExpandAllChanged.bind(this);
		this.onSelectChange = this.onSelectChange.bind(this);
		this.handleOptionChange = this.handleOptionChange.bind(this);
		this.setMapSelected = this.setMapSelected.bind(this);
		this.toggleModal = this.toggleModal.bind(this);
		this.renderBookedCampsButton = this.renderBookedCampsButton.bind(this);
	}

	componentDidMount() {
		if (this.state.dataSource == null) {
			this.gridDataSource();
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.filterChanges === 0 && this.state.dataSource == null) {
			this.gridDataSource();
		}
		if (prevProps.filterChanges !== this.props.filterChanges) {
			this.gridDataSource();
		}
	}

	onCheckBoxesModeChanged({ value }) {
		this.setState({ checkBoxesMode: value });
	}

	onAllModeChanged({ value }) {
		this.setState({ allMode: value });
	}

	onSelectChange(data) {
		const { selectedRowsData, currentDeselectedRowKeys } = data;

		const { setSelectedInventory } = this.props;
		const { selectedRowKeys } = this.state;
		const selRowsIds = selectedRowsData.map((s) => s._id);
		var updatedRows;
		if (currentDeselectedRowKeys.length > 0) {
			updatedRows = selectedRowKeys.filter(
				(s) => currentDeselectedRowKeys.indexOf(s) === -1
			);
		} else {
			updatedRows = _.union(selectedRowKeys, selRowsIds);
		}

		var selIds = updatedRows.map((s) => {
			return { _id: s };
		});
		const selKeys = updatedRows.map((s) => s);

		this.setState({ selectedRowKeys: selKeys, selectedRows: selRowsIds });
		setSelectedInventory(selIds);
	}

	handleOptionChange(e) {
		const { setSelectedInventory, selectedInv } = this.props;
		const { allInvIds } = this.state;
		if (e.fullName === "dataSource" && selectedInv) {
			var selFilter = [];
			if (selectedInv.length === 1) {
				selFilter = ["id", "=", selectedInv[0]["_id"]];
			} else {
				selectedInv.forEach((s, i) => {
					var arr = [];
					arr[0] = "id";
					arr[1] = "=";
					arr[2] = s._id;
					selFilter.push(arr);
					if (i < selectedInv.length - 1) {
						selFilter.push("or");
					}
				});
			}

			this.setState({ selectionFilter: selFilter });
		}

		if (e.fullName === "selectionFilter") {
			this.setState({
				selectionFilter: e.value,
			});

			if (e.value == null) {
				// console.log("select all",allInvIds)
				var allRows = allInvIds.map((a) => {
					return { _id: a };
				});
				setSelectedInventory(allRows);
			} else if (e.value && e.value.length === 0) {
				setSelectedInventory([]);
			} else {
				var selectedRows = [];
				selFilter = e.value;
				if (selFilter[0] === "id") {
					selectedRows.push(selFilter[2]);
				} else if (selFilter[0] === "!") {
					selectedRows = selectedRows.filter((s) => s !== selFilter[1][2]);
				} else {
					selFilter.forEach((s) => {
						if (s[0] === "id") {
							selectedRows.push(s[2]);
						}
						if (s[0] === "!") {
							selectedRows = selectedRows.filter((sr) => sr !== s[1][2]);
						}
					});
				}

				var fSelectedRows = selectedRows.map((s) => {
					return { _id: s };
				});
				setSelectedInventory(fSelectedRows);
			}
		}
	}

	gridDataSource() {
		// console.log("hola mofo=========")

		const { campId, filterObj, updateTotalInventory, simNetworks } = this.props;

		const store = new CustomStore({
			key: "id",
			load: (loadOptions) => {
				let query = {
					campId: campId,
					filters: filterObj,
					networks: simNetworks,
				};

				return axios
					.post(`${devUrl}/v2/fetch_sugg_inventory_map`, query, {
						headers: { authorization: localStorage.getItem("plano_token") },
					})
					.then((response) => {
						// console.log('remote data grid',response);
						const data = response.data.plano_inventory;
						const total = response.data.total_count;
						const invIds = response.data.inv_ids;
						updateTotalInventory(total);
						this.setState({ allInvIds: invIds });
						return {
							data: data,
							totalCount: total,
						};
					})
					.catch((error) => {});
			},
		});

		this.setState({ dataSource: store });
	}

	setMapSelected(e) {
		const { selectedInv } = this.props;
		var selKeys = selectedInv.map((s) => s._id);

		this.setState({ selectedRowKeys: selKeys });
	}

	toggleModal(data) {
		this.setState({modal1: !this.state.modal1});
		if(data && data.data.booked_camps && data.data.booked_camps.length>0) this.setState({booked_camps: data.data})
	}

	renderBookedCampsButton(data) {
		//console.log("toggleModal data", data.data)
		if(data.data.location==='Av. Las Condes 8231 / Los Militares - Orientación: Varias') console.log("toggleModal data", data.data)
		if(data.data.booked_camps && data.data.booked_camps.length>0) {
		return (
			<button
					  //style={{ height: "40px" }}
					  className="btn btn-danger btn-sm"
					  onClick={() => this.toggleModal(data)}
						>
							<Trans>Status</Trans>
						</button>
		);
		}
		else return 'N.A.';
	}

	render() {
		const { pColumns } = this.props;
		const { dataSource } = this.state;

		return (
			<div>
				<DataGrid
					dataSource={dataSource}
					allowColumnReordering={true}
					showBorders={true}
					selectionFilter={this.state.selectionFilter}
					onOptionChanged={this.handleOptionChange}
					selectedRowKeys={this.state.selectedRowKeys}
					allowColumnResizing={true}
					columnAutoWidth={true}
				>
					<Selection
						mode="multiple"
						allowSelectAll={true}
						deferred={true}
						selectAllMode={"allPages"}
						showCheckBoxesMode="always"
					/>
					{/* <GroupPanel visible={true} /> */}
					<SearchPanel visible={true} />
					<Paging defaultPageSize={10} />
					<Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50]} />
					{pColumns.map((p, i) => {
						if (i === 0) {
							return <Column caption={p.title} dataField={p.name} dataType="string" key={i} />;
						}

						if (p.name === "inventory_cost") {
							return (
								<Column
									dataField={p.name}
									caption={p.title}
									cellRender={(data) => numberWithCommas(data.value)}
									key={i}
								/>
							);
						}

						if (p.name === "booked_camps") {
							return (
								<Column
									//dataField={p.name}
									caption={p.title}
									cellRender={(data) => this.renderBookedCampsButton(data)}
									key={i}
								/>
							);
						}

						return (
							<Column
								dataField={p.name}
								caption={p.title}
								dataType="string"
								key={i}
							/>
						);
					})}
				</DataGrid>
				<Modal
							className="modal-dialog-centered"
							isOpen={this.state.modal1}
							toggle={() => this.toggleModal()}
							size="lg"
						>
							<div
								className="modal-header text-center"
								style={{ background: "#fefefe" }}
							>
								<h3 className="modal-title" id="exampleModalLabel">
									<Trans>Site Code</Trans>:&nbsp;{this.state.booked_camps ? this.state.booked_camps.site_code: null},&nbsp;<Trans>Booked Campaign Details</Trans>
								</h3>
							</div>
							<div className="modal-body">
								<CardBody className="px-lg-2 py-lg-2" style={{maxWidth: '752px', overflowX: 'scroll'}}>
									<Table>
											<thead>
											  <tr>
												
												<th><Trans>Campaign Name</Trans></th>
												<th><Trans>Start Date</Trans></th>
												<th><Trans>End Date</Trans></th>
												<th><Trans>States</Trans></th>
												<th><Trans>Cities</Trans></th>
												<th><Trans>Media Types</Trans></th>
												{/* <th><Trans>Budget</Trans></th>
												<th><Trans>Budget Used</Trans></th> */}
											  </tr>
											</thead>
											<tbody>
											{this.state.booked_camps && this.state.booked_camps.booked_camps && this.state.booked_camps.booked_camps.length>0 && this.state.booked_camps.booked_camps.map(b => {
										return (
											<>
											  <tr>
												<td>{b.name}</td>
												<td><Moment format="MMMM Do YYYY">{b.start_date}</Moment></td>
												<td><Moment format="MMMM Do YYYY">{b.end_date}</Moment></td>
												<td>{b.states ? b.states.toString(): null}</td>
												<td>{b.cities ? b.cities.toString(): null}</td>
												<td>{b.media_types ? b.media_types.toString(): null}</td>
												{/* <td>{b.budget}</td>
												<td>{b.budget_used}</td> */}
											  </tr>
											  
										 </>
											  )
											  })}
											</tbody>
										  </Table>
									
								</CardBody>
							</div>
							<div className="modal-footer">
								
								{/* <Button
									block
									color="primary"
									type="button"
									onClick={this.isValid}
								>
									{adding_camp ? "Creating..." : "Create New"}
								</Button> */}
							</div>
						</Modal>
			</div>
		);
	}

	onAutoExpandAllChanged() {
		this.setState({
			autoExpandAll: !this.state.autoExpandAll,
		});
	}
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			fetchSuggInventory,
		},
		dispatch
	);

const mapStateToProps = ({ inv }) => {
	const { inventory, nearby_places, nearby_data, total_count } = inv;
	return {
		nearby_places,
		nearby_data,
		inventory,
		total_count,
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(RemoteReactGridInventory);
