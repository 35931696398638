import React from "react";
import { Modal, Card, Button, CardBody, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, CardHeader } from "reactstrap";
import _ from "lodash";
import Select, { createFilter } from "react-select";
import { Trans, Translation } from "react-i18next";

class EditMemberModal extends React.Component {
	state = {
		uPolicies: null,
		role_name: null,
		uRole: null,
		uBusUnits: null,
		uSharedUsers: null,
		formData: {},
		errors: {},
	};

	formHandler = (e) => {
		const target = e.target;
		const value = target.value;
		const field = target.name;
		const { mFormHandler } = this.props;
		mFormHandler(field, value);
	};

	submitForm = () => {
		const { editMember } = this.props;
		editMember();
		this.setState({ uBusUnits: null, uSharedUsers: null });
	};

	handlePolicyChange = (val) => {
		// var errors = {...this.state.errors}
		//  errors['media_types'] =null;
		this.setState({ uPolicies: val });
	};

	handleRoleChange = (val) => {
		this.setState({ uRole: val });
		const { mFormHandler } = this.props;
		mFormHandler("role", { _id: val.value });
	};

	handleBusinessUnits = (val) => {
		var bsUnits = [];
		if (val) {
			bsUnits = val;
		}
		this.setState({ uBusUnits: bsUnits });
		const { mFormHandler } = this.props;
		mFormHandler("business_units", bsUnits);
	};

	handleSharedUsers = (val) => {
		var sUsers = [];
		if (val) {
			sUsers = val;
		}
		this.setState({ uSharedUsers: sUsers });
		const { mFormHandler } = this.props;
		mFormHandler("shared_users", sUsers);
	};

	render() {
		const { show, roles, closeEditMemberModal, edit_member_modal_data, edit_member_errors, members } = this.props;
		const { uRole, uBusUnits, uSharedUsers } = this.state;

		if (!show) {
			return null;
		}

		const { name, email, phone, role, business_units, shared_users } = edit_member_modal_data;

		var allRoles = [];
		if (!_.isEmpty(roles)) {
			allRoles = roles.map((c) => {
				return { label: c.name, value: c._id };
			});
		}

		// var fPolicies=[];
		// if(!_.isEmpty(policies)){
		//     fPolicies = policies.map((c) => {return {label:c.name,value:c._id}})
		// }

		const fRole = { label: role.name, value: role._id };
		const fBusUnits = business_units
			? business_units.map((b) => {
					return { label: b, value: b };
			  })
			: [];
		const fSharedUsers =
			shared_users && shared_users.length > 0
				? shared_users.map((b) => {
						return { label: b.email, value: b._id };
				  })
				: [];

		var all_business_units = ["HAVAS", "Mediawave", "Mediared"];
		all_business_units = all_business_units.map((c) => {
			return { label: c, value: c };
		});

		var all_shared_users = [];
		if (members && members.length > 0) {
			members.forEach((m) => {
				all_shared_users.push({ label: m.email, value: m._id });
			});
		}
		return (
			<Modal className="modal-dialog-centered" size="sm" isOpen={show} toggle={() => closeEditMemberModal()}>
				<div className="modal-body p-0">
					<Card className="bg-secondary shadow border-0">
						<CardHeader className="bg-transparent pb-2">
							<div className="modal-header">
								<h4 className="modal-title" id="modal-title-default">
									<Trans>Edit Member</Trans>
								</h4>
								<button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => closeEditMemberModal()}>
									<span aria-hidden={true}>×</span>
								</button>
							</div>
						</CardHeader>
						<CardBody className="px-lg-3 py-lg-3">
							<Form role="form">
								<FormGroup className="mb-3">
									<InputGroup className="input-group-alternative">
										<InputGroupAddon addonType="prepend">
											<InputGroupText>
												<i className="ni ni-single-02" />
											</InputGroupText>
										</InputGroupAddon>
										<Translation>
											{(t) => <Input name="name" placeholder={t("Name")} type="text" onChange={this.formHandler} value={name} />}
										</Translation>
									</InputGroup>
									{edit_member_errors.name && (
										<small style={{ color: "#f5365c" }}>
											<span className="fa fa-exclamation-circle"> </span>
											&nbsp; {edit_member_errors.name}
										</small>
									)}
								</FormGroup>
								<FormGroup className="mb-3">
									<InputGroup className="input-group-alternative">
										<InputGroupAddon addonType="prepend">
											<InputGroupText>
												<i className="ni ni-email-83" />
											</InputGroupText>
										</InputGroupAddon>
										<Translation>
											{(t) => <Input name="email" placeholder={t("Email")} type="email" onChange={this.formHandler} value={email} />}
										</Translation>
									</InputGroup>
									{edit_member_errors.email && (
										<small style={{ color: "#f5365c" }}>
											<span className="fa fa-exclamation-circle"> </span>
											&nbsp; {edit_member_errors.email}
										</small>
									)}
								</FormGroup>
								<FormGroup className="mb-3">
									<InputGroup className="input-group-alternative">
										<InputGroupAddon addonType="prepend">
											<InputGroupText>
												<i className="ni ni-mobile-button" />
											</InputGroupText>
										</InputGroupAddon>
										<Translation>
											{(t) => <Input name="phone" placeholder={t("Phone")} type="phone" onChange={this.formHandler} value={phone} />}
										</Translation>
									</InputGroup>
									{edit_member_errors.phone && (
										<small style={{ color: "#f5365c" }}>
											<span className="fa fa-exclamation-circle"> </span>
											&nbsp; {edit_member_errors.phone}
										</small>
									)}
								</FormGroup>

								<FormGroup>
									<Translation>
										{(t) => (
											<Select
												placeholder={t("Role")}
												className="input-group-alternative"
												filterOption={createFilter({ ignoreAccents: false })} // this makes all the difference!
												options={allRoles}
												value={uRole !== null ? uRole : fRole}
												onChange={this.handleRoleChange}
											/>
										)}
									</Translation>

									{edit_member_errors.role && (
										<small style={{ color: "#f5365c" }}>
											<span className="fa fa-exclamation-circle"> </span>
											&nbsp; {edit_member_errors.role}
										</small>
									)}
								</FormGroup>
								<FormGroup>
									<Translation>
										{(t) => (
											<Select
												isMulti
												name="business_units"
												placeholder={t("Business Units")}
												className="input-group-alternative"
												filterOption={createFilter({ ignoreAccents: false })} // this makes all the difference!
												options={all_business_units}
												value={uBusUnits !== null ? uBusUnits : fBusUnits}
												onChange={this.handleBusinessUnits}
											/>
										)}
									</Translation>

									{edit_member_errors.business_units && (
										<small style={{ color: "#f5365c" }}>
											<span className="fa fa-exclamation-circle"> </span>
											&nbsp; {edit_member_errors.business_units}
										</small>
									)}
								</FormGroup>
								<FormGroup>
									<Select
										isMulti
										name="shared_users"
										placeholder="Shared Users"
										className="input-group-alternative"
										filterOption={createFilter({ ignoreAccents: false })} // this makes all the difference!
										options={all_shared_users}
										value={uSharedUsers !== null ? uSharedUsers : fSharedUsers}
										onChange={this.handleSharedUsers}
									/>
									<small style={{ color: "#464749" }}> (optional)</small>
								</FormGroup>
								<div className="text-center">
									<Button block className="my-4" color="primary" type="button" onClick={this.submitForm}>
										<Trans>Update</Trans>
									</Button>
								</div>
							</Form>
						</CardBody>
					</Card>
				</div>
			</Modal>
		);
	}
}

export default EditMemberModal;
