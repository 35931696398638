import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Switch, Redirect } from "react-router-dom";

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";

import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import OpenLayout from "layouts/Open.js";
import { Provider } from "react-redux";
import configureStore from "./store";
import AuthRoute from "views/auth/AuthRoute";
import "./i18n";
import "./assets/css/custom.css";

ReactDOM.render(
	<Provider store={configureStore()}>
		<BrowserRouter>
			<Switch>
				<AuthRoute
					type="private"
					path="/admin"
					render={(props) => <AdminLayout {...props} />}
				/>
				<AuthRoute
					type="guest"
					path="/auth"
					render={(props) => <AuthLayout {...props} />}
				/>
				<AuthRoute
					type="open"
					path="/open"
					render={(props) => <OpenLayout {...props} />}
				/>
				<Redirect from="/" to="/auth/login" />
			</Switch>
		</BrowserRouter>
	</Provider>,
	document.getElementById("root")
);
