import React from "react";
import {
	Modal,
	Card,
	Button,
	CardBody,
	Form,
	FormGroup,
	Input,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	CardHeader,
} from "reactstrap";
import _ from "lodash";
import Select, { createFilter } from "react-select";
import { Trans, Translation } from "react-i18next";

class EditRoleModal extends React.Component {
	state = {
		uPolicies: null,
		role_name: null,
		errors: {},
	};

	roleHandler = (e) => {
		const target = e.target;
		const role_name = target.value;
		this.setState({ role_name: role_name });
	};

	submitForm = () => {
		const { editRole } = this.props;
		const { role_name, uPolicies } = this.state;
		editRole(role_name, uPolicies);
	};

	handlePolicyChange = (val) => {
		// var errors = {...this.state.errors}
		//  errors['media_types'] =null;
		this.setState({ uPolicies: val });
	};

	render() {
		const {
			show,
			all_policies,
			closeEditRoleModal,
			edit_modal_data,
			edit_role_errors,
		} = this.props;
		const { role_name, uPolicies } = this.state;

		if (!show) {
			return null;
		}

		const { name, policies } = edit_modal_data;

		var allPolicies = [];
		if (!_.isEmpty(all_policies)) {
			allPolicies = all_policies.map((c) => {
				return { label: c.name, value: c._id };
			});
		}

		var fPolicies = [];
		if (!_.isEmpty(policies)) {
			fPolicies = policies.map((c) => {
				return { label: c.name, value: c._id };
			});
		}

		return (
			<Modal
				className="modal-dialog-centered"
				size="sm"
				isOpen={show}
				toggle={() => closeEditRoleModal()}
			>
				<div className="modal-body p-0">
					<Card className="bg-secondary shadow border-0">
						<CardHeader className="bg-transparent pb-2">
							<div className="modal-header">
								<h4 className="modal-title" id="modal-title-default">
									<Trans>Edit Role</Trans>
								</h4>
								<button
									aria-label="Close"
									className="close"
									data-dismiss="modal"
									type="button"
									onClick={() => closeEditRoleModal()}
								>
									<span aria-hidden={true}>×</span>
								</button>
							</div>
						</CardHeader>
						<CardBody className="px-lg-3 py-lg-3">
							<Form role="form">
								<FormGroup className="mb-3">
									<InputGroup className="input-group-alternative">
										<InputGroupAddon addonType="prepend">
											<InputGroupText>
												<i className="ni ni-email-83" />
											</InputGroupText>
										</InputGroupAddon>
										<Translation>
											{(t) => (
												<Input
													placeholder={t("Role Name")}
													type="text"
													onChange={this.roleHandler}
													value={role_name !== null ? role_name : name}
												/>
											)}
										</Translation>
									</InputGroup>
									{edit_role_errors.role_name && (
										<small style={{ color: "#f5365c" }}>
											<span className="fa fa-exclamation-circle"> </span>
											&nbsp; {edit_role_errors.role_name}
										</small>
									)}
								</FormGroup>
								<FormGroup>
									<Translation>
										{(t) => (
											<Select
												isMulti
												placeholder={t("Attach Policies")}
												className="input-group-alternative"
												filterOption={createFilter({ ignoreAccents: false })} // this makes all the difference!
												options={allPolicies}
												value={uPolicies !== null ? uPolicies : fPolicies}
												onChange={this.handlePolicyChange}
											/>
										)}
									</Translation>

									{edit_role_errors.policies && (
										<small style={{ color: "#f5365c" }}>
											<span className="fa fa-exclamation-circle"> </span>
											&nbsp; {edit_role_errors.policies}
										</small>
									)}
								</FormGroup>
								<div className="text-center">
									<Button
										block
										className="my-4"
										color="primary"
										type="button"
										onClick={this.submitForm}
									>
										<Trans>Update</Trans>
									</Button>
								</div>
							</Form>
						</CardBody>
					</Card>
				</div>
			</Modal>
		);
	}
}

export default EditRoleModal;
