import React from "react";
import { Trans } from "react-i18next";
import { Card, Row, CardBody, Col, CardHeader } from "reactstrap";

const Networks = (props) => {
  const { networks, show, selNetworks, networkChange } = props;

  return (
    <Card className={show ? "shadow mb-sm-3" : "d-none"}>
      <CardHeader>
        <Row>
          <Col xs="11">
            <h3>
              <Trans>Networks</Trans>
            </h3>
          </Col>
        </Row>
      </CardHeader>

      {/* <Collapse isOpen={networkCollapse}> */}
      <CardBody style={{ padding: "2rem" }}>
        <Row>
          {networks &&
            networks.map((n, i) => {
              if (i % 4 === 0 && i !== 0) {
                // console.log("network", n);
                return (
                  <div className="w-100" key={i}>
                    <Col
                      className="custom-control custom-checkbox mb-4"
                      key={i}
                    >
                      <input
                        //disabled
                        className="custom-control-input"
                        id={"network" + i}
                        name={n}
                        type="checkbox"
                        checked={selNetworks.get(n)}
                        onChange={networkChange}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor={"network" + i}
                      >
                        <Trans>{n}</Trans>
                      </label>
                    </Col>
                  </div>
                );
              }
              return (
                <Col className="custom-control custom-checkbox mb-2" key={i}>
                  <input
                    //disabled
                    className="custom-control-input"
                    id={"network" + i}
                    name={n}
                    type="checkbox"
                    checked={selNetworks.get(n)}
                    onChange={networkChange}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor={"network" + i}
                  >
                    <Trans>{n}</Trans>
                  </label>
                </Col>
              );
            })}
        </Row>
      </CardBody>
      {/* </Collapse> */}
    </Card>
  );
};
export default Networks;
