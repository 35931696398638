import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Card,
  Row,
  CardBody,
  Col,
  CardHeader,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  Input,
  InputGroupText,
  Button,
} from "reactstrap";
import Select, { createFilter } from "react-select";
import CompHeader from "components/Headers/CompHeader.js";
import {
  fetchCampDetails,
  fetchDistinctFilters,
  updateCampaign,
  resetCampUpdate,
} from "../../actions/campActions";
import ReactDatetime from "react-datetime";
import { validateForm } from "../../helpers/validations";
import _ from "lodash";
import moment from "moment";
import { nFormatter } from "../../helpers/helpers";
import { Trans } from "react-i18next";

const distMediaTypes = [
  { label: "All", value: "all" },
  { label: "Traditional OOH", value: "ooh" },
  { label: "Digital OOH", value: "digital" },
];

const EditCampaign = (props) => {
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});

  const dispatch = useDispatch();
  const { camp_details, filters, updating_camp } = useSelector(
    (state) => state.camps
  );

  useEffect(() => {
    const { campId } = props.match.params;
    dispatch(fetchCampDetails({ campId }));
  }, [dispatch, props.match.params]);

  useEffect(() => {
    if (camp_details) {
      let campDetails = Object.assign({}, camp_details);
      campDetails.start_date = moment(camp_details.start_date).format("ll");
      campDetails.end_date = moment(camp_details.end_date).format("ll");

      campDetails.media_type = distMediaTypes.filter(
        (item) => camp_details.media_type === item.value
      );

      campDetails.states = camp_details.states.map((s) => {
        return { label: s, value: s };
      });
      campDetails.cities = camp_details.cities.map((c) => {
        return { label: c, value: c };
      });
      campDetails = _.pick(campDetails, [
        "brand",
        "name",
        "media_type",
        "start_date",
        "end_date",
        "budget",
        "states",
        "cities",
      ]);
      setFormData(campDetails);

      const fields = [
        { type: "dependent", val: "city" },
        { type: "original", val: "state" },
      ];
      // const states = val ? val.map((v) => v.label):[]
      const query = { state: { $in: camp_details.states } };
      dispatch(fetchDistinctFilters({ fields: fields, query: query }));
    }
  }, [dispatch, camp_details]);

  useEffect(() => {
    if (updating_camp === "success") {
      props.history.push("/admin/campaigns");
    }
    return () => {
      dispatch(resetCampUpdate());
    };
  }, [dispatch, props.history, updating_camp]);

  const validDate = (current) => {
    var yesterday = moment().subtract(1, "day");
    return current.isAfter(yesterday);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    const key = e.target.name;

    let uFormData = Object.assign({}, formData);
    uFormData[key] = value;
    setFormData(uFormData);
  };

  const updateCamp = () => {
    // console.log('ud form', formData)
    const {
      brand,
      name,
      start_date,
      end_date,
      budget,
      cities,
      media_type,
      states,
    } = formData;

    var errors = validateForm([
      { field: "brand", value: brand, validations: ["Required"] },
      { field: "name", value: name, validations: ["Required"] },
      { field: "start_date", value: start_date, validations: ["ValidDate"] },
      { field: "end_date", value: end_date, validations: ["ValidDate"] },
      { field: "budget", value: budget, validations: ["Required"] },
      { field: "states", value: states, validations: ["Required"] },
      { field: "cities", value: cities, validations: ["Required"] },
      { field: "media_type", value: media_type, validations: ["Required"] },
    ]);

    if (
      !errors["end_date"] &&
      !errors["start_date"] &&
      moment(start_date).isAfter(moment(end_date))
    ) {
      errors["end_date"] = "End Date should be after Start Date";
    }

    if (!_.isEmpty(errors)) {
      setFormErrors(errors);
    } else {
      console.log("update api hit");
      const { campId } = props.match.params;
      dispatch(updateCampaign({ campId: campId, data: formData }));
    }
  };

  const handleFieldChange = (key, val) => {
    // console.log("key",key,'val',val,' formData',formData)
    // var errors = Object.assign({},formErrors)
    var uFormData = Object.assign({}, formData);
    // errors[key] = null;

    if (key === "states") {
      const fields = [
        { type: "dependent", val: "city" },
        { type: "original", val: "state" },
      ];
      const states = val ? val.map((v) => v.label) : [];
      const query = { state: { $in: states } };
      dispatch(fetchDistinctFilters({ fields: fields, query: query }));
    }

    // setFormErrors(errors);

    uFormData[key] = val;
    // console.log('formData',uFormData)
    setFormData(uFormData);
    // this.setState({[key]:val, errors:errors})
  };

  //   console.log('formData1',formData,' filters',filters)

  const {
    brand,
    name,
    start_date,
    end_date,
    budget,
    media_type,
    states,
    cities,
  } = formData;

  let distStates, distCities;

  if (!_.isEmpty(filters)) {
    distStates = filters.state
      ? filters.state.map((m) => {
          return { label: m, value: m };
        })
      : [];
    distCities = [{ label: "All", value: "all" }];
    distCities = filters.city
      ? distCities.concat(
          filters.city.map((m) => {
            return { label: m, value: m };
          })
        )
      : [];
  }

  return (
    <>
      <CompHeader />
      <Container>
        <Row className="mt-5">
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0" style={{ textAlign: "center" }}>
                      <Trans>Edit Campaign</Trans>
                    </h3>
                  </div>
                </Row>
              </CardHeader>
              <CardBody style={{ display: "flex", justifyContent: "center" }}>
                <Col xl="9">
                  <Form role="form">
                    <FormGroup className="mb-3">
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon
                          addonType="prepend"
                          style={{ minWidth: "20%" }}
                        >
                          <InputGroupText>
                            <Trans>Brand</Trans>:
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Brand"
                          type="text"
                          name="brand"
                          onChange={handleInputChange}
                          value={brand}
                        />
                      </InputGroup>
                      {formErrors.brand && (
                        <small style={{ color: "#f5365c" }}>
                          {" "}
                          <span className="fa fa-exclamation-circle">
                            {" "}
                          </span>{" "}
                          {formErrors.brand}
                        </small>
                      )}
                    </FormGroup>

                    <FormGroup className="mb-3">
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon
                          addonType="prepend"
                          style={{ minWidth: "20%" }}
                        >
                          <InputGroupText>
                            <Trans>Campaign</Trans>:
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Campaign Name"
                          type="text"
                          name="name"
                          onChange={handleInputChange}
                          value={name}
                        />
                      </InputGroup>
                      {formErrors.name && (
                        <small style={{ color: "#f5365c" }}>
                          {" "}
                          <span className="fa fa-exclamation-circle">
                            {" "}
                          </span>{" "}
                          {formErrors.name}
                        </small>
                      )}
                    </FormGroup>

                    <FormGroup className="mb-3">
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon
                          addonType="prepend"
                          style={{ minWidth: "20%" }}
                        >
                          <InputGroupText>
                            <Trans>Start Date</Trans>:
                          </InputGroupText>
                        </InputGroupAddon>
                        <ReactDatetime
                          inputProps={{
                            placeholder: "Start Date",
                          }}
                          timeFormat={false}
                          dateFormat={"ll"}
                          onChange={(e) => handleFieldChange("start_date", e)}
                          value={start_date}
                          isValidDate={validDate}
                          closeOnSelect={true}
                        />
                      </InputGroup>
                      {formErrors.start_date && (
                        <small style={{ color: "#f5365c" }}>
                          {" "}
                          <span className="fa fa-exclamation-circle">
                            {" "}
                          </span>{" "}
                          {formErrors.start_date}
                        </small>
                      )}
                    </FormGroup>
                    <FormGroup className="mb-3">
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon
                          addonType="prepend"
                          style={{ minWidth: "20%" }}
                        >
                          <InputGroupText>
                            <Trans>End Date</Trans>:
                          </InputGroupText>
                        </InputGroupAddon>
                        <ReactDatetime
                          inputProps={{
                            placeholder: "End Date",
                          }}
                          timeFormat={false}
                          dateFormat={"ll"}
                          onChange={(e) => handleFieldChange("end_date", e)}
                          value={end_date}
                          isValidDate={validDate}
                          closeOnSelect={true}
                        />
                      </InputGroup>
                      {formErrors.end_date && (
                        <small style={{ color: "#f5365c" }}>
                          {" "}
                          <span className="fa fa-exclamation-circle">
                            {" "}
                          </span>{" "}
                          {formErrors.end_date}
                        </small>
                      )}
                    </FormGroup>
                    <FormGroup className="mb-3">
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon
                          addonType="prepend"
                          style={{ minWidth: "20%" }}
                        >
                          <InputGroupText>
                            <Trans>Budget</Trans>:
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Budget"
                          type="number"
                          name="budget"
                          onChange={handleInputChange}
                          value={budget}
                        />
                        <InputGroupAddon
                          addonType="postpend"
                          style={{ minWidth: "20%" }}
                        >
                          <InputGroupText>
                            <span style={{ fontWeight: "bold" }}>
                              {nFormatter(budget, 1)}
                            </span>
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                      {formErrors.budget && (
                        <small style={{ color: "#f5365c" }}>
                          {" "}
                          <span className="fa fa-exclamation-circle">
                            {" "}
                          </span>{" "}
                          {formErrors.budget}
                        </small>
                      )}
                    </FormGroup>
                    <FormGroup className="mb-3">
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon
                          addonType="prepend"
                          style={{ minWidth: "20%" }}
                        >
                          <InputGroupText>
                            <Trans>Media Types</Trans>:
                          </InputGroupText>
                        </InputGroupAddon>
                        <Select
                          placeholder="Media Type"
                          className="input-group-alternative select"
                          filterOption={createFilter({ ignoreAccents: false })} // this makes all the difference!
                          options={distMediaTypes}
                          onChange={(e) => handleFieldChange("media_type", e)}
                          value={media_type}
                        />
                      </InputGroup>
                      {formErrors.media_types && (
                        <small style={{ color: "#f5365c" }}>
                          {" "}
                          <span className="fa fa-exclamation-circle">
                            {" "}
                          </span>{" "}
                          {formErrors.media_types}
                        </small>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon
                          addonType="prepend"
                          style={{ minWidth: "20%" }}
                        >
                          <InputGroupText>
                            <Trans>States</Trans>:
                          </InputGroupText>
                        </InputGroupAddon>
                        <Select
                          isMulti
                          placeholder="States"
                          className="input-group-alternative select"
                          filterOption={createFilter({ ignoreAccents: false })} // this makes all the difference!
                          options={distStates}
                          onChange={(e) => handleFieldChange("states", e)}
                          value={states}
                        />
                      </InputGroup>
                      {formErrors.states && (
                        <small style={{ color: "#f5365c" }}>
                          {" "}
                          <span className="fa fa-exclamation-circle">
                            {" "}
                          </span>{" "}
                          {formErrors.states}
                        </small>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon
                          addonType="prepend"
                          style={{ minWidth: "20%" }}
                        >
                          <InputGroupText>
                            <Trans>Cities:</Trans>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Select
                          isMulti
                          placeholder="Cities"
                          className="input-group-alternative select"
                          filterOption={createFilter({ ignoreAccents: false })} // this makes all the difference!
                          options={distCities}
                          onChange={(e) => handleFieldChange("cities", e)}
                          value={cities}
                        />
                      </InputGroup>
                      {formErrors.cities && (
                        <small style={{ color: "#f5365c" }}>
                          {" "}
                          <span className="fa fa-exclamation-circle">
                            {" "}
                          </span>{" "}
                          {formErrors.cities}
                        </small>
                      )}
                    </FormGroup>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Button
                        color="primary"
                        type="button"
                        onClick={updateCamp}
                      >
                        <Trans>
                          {updating_camp === "pending"
                            ? "Updating Campaign"
                            : "Update Campaign"}
                        </Trans>
                      </Button>
                    </div>
                  </Form>
                </Col>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default EditCampaign;
