import React from "react";
import { Container, Row, Col } from "reactstrap";
import { connect } from "react-redux";
import { Trans } from "react-i18next";

class UserHeader extends React.Component {
	render() {
		const { user } = this.props;
		const { name } = user;

		return (
			<>
				<div
					className="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
					style={{
						minHeight: "600px",
						backgroundImage:
							"url(" + require("assets/img/theme/profile-cover.jpg") + ")",
						backgroundSize: "cover",
						backgroundPosition: "center top",
					}}
				>
					{/* Mask */}
					<span className="mask bg-gradient-default opacity-8" />
					{/* Header container */}
					<Container className="d-flex align-items-center" fluid>
						<Row>
							<Col lg="7" md="10">
								<h1 className="display-2 text-white">
									<Trans>Hello</Trans> {name}
								</h1>
								<p className="text-white mt-0 mb-5">
									<Trans i18nKey="profile_header_text">
										This is your profile page. You can see the manage profile
										details here
									</Trans>
								</p>
								{/* <Button
                  color="info"
                  href="#pablo"
                  onClick={e => e.preventDefault()}
                >
                  Edit profile
                </Button> */}
							</Col>
						</Row>
					</Container>
				</div>
			</>
		);
	}
}

const mapStateToProps = ({ auth }) => {
	const { user } = auth;
	return {
		user,
	};
};

export default connect(mapStateToProps, null)(UserHeader);
