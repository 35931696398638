import axios from "axios";
import {
	AUTH_USER,
	UPDATE_USER_DETAILS,
	FETCH_ALL_POLICIES,
	ADD_USER_ROLE,
	FETCH_USER_ROLES,
	ADD_MEMBER,
	FETCH_MEMBERS,
	ADD_POLICY,
	DELETE_MEMBER,
} from "./types";
import { devUrl } from "../utility";

export const fetchUserDetails = (data) => (dispatch) => {
	axios
		.post(`${devUrl}/fetch_user_details`, data, {
			headers: { authorization: localStorage.getItem("plano_token") },
		})
		.then((response) => {
			console.log(response);
			const user = response.data.user;
			dispatch({ type: AUTH_USER, payload: user });
			localStorage.setItem("user", JSON.stringify(user));
		})
		.catch((error) => {});
};

export const fetchOpUserDetails = (data) => (dispatch) => {
	axios
		.post(`${devUrl}/fetch_open_user_details`, data, {
			headers: { authorization: localStorage.getItem("plano_token") },
		})
		.then((response) => {
			console.log(response);
			const user = response.data.user;
			dispatch({ type: AUTH_USER, payload: user });
			localStorage.setItem("user", JSON.stringify(user));
		})
		.catch((error) => {});
};

export const updateUserDetails = (data) => (dispatch) => {
	axios
		.post(`${devUrl}/update_user_details`, data, {
			headers: { authorization: localStorage.getItem("plano_token") },
		})
		.then((response) => {
			const user = response.data.user;
			dispatch({ type: UPDATE_USER_DETAILS, payload: user });
			localStorage.setItem("user", JSON.stringify(user));
		})
		.catch((error) => {});
};

export const fetchAllPolicies = (data) => (dispatch) => {
	axios
		.post(`${devUrl}/fetch_all_policies`, data, {
			headers: { authorization: localStorage.getItem("plano_token") },
		})
		.then((response) => {
			const { policies } = response.data;
			dispatch({ type: FETCH_ALL_POLICIES, payload: policies });
		})
		.catch((error) => {});
};

export const addUserRole = (data) => (dispatch) => {
	axios
		.post(`${devUrl}/add_user_role`, data, {
			headers: { authorization: localStorage.getItem("plano_token") },
		})
		.then((response) => {
			const { role } = response.data;
			dispatch({ type: ADD_USER_ROLE, payload: role });
		})
		.catch((error) => {});
};

export const fetchRoles = (data) => (dispatch) => {
	axios
		.post(`${devUrl}/fetch_user_roles`, data, {
			headers: { authorization: localStorage.getItem("plano_token") },
		})
		.then((response) => {
			const { roles } = response.data;
			dispatch({ type: FETCH_USER_ROLES, payload: roles });
		})
		.catch((error) => {});
};

export const updateRole = (data) => (dispatch) => {
	axios
		.post(`${devUrl}/edit_user_role`, data, {
			headers: { authorization: localStorage.getItem("plano_token") },
		})
		.then((response) => {
			const roles = response.data.roles;
			dispatch({ type: FETCH_USER_ROLES, payload: roles });
		})
		.catch((error) => {});
};

export const addMember = (data) => (dispatch) => {
	axios
		.post(`${devUrl}/add_member`, data, {
			headers: { authorization: localStorage.getItem("plano_token") },
		})
		.then((response) => {
			const { member } = response.data;
			dispatch({ type: ADD_MEMBER, payload: member });
		})
		.catch((error) => {});
};

export const fetchMembers = (data) => (dispatch) => {
	axios
		.post(`${devUrl}/fetch_user_members`, data, {
			headers: { authorization: localStorage.getItem("plano_token") },
		})
		.then((response) => {
			const { members } = response.data;
			dispatch({ type: FETCH_MEMBERS, payload: members });
		})
		.catch((error) => {});
};

export const updateMember = (data) => (dispatch) => {
	axios
		.post(`${devUrl}/update_member_details`, data, {
			headers: { authorization: localStorage.getItem("plano_token") },
		})
		.then((response) => {
			const { members } = response.data;
			dispatch({ type: FETCH_MEMBERS, payload: members });
		})
		.catch((error) => {});
};

export const addPlanoPolicy = (data) => (dispatch) => {
	axios
		.post(`${devUrl}/add_plano_policy`, data, {
			headers: { authorization: localStorage.getItem("plano_token") },
		})
		.then((response) => {
			const { policy } = response.data;
			dispatch({ type: ADD_POLICY, payload: policy });
		})
		.catch((error) => {});
};

export const deleteMember = (data) => (dispatch) => {
	axios
		.post(`${devUrl}/delete_member`, data, {
			headers: { authorization: localStorage.getItem("plano_token") },
		})
		.then((response) => {
			dispatch({ type: DELETE_MEMBER, payload: data });
		})
		.catch((error) => {});
};

export const changePassword = (data) => (dispatch) => {
	axios
		.post(`${devUrl}/change_password`, data, {
			headers: { authorization: localStorage.getItem("plano_token") },
		})
		.then((response) => {
			// dispatch({ type: DELETE_MEMBER, payload: data });
		})
		.catch((error) => {});
};
