import axios from 'axios';
import { AUTH_USER, UNAUTH_USER, AUTH_ERROR, SIGNING_IN } from './types';
import { devUrl } from '../utility';

export const  setAuthTokenAction = (token,user)=> dispatch => {
    console.log('token',token)
    axios.defaults.headers.common['authorization'] = token
    localStorage.setItem("plano_token", token);
    localStorage.setItem("user",JSON.stringify(user));
}

export const signInUser = ({email,password}) => dispatch => {
    dispatch({type: SIGNING_IN, payload: true })
    axios.post(`${devUrl}/signin`, {email,password})
        .then(response => {
            console.log(response);
           setAuthTokenAction(response.data.token,response.data.user)(dispatch);
           dispatch({ type: AUTH_USER, payload:response.data.user });
           dispatch({ type: SIGNING_IN, payload: false});

         })
        .catch(error => {
           authError("Incorrect Email or Password")(dispatch);
           dispatch({ type: SIGNING_IN, payload: false});
        });
}

export const authError = (error) => dispatch => {
	dispatch({
		type: AUTH_ERROR,
		payload: error
	});
}

export const signOut = () => dispatch => {
  dispatch({
      type: UNAUTH_USER
  })
  localStorage.removeItem("plano_token");
  localStorage.removeItem("user");
  window.location.reload();
}


