import React from "react";
import {
	Modal,
	Button,
	Card,
	CardHeader,
	CardBody,
	Form,
	FormGroup,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	Input,
} from "reactstrap";
import { isValidPassword } from "../../helpers/validations";
import { Trans } from "react-i18next";

class PasswordChangeModal extends React.Component {
	state = {
		new_pass: null,
		conf_pass: null,
		errors: {},
	};

	passwordChangeHandler = (e) => {
		const { target } = e;
		const name = target.name;
		const val = target.value;
		this.setState({ [name]: val });
		console.log("name", val, "key", name);
	};

	validateForm = () => {
		const { new_pass, conf_pass } = this.state;
		const { changePassword } = this.props;
		var errors = {};

		if (!isValidPassword(new_pass)) {
			errors["new_pass"] = "New Password should be more than 8 characters";
			this.setState({ errors: errors });
		} else if (new_pass !== conf_pass) {
			errors["conf_pass"] = "Confirm Password does not matches";
			this.setState({ errors: errors });
		} else {
			changePassword({ new_pass });
			this.setState({ new_pass: null, conf_pass: null, errors: {} });
		}
	};

	render() {
		const { show, closePassModal } = this.props;
		const { new_pass, conf_pass, errors } = this.state;

		return (
			<Modal
				className="modal-dialog-centered"
				size="sm"
				isOpen={show}
				toggle={() => closePassModal()}
			>
				<div className="modal-body p-0">
					<Card className="bg-secondary shadow border-0">
						<CardHeader className="bg-transparent pb-2">
							<div className="modal-header">
								<h4 className="modal-title" id="modal-title-default">
									<Trans>Change Member Password</Trans>
								</h4>
								<button
									aria-label="Close"
									className="close"
									data-dismiss="modal"
									type="button"
									onClick={() => closePassModal()}
								>
									<span aria-hidden={true}>×</span>
								</button>
							</div>
						</CardHeader>
						<CardBody className="px-lg-3 py-lg-3">
							<Form role="form">
								<FormGroup className="mb-3">
									<InputGroup className="input-group-alternative">
										<InputGroupAddon addonType="prepend">
											<InputGroupText>
												<i className="fas fa-key" />
											</InputGroupText>
										</InputGroupAddon>
										<Input
											name="new_pass"
											placeholder="New Password"
											type="password"
											onChange={this.passwordChangeHandler}
											value={new_pass}
										/>
									</InputGroup>
									{errors.new_pass && (
										<small style={{ color: "#f5365c" }}>
											<span className="fa fa-exclamation-circle"> </span>
											&nbsp; {errors.new_pass}
										</small>
									)}
								</FormGroup>

								<FormGroup className="mb-3">
									<InputGroup className="input-group-alternative">
										<InputGroupAddon addonType="prepend">
											<InputGroupText>
												<i className="fas fa-key" />
											</InputGroupText>
										</InputGroupAddon>
										<Input
											name="conf_pass"
											placeholder="Confirm Password"
											type="password"
											onChange={this.passwordChangeHandler}
											value={conf_pass}
										/>
									</InputGroup>
									{errors.conf_pass && (
										<small style={{ color: "#f5365c" }}>
											<span className="fa fa-exclamation-circle"> </span>
											&nbsp; {errors.conf_pass}
										</small>
									)}
								</FormGroup>

								<div className="text-center">
									<Button
										block
										className="my-4"
										color="primary"
										type="button"
										onClick={this.validateForm}
									>
										<Trans>Update</Trans>
									</Button>
								</div>
							</Form>
						</CardBody>
					</Card>
				</div>
			</Modal>
		);
	}
}

export default PasswordChangeModal;
