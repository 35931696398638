import React from "react";

const Message = (props) => {
	return (
		<div className="message" style={{ marginBottom: "10px" }}>
			<div className="data">
				{props.message.ownMessage ? (
					<div className="mine text-right">
						<div className="message2 last">{props.message.message.text}</div>
					</div>
				) : (
					<div className="yours">
						<div className="message2 last">{props.message.message.text}</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default Message;
