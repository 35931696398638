/* eslint-disable no-undef */
import React from "react";
import {
	withScriptjs,
	withGoogleMap,
	GoogleMap,
	Marker,
} from "react-google-maps";
import { InfoBox } from "react-google-maps/lib/components/addons/InfoBox";
import MapInfoBox from "./MapInfoBox";
import { Card, Row, CardBody, Col, CardHeader } from "reactstrap";
import { Trans } from "react-i18next";

const MapWithAMarkerClusterer = withScriptjs(
	withGoogleMap((props) => (
		<GoogleMap
			defaultZoom={10}
			defaultCenter={{
				lat: Number(props.center.latitude),
				lng: Number(props.center.longitude),
			}}
			onDragEnd={() => console.log("dragged")}
		>
			{props.showInfoBox && (
				<InfoBox
					position={
						new google.maps.LatLng(
							props.activeMarker.lat,
							props.activeMarker.lng
						)
					}
					options={{ enableEventPropagation: true }}
					onCloseClick={props.onInfoBoxCloseClick}
				>
					<MapInfoBox infoBoxDetails={props.infoBoxDetails} />
				</InfoBox>
			)}

			{/* <MarkerClusterer
        onClick={props.onMarkerClustererClick}
        imagePath={"https://cdn.jsdelivr.net/gh/googlemaps/v3-utility-library/markerclustererplus/images/m3.png"}
        averageCenter
        enableRetinaIcons
        gridSize={60}
      > */}
			{/* {props.markers.map(marker => (
          <Marker
            key={marker.photo_id}
            position={{ lat: marker.latitude, lng: marker.longitude }}
          />
        ))} */}

			{props.inventory.map((item, index) => {
				let position = {
					lat: item.loc.coordinates[1],
					lng: item.loc.coordinates[0],
				};
				return (
					<Marker
						key={index}
						position={position}
						icon={{
							url: require("assets/img/icons/map/green_icon.png"),
							scaledSize: new window.google.maps.Size(30, 30),
						}}
						title={item.location}
						onClick={() => props.handleInfoBox(item, position)}
					/>
				);
			})}

			{props.nearby_data &&
				props.nearby_data.map((item, index) => {
					let position = {
						lat: item.loc.coordinates[1],
						lng: item.loc.coordinates[0],
					};
					return (
						<Marker
							key={index}
							position={position}
							icon={{
								url: item.icon || "",
								scaledSize: new window.google.maps.Size(30, 30),
							}}
							title={item.name}
						/>
					);
				})}
			{/* </MarkerClusterer> */}
		</GoogleMap>
	))
);

// const LocadMap = withScriptjs(withGoogleMap(props => (
//    <GoogleMap
//     defaultZoom={11}
//     defaultCenter={{ lat: Number(props.center.latitude), lng: Number(props.center.longitude) }}
//   >
//     {
//       props.inventory.map((item,index) => {
//               let position = {
//                 lat: item.loc.coordinates[1],
//                 lng: item.loc.coordinates[0]
//               };
//               return (
//                 <Marker
//                   key={index}
//                   position={position}
//                   icon={{
//                     url: require('assets/img/icons/map/new_2.png'),
//                     scaledSize: new window.google.maps.Size(30, 30)
//                   }}
//                   title={item.location}
//                 />
//               );
//             })
//       }

//       {
//         props.nearby_data && props.nearby_data.map((item,index) => {
//             let position = {
//               lat: item.loc.coordinates[1],
//               lng: item.loc.coordinates[0]
//             };
//             return (
//               <Marker
//                 key={index}
//                 position={position}
//                 icon={{
//                   url: item.icon || "",
//                   scaledSize: new window.google.maps.Size(30, 30)
//                 }}
//                 title={item.location}
//               />
//             );
//         })
//       }

//   </GoogleMap>
//   )

// ));

class InvMap extends React.Component {
	state = {
		showInfoBox: false,
		activeMarker: null,
		infoBoxDetails: null,
	};

	handleInfoBox = (item, position) => {
		this.setState({
			showInfoBox: true,
			activeMarker: position,
			infoBoxDetails: item,
		});
	};

	onInfoBoxCloseClick = () => {
		this.setState({
			showInfoBox: false,
		});
	};

	render() {
		const { show, inventory, nearby_data } = this.props;
		const { activeMarker, showInfoBox, infoBoxDetails } = this.state;
		return (
			<Card className={show ? "shadow mb-sm-3" : "d-none"}>
				<CardHeader>
					<Row>
						<Col xs="11">
							<h3><Trans>Map</Trans></h3>
						</Col>
					</Row>
				</CardHeader>

				<CardBody>
					{inventory && inventory.length > 0 && (
						<MapWithAMarkerClusterer
							inventory={inventory}
							nearby_data={nearby_data}
							center={inventory[0]}
							handleInfoBox={this.handleInfoBox}
							activeMarker={activeMarker}
							infoBoxDetails={infoBoxDetails}
							showInfoBox={showInfoBox}
							onInfoBoxCloseClick={this.onInfoBoxCloseClick}
							loadingElement={<div style={{ height: `100%` }} />}
							googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDj0lUV-8zun12iUwHxDt1kcYVUJyoxw0o&callback&libraries=places"
							containerElement={<div style={{ height: `500px` }} />}
							mapElement={<div style={{ height: `100%` }} />}
						/>
					)}
				</CardBody>
			</Card>
		);
	}
}

export default InvMap;
