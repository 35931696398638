import _ from "lodash";
import axios from "axios";
import { devUrl } from "../utility";

export const nFormatter = (num, digits) => {
	var si = [
		{ value: 1, symbol: "" },
		{ value: 1e3, symbol: " k" },
		{ value: 1e6, symbol: " M" },
		{ value: 1e9, symbol: " B" },
		{ value: 1e12, symbol: " T" },
		{ value: 1e15, symbol: " P" },
		{ value: 1e18, symbol: " E" },
	];
	var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
	var i;
	for (i = si.length - 1; i > 0; i--) {
		if (num >= si[i].value) {
			break;
		}
	}
	return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
};

export const formatDate = (unix) => {
	const d = new Date(unix);
	const dformat =
		[d.getMonth() + 1, d.getDate(), d.getFullYear()].join("/") +
		" " +
		[d.getHours(), d.getMinutes(), d.getSeconds()].join(":");
	return dformat;
};

export const mapStyle = [
	{
		featureType: "all",
		elementType: "geometry.fill",
		stylers: [
			{
				weight: "2.00",
			},
		],
	},
	{
		featureType: "all",
		elementType: "geometry.stroke",
		stylers: [
			{
				color: "#9c9c9c",
			},
		],
	},
	{
		featureType: "all",
		elementType: "labels.text",
		stylers: [
			{
				visibility: "on",
			},
		],
	},
	{
		featureType: "landscape",
		elementType: "all",
		stylers: [
			{
				color: "#f2f2f2",
			},
		],
	},
	{
		featureType: "landscape",
		elementType: "geometry.fill",
		stylers: [
			{
				color: "#ffffff",
			},
		],
	},
	{
		featureType: "landscape.man_made",
		elementType: "geometry.fill",
		stylers: [
			{
				color: "#ffffff",
			},
		],
	},
	{
		featureType: "poi",
		elementType: "all",
		stylers: [
			{
				visibility: "off",
			},
		],
	},
	{
		featureType: "road",
		elementType: "all",
		stylers: [
			{
				saturation: -100,
			},
			{
				lightness: 45,
			},
		],
	},
	{
		featureType: "road",
		elementType: "geometry.fill",
		stylers: [
			{
				color: "#eeeeee",
			},
		],
	},
	{
		featureType: "road",
		elementType: "labels.text.fill",
		stylers: [
			{
				color: "#7b7b7b",
			},
		],
	},
	{
		featureType: "road",
		elementType: "labels.text.stroke",
		stylers: [
			{
				color: "#ffffff",
			},
		],
	},
	{
		featureType: "road.highway",
		elementType: "all",
		stylers: [
			{
				visibility: "simplified",
			},
		],
	},
	{
		featureType: "road.arterial",
		elementType: "labels.icon",
		stylers: [
			{
				visibility: "off",
			},
		],
	},
	{
		featureType: "transit",
		elementType: "all",
		stylers: [
			{
				visibility: "off",
			},
		],
	},
	{
		featureType: "water",
		elementType: "all",
		stylers: [
			{
				color: "#46bcec",
			},
			{
				visibility: "on",
			},
		],
	},
	{
		featureType: "water",
		elementType: "geometry.fill",
		stylers: [
			{
				color: "#c8d7d4",
			},
		],
	},
	{
		featureType: "water",
		elementType: "labels.text.fill",
		stylers: [
			{
				color: "#070707",
			},
		],
	},
	{
		featureType: "water",
		elementType: "labels.text.stroke",
		stylers: [
			{
				color: "#ffffff",
			},
		],
	},
];

export const numberWithCommas = (x) => {
	//return x ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : x;
	//return x ? Math.round((x + Number.EPSILON) * 100) / 100 : x;
	return x ? Math.round(x) : x;
};

export const chileHeaders =  {
   "site_code": "CCPD0001",
   "location": "Vitacura / Presidente Riesco - Orientación: SN",
   "width": 1.15,
   "height": 2.5,
   "city": "Las Condes",
   "state": "Santiago",
   "media_type": "Paleta Digital",
   "lighting": "SI",
   "tax_id": "84383200-8",
   "area": 2.875,
   "unit_of_measurement": "mts",
   "region_province": "Región Metropolitana",
   "orientation": "SN",
   "static_digital": "Digital",
   "format": "Vertical",
   "specs": "Video armado 10 segundos, formato MOV",
   "avg_footfall": "Alto",
   "strategic_business_unit": "HAVAS/Mediawave",
   "location_quality": 1,
   "visibility_quality": 1,
   "visual_contamination": 1,
   "illumination": 1,
   "size_quality": 0,
   "flow_quality": 1,
   "price_quality": 1,
   "score": 0.93,
   "cat_supplier": "Con Acuerdo",
   "final_score": 0.93,
   "cost_1": 320000,
   "sell_price_1": 320000,
   "cost_2": 320000,
   "sell_price_2": 480000,
   "cost_3": "",
   "sell_price_3": "",
   "impression_cost": "",
   "impression_sale_price": "",
   "installation_cost": "",
   "zone": "Oriente",
   "coordinates": "-33.4114292231988,-70.60267409719",
   "trip_600mt": 111432.687233,
   "trip_back_600mt": 40455.3618385,
   "sec_600mt": "c1a",
   "homes_600mt": 5001,
   "male_600mt": 4484,
   "female_600mt": 4683,
   "p_0_5_600mt": 525,
   "p_6_14_600mt": 346,
   "p_15_29_600mt": 2043,
   "p_30_44_600mt": 2531,
   "p_45_64_600mt": 2008,
   "p_65_600mt": 1714,
   "homes_ab_600mt": 1276,
   "homes_c1a_600mt": 3129,
   "homes_c1b_600mt": 576,
   "homes_c2_600mt": 13,
   "homes_c3_600mt": 7,
   "homes_d_600mt": 0,
   "homes_e_600mt": 0,
   "health_600mt": 10,
   "entertainment_600mt": 8,
   "services_600mt": 80,
   "pension_600mt": 1,
   "commerce_600mt": 12,
   "various_600mt": 14,
   "accommodation_600mt": 14,
   "government_organisation_600mt": 0,
   "tourism_600mt": 0,
   "security_institute_600mt": 0,
   "media_600mt": 0,
   "transport_600mt": 0,
   "international_organization_600mt": 10,
   "education_600mt": 2,
   "food_600mt": 58,
   "trip_1000mt": 111432.687233,
   "trip_back_1000mt": 40455.3618385,
   "sec_1000mt": "c1a",
   "homes_1000mt": 12118,
   "male_1000mt": 11026,
   "female_1000mt": 12187,
   "p_0_5_1000mt": 1317,
   "p_6_14_1000mt": 993,
   "p_15_29_1000mt": 5320,
   "p_30_44_1000mt": 5993,
   "p_45_64_1000mt": 5045,
   "p_65_1000mt": 4545,
   "homes_ab_1000mt": 2321,
   "homes_c1a_1000mt": 8310,
   "homes_c1b_1000mt": 1406,
   "homes_c2_1000mt": 56,
   "homes_c3_1000mt": 25,
   "homes_d_1000mt": 0,
   "homes_e_1000mt": 0,
   "health_1000mt": 51,
   "entertainment_1000mt": 23,
   "services_1000mt": 228,
   "pension_system_1000mt": 3,
   "commerce_1000mt": 61,
   "various_1000mt": 32,
   "accommodation_1000mt": 26,
   "government_organizations_1000mt": 1,
   "tourism_1000mt": 0,
   "security_institutions_1000mt": 0,
   "media_1000mts": 6,
   "transport_1000mt": 3,
   "international_organization_1000mt": 25,
   "education_1000mt": 12,
   "food_1000mt": 182
 };

export const getKeyForUpload = (type, file, additional_data) => {
	switch (type) {
		case "site_map":
			return "site_maps/user_" + additional_data + "_" + file.name;

		case "creative":
			return (
				"creative_approvals/user_" +
				localStorage.getItem("id") +
				"_" +
				additional_data +
				"_" +
				file.name
			);
		default: {
		}
	}
};

export const uploadFilesS3 = (files, bucket, type, additional_data) => {
	return new Promise(function (resolve, reject) {
		var total_images_uploaded = [];
		_.map(files, function (file) {
			var key = getKeyForUpload(type, file, additional_data);
			axios
				.post(
					`${devUrl}/generateSignedUrlForUpload`,
					{
						file_name: file.name,
						file_type: file.type,
						bucket: bucket,
						key: key,
					},
					{ headers: { authorization: localStorage.getItem("plano_token") } }
				)
				.then((res) => {
					console.log("response", res.data);
					if (res.data.error) {
						// console.log('error',error)
					} else {
						const xhr = new XMLHttpRequest();
						xhr.open("PUT", res.data.data);

						xhr.upload.onprogress = (e) => {
							const progress = Math.round((e.loaded / e.total) * 100);
							console.log("file", file.name, " progres", progress);
						};

						xhr.onreadystatechange = () => {
							if (xhr.readyState === 4) {
								if (xhr.status === 200) {
									console.log("File uploaded success");
									var site_code_arr = file.name.split(".");
									var site_code = site_code_arr[0];
									total_images_uploaded.push({
										site_code: site_code,
										url: res.data.url,
										type: file.type,
										name: file.name,
									});
									if (total_images_uploaded.length === files.length) {
										resolve(total_images_uploaded);
									}
								}
							}
						};
						xhr.send(file);
					}
				});
		});
	});
};
