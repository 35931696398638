import React from "react";
import {
	Modal,
	Card,
	Button,
	CardBody,
	Form,
	FormGroup,
	Input,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	CardHeader,
} from "reactstrap";
import _ from "lodash";
import Select, { createFilter } from "react-select";
import { Trans, Translation } from "react-i18next";

class AddMemberModal extends React.Component {
	state = {
		formData: {},
		errors: {},
	};

	formHandler = (e) => {
		const target = e.target;
		const value = target.value;
		const name = target.name;
		var formData = { ...this.state.formData };
		formData[name] = value;
		this.setState({ formData: formData });
	};

	submitForm = () => {
		const { addMember } = this.props;
		const { formData } = this.state;

		addMember(formData);
		this.setState({ formData: {}, errors: {} });
	};

	handleRoleChange = (val) => {
		var formData = { ...this.state.formData };
		formData["role"] = val.value;
		this.setState({ formData: formData });
	};

	handleBusinessUnits = (val) => {
		console.log("val", val);
		var formData = { ...this.state.formData };
		formData["business_units"] = val;
		this.setState({ formData: formData });
	};

	render() {
		const { show, roles, closeAddMemberModal, add_member_errors, members } =
			this.props;
		const { role_name } = this.state;

		var allroles = [];
		if (!_.isEmpty(roles)) {
			allroles = roles.map((c) => {
				return { label: c.name, value: c._id };
			});
		}

		var business_units = ["HAVAS", "Mediawave", "Mediared"];
		business_units = business_units.map((c) => {
			return { label: c, value: c };
		});

		var all_shared_users = [];
		if (members && members.length > 0) {
			members.forEach((m) => {
				all_shared_users.push({ label: m.email, value: m._id });
			});
		}

		return (
			<Modal
				className="modal-dialog-centered"
				size="sm"
				isOpen={show}
				toggle={() => closeAddMemberModal()}
			>
				<div className="modal-body p-0">
					<Card className="bg-secondary shadow border-0">
						<CardHeader className="bg-transparent pb-2">
							<div className="modal-header">
								<h4 className="modal-title" id="modal-title-default">
									Add Member
								</h4>
								<button
									aria-label="Close"
									className="close"
									data-dismiss="modal"
									type="button"
									onClick={() => closeAddMemberModal()}
								>
									<span aria-hidden={true}>×</span>
								</button>
							</div>
						</CardHeader>
						<CardBody className="px-lg-3 py-lg-3">
							<Form role="form">
								<FormGroup className="mb-3">
									<InputGroup className="input-group-alternative">
										<InputGroupAddon addonType="prepend">
											<InputGroupText>
												<i className="ni ni-email-83" />
											</InputGroupText>
										</InputGroupAddon>
										<Translation>
											{(t) => (
												<Input
													name="name"
													placeholder={t("Name")}
													type="text"
													onChange={this.formHandler}
													value={role_name}
												/>
											)}
										</Translation>
									</InputGroup>
									{add_member_errors.name && (
										<small style={{ color: "#f5365c" }}>
											<span className="fa fa-exclamation-circle"> </span>
											&nbsp; {add_member_errors.name}
										</small>
									)}
								</FormGroup>
								<FormGroup className="mb-3">
									<InputGroup className="input-group-alternative">
										<InputGroupAddon addonType="prepend">
											<InputGroupText>
												<i className="ni ni-email-83" />
											</InputGroupText>
										</InputGroupAddon>
										<Translation>
											{(t) => (
												<Input
													name="email"
													placeholder={t("Email")}
													type="email"
													onChange={this.formHandler}
													value={role_name}
												/>
											)}
										</Translation>
									</InputGroup>
									{add_member_errors.email && (
										<small style={{ color: "#f5365c" }}>
											<span className="fa fa-exclamation-circle"> </span>
											&nbsp; {add_member_errors.email}
										</small>
									)}
								</FormGroup>
								<FormGroup className="mb-3">
									<InputGroup className="input-group-alternative">
										<InputGroupAddon addonType="prepend">
											<InputGroupText>
												<i className="ni ni-email-83" />
											</InputGroupText>
										</InputGroupAddon>
										<Translation>
											{(t) => (
												<Input
													name="phone"
													placeholder={t("Phone")}
													type="phone"
													onChange={this.formHandler}
													value={role_name}
												/>
											)}
										</Translation>
									</InputGroup>
									{add_member_errors.phone && (
										<small style={{ color: "#f5365c" }}>
											<span className="fa fa-exclamation-circle"> </span>
											&nbsp; {add_member_errors.phone}
										</small>
									)}
								</FormGroup>

								<FormGroup className="mb-3">
									<InputGroup className="input-group-alternative">
										<InputGroupAddon addonType="prepend">
											<InputGroupText>
												<i className="ni ni-email-83" />
											</InputGroupText>
										</InputGroupAddon>
										<Translation>
											{(t) => (
												<Input
													name="password"
													placeholder={t("Password")}
													type="password"
													onChange={this.formHandler}
													value={role_name}
												/>
											)}
										</Translation>
									</InputGroup>
									{add_member_errors.password && (
										<small style={{ color: "#f5365c" }}>
											<span className="fa fa-exclamation-circle"> </span>
											&nbsp; {add_member_errors.password}
										</small>
									)}
								</FormGroup>
								<FormGroup>
									<Translation>
										{(t) => (
											<Select
												name="role"
												placeholder={t("Role")}
												className="input-group-alternative"
												filterOption={createFilter({ ignoreAccents: false })} // this makes all the difference!
												options={allroles}
												onChange={this.handleRoleChange}
											/>
										)}
									</Translation>

									{add_member_errors.role && (
										<small style={{ color: "#f5365c" }}>
											<span className="fa fa-exclamation-circle"> </span>
											&nbsp; {add_member_errors.role}
										</small>
									)}
								</FormGroup>
								<FormGroup>
									<Translation>
										{(t) => (
											<Select
												isMulti
												name="business_units"
												placeholder={t("Business Units")}
												className="input-group-alternative"
												filterOption={createFilter({ ignoreAccents: false })} // this makes all the difference!
												options={business_units}
												onChange={this.handleBusinessUnits}
											/>
										)}
									</Translation>

									{add_member_errors.business_units && (
										<small style={{ color: "#f5365c" }}>
											<span className="fa fa-exclamation-circle"> </span>
											&nbsp; {add_member_errors.business_units}
										</small>
									)}
								</FormGroup>
								<FormGroup>
									<Select
										isMulti
										name="shared_users"
										placeholder="Shared Users"
										className="input-group-alternative"
										filterOption={createFilter({ ignoreAccents: false })} // this makes all the difference!
										options={all_shared_users}
										onChange={this.handleSharedUsers}
									/>
									<small style={{ color: "#464749" }}>
										(<Trans>optional</Trans>)
									</small>
								</FormGroup>
								<div className="text-center">
									<Button
										block
										className="my-4"
										color="primary"
										type="button"
										onClick={this.submitForm}
									>
										<Trans>Add</Trans>
									</Button>
								</div>
							</Form>
						</CardBody>
					</Card>
				</div>
			</Modal>
		);
	}
}

export default AddMemberModal;
