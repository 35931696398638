import React from "react";
import { Row, Col, Alert } from "reactstrap";

import socketIOClient from "socket.io-client";
import { devUrl } from "../utility";
import Pusher from "pusher-js";

class Alerts extends React.Component {
	constructor(props) {
		super(props);
		this.socket = null;
		this.state = {
			message: "",
		};
	}
	componentDidMount() {
		// this.initAlerts();
		const PUSHER_APP_KEY = "1769859fe5be83aa1c9c";
		const CLUSTER = "ap2";
		const userId = localStorage.getItem("uid");
		this.pusher = new Pusher(PUSHER_APP_KEY, {
			cluster: CLUSTER,
			encrypted: true,
		});
		this.channel = this.pusher.subscribe("alertsChannel" + userId);
		this.channel.bind("new_alert", (data) => {
			this.setState({ message: data.message });
			console.log("g", data);
		});
	}

	initAlerts() {
		var user = localStorage.getItem("user");
		user = JSON.parse(user);
		var username = user.name;
		var uid = user._id;

		this.socket = socketIOClient(devUrl, {
			query: "username=" + username + "&uid=" + uid,
		});

		this.socket.on(
			"alert",
			function (message) {
				console.log("message", message);
				this.setState({ message: message });
			}.bind(this)
		);
	}

	closeMessage = () => {
		this.setState({ message: "" });
	};

	render() {
		const { message } = this.state;
		if (!message) {
			return null;
		}

		return (
			<Col>
				<Alert style={{ overflow: "hidden" }} color="success">
					<Row>
						<Col lg="11">
							<strong> {message}</strong>
						</Col>
						<Col
							lg="1"
							className="col text-right"
							onClick={this.closeMessage}
							style={{ cursor: "pointer" }}
						>
							<strong>X</strong>
						</Col>
					</Row>
				</Alert>
			</Col>
		);
	}
}

export default Alerts;
