import React from "react";
import { connect } from "react-redux";
import { Redirect, Route } from "react-router";

const AuthRoute = props => {
  const { auth, type } = props;
  const { authenticated } = auth;
  if (type === "guest" && authenticated) return <Redirect to="/admin" />;
  else if (type === "private" && !authenticated) return <Redirect to="/" />;

  return <Route {...props} />;
};

const mapStateToProps = ({ auth }) => ({
  auth
});

export default connect(mapStateToProps)(AuthRoute);