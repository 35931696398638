import React from "react";
import {
	Container,
	Card,
	Row,
	Col,
	Button,
	CardBody,
	Alert,
	Modal,
	Form,
	FormGroup,
	InputGroup,
	Input,
	InputGroupAddon,
	InputGroupText,
	CardHeader,
} from "reactstrap";
// core components
import CompHeader from "components/Headers/CompHeader.js";
import { fetchAllMediaOwners, fetchDistinctCityStates, addProperMediaOwner } from "actions/invActions";
import { connect } from "react-redux";
import XLSX from "xlsx";
import { bindActionCreators } from "redux";
import Alerts from "../alerts";
import BootstrapTable from "react-bootstrap-table-next";
import { Link } from "react-router-dom";
import { formatDate } from "../../helpers/helpers";
import Select, { createFilter } from "react-select";
import _, { functions } from "lodash";
import { validateForm } from "../../helpers/validations";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import ToolkitProvider, { CSVExport } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { Trans } from "react-i18next";
import i18n from "../../i18n";
import { TotalItem } from "devextreme-react/data-grid";
const { ExportCSVButton } = CSVExport;

function buttonFormatter(cell, row) {
	if (cell === "yes") {
		return (
			<Button className="btn-icon btn-2" color="success" type="button">
				<span className="btn-inner--icon">
					<i className="ni ni-check-bold" />
				</span>
			</Button>
		);
	}
}

function seeInvButton(cell, row) {
	return (
		<Link to={"/admin/vendor_inventory/" + row._id + "/" + row.name}>
			<Button className="btn-icon btn-2" color="primary" type="button">
				<span className="btn-inner--icon">
					<i className="ni ni-bold-right" />
				</span>
			</Button>
		</Link>
	);
}

function dateFormatter(cell, row) {
	if (cell) {
		return formatDate(cell);
	}
}



const columns = [
	{
		dataField: "name",
		text: i18n.t("Media Owner"),
		filter: textFilter({ placeholder: "Text" }),
	},
	{
		dataField: "states",
		text: i18n.t("States"),
		filter: textFilter({ placeholder: "Text" }),
	},
	{
		dataField: "aliases",
		text: i18n.t("Address"),
		filter: textFilter({ placeholder: "Text" }),
	},
	{
		dataField: "phone",
		text: i18n.t("Phone"),
		filter: textFilter({ placeholder: "Text" }),
	},
	{
		dataField: "email",
		text: i18n.t("Email"),
		filter: textFilter({ placeholder: "Text" }),
	},
	{
		dataField: 'inv_received',
		text: 'Inventory Received',
		formatter: buttonFormatter,
		filter: textFilter()
	},
	{
		dataField: "inv_uploaded",
		text: i18n.t("Inventory Uploaded"),
		formatter: buttonFormatter,
		filter: textFilter({ placeholder: "Text" }),
	},
	{
		dataField: "_id",
		text: i18n.t("See Inventory"),
		formatter: seeInvButton,
	},
	{
		dataField: "inv_last_updated",
		text: i18n.t("Last Updated"),
		formatter: dateFormatter,
	},
];

class VendorManagement extends React.Component {
	state = {
		selectedInv: [],
		showCard: {
			map: false,
		},
		showPPTModal: false,
		addMediaOwnerModal: false,
		activeAlert: "",
		activeAlertType: "success",
		addMediaOwnerForm: {},
		addMediaOwnerErrors: {},
		addingMediaOwner: false,
		country: null,
		states: null,
	};

	componentDidMount() {
		const { fetchAllMediaOwners, fetchDistinctCityStates } = this.props;
		fetchAllMediaOwners();
		fetchDistinctCityStates();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.add_media_owner_err !== this.props.add_media_owner_err && this.props.add_media_owner_err) {
			this.setState({
				activeAlert: <Trans>Media Owner already exists</Trans>,
				activeAlertType: "danger",
			});
		}
	}

	setSelectedInventory = (selectedRows) => {
		this.setState({ selectedInv: selectedRows });
	};

	closeActiveAlert = () => {
		this.setState({ activeAlert: "" });
	};

	toggleModal = (state) => {
		this.setState({
			[state]: !this.state[state],
		});
	};

	handleInputChange = (e) => {
		const target = e.target;
		const name = target.name;
		const value = target.value;

		var form = { ...this.state.addMediaOwnerForm };
		var errors = { ...this.state.addMediaOwnerErrors };

		errors[name] = null;
		form[name] = value;
		this.setState({
			addMediaOwnerForm: form,
			addMediaOwnerErrors: errors,
		});
	};

	handleFieldChange = (key, val) => {
		console.log("key", key, "val", val);
		var form = { ...this.state.addMediaOwnerForm };
		var errors = { ...this.state.addMediaOwnerErrors };
		errors[key] = null;
		form[key] = val;
		const { fetchDistinctCityStates } = this.props;
		if (key === "country") {
			fetchDistinctCityStates({ country: val.value });
		}
		this.setState({ addMediaOwnerForm: form, addMediaOwnerErrors: errors });
	};

	isValid = () => {
		const { addMediaOwnerForm } = this.state;
		const { name, email, phone, country, states, password } = addMediaOwnerForm;
		const { addProperMediaOwner } = this.props;
		//   const { brand, campaign_name, start_date, end_date, budget,
		//         cities, media_type, states} = this.state;
		//   const { createCampaign } = this.props;

		const errors = validateForm([
			{ field: "name", value: name, validations: ["Required"] },
			{ field: "email", value: email, validations: ["Required"] },
			{ field: "phone", value: phone, validations: ["Required"] },
			{ field: "password", value: password, validations: ["Required"] },
			{ field: "country", value: country, validations: ["Required"] },
			{ field: "states", value: states, validations: ["Required"] },
		]);

		if (!_.isEmpty(errors)) {
			this.setState({ addMediaOwnerErrors: errors });
		} else {
			addProperMediaOwner({ data: addMediaOwnerForm });
			this.setState({ addMediaOwnerModal: false });
		}
	};

	excelExport = () => {
		var newData = this.props.all_vendors;
		var wb = XLSX.utils.book_new()
		var ws = XLSX.utils.json_to_sheet(newData)

		XLSX.utils.book_append_sheet(wb, ws, "sheet1");
		XLSX.writeFile(wb, "Vendors sheet.xlsx");
		console.log("Vendors Data", newData);
	}

	// importExcel = () => {
	// 	console.log("hello world");
	// }

	render() {
		const { activeAlert, addMediaOwnerForm, addMediaOwnerErrors, addingMediaOwner, activeAlertType } = this.state;
		const { name, email, phone, password } = addMediaOwnerForm;
		const { all_vendors, distinct_city_data, vendor_inventory } = this.props;
		var distCountries = [],
			distStates;

		if (distinct_city_data) {
			distCountries =
				distinct_city_data["country"] &&
				distinct_city_data["country"].map((c) => {
					return { label: c, value: c };
				});
			distStates =
				distinct_city_data["state"] &&
				distinct_city_data["state"].map((s) => {
					return { label: s, value: s };
				});
		}

		return (
			<>
				<CompHeader />
				<Container fluid>
					<Row className="mt-5">
						<Col className="mb-5 mb-xl-0" xl="12">
							<Card className="shadow">
								<CardHeader className="border-0">
									<Row className="align-items-center">
										<div className="col">
											<h3 className="mb-0">
												<Trans>Vendor Management</Trans> ({all_vendors && all_vendors.length})
											</h3>
										</div>
									</Row>
								</CardHeader>
								<CardBody>
									<Alerts />
									{/* { ----Alert ----- } */}
									{activeAlert && (
										<Col>
											<Alert style={{ overflow: "hidden" }} color={activeAlertType}>
												<Row>
													<Col lg="11">
														<strong> {activeAlert}</strong>
													</Col>
													<Col lg="1" className="col text-right" onClick={this.closeActiveAlert} style={{ cursor: "pointer" }}>
														<strong>X</strong>
													</Col>
												</Row>
											</Alert>
										</Col>
									)}

									{all_vendors && (
										<ToolkitProvider keyField="name" data={all_vendors} columns={columns} exportCSV condensed wrapperClasses="table-responsive">
											{(props) => (
												<div>
													{/* <ExportCSVButton {...props.csvProps}>
														<Trans>Export CSV</Trans>!!
													</ExportCSVButton> */}

													<Button
														className={"btn-icon btn-3 pull-right"}
														color="primary"
														type="button"
														onClick={() => this.toggleModal("addMediaOwnerModal")}
													>
														<span className="btn-inner--icon">
															<i className="ni ni-bag-17" />
														</span>
														<span className="btn-inner--text">
															<Trans>Add Media Owner</Trans>
														</span>
													</Button>

													<Button
														color="primary"
														type="button"
														onClick={this.excelExport}
													>
														<Trans>Excel Export!!</Trans>
													</Button>
													{/* <Button
														color="primary"
														type="button"
														onClick={this.importExcel}
													>
														<Trans>Import Excel!!</Trans>
													</Button> */}
													<hr />
													<BootstrapTable {...props.baseProps} filter={filterFactory()} pagination={paginationFactory()} />

													{/* Modal */}
													<Modal
														className="modal-dialog-centered"
														isOpen={this.state.addMediaOwnerModal}
														toggle={() => this.toggleModal("addMediaOwnerModal")}
													>
														<div className="modal-header text-center" style={{ background: "#fefefe" }}>
															<h3 className="modal-title" id="exampleModalLabel">
																<Trans>Add Media Owner</Trans>
															</h3>
														</div>
														<div className="modal-body">
															<CardBody className="px-lg-2 py-lg-2">
																<Form role="form">
																	<FormGroup className="mb-3">
																		<InputGroup className="input-group-alternative">
																			<InputGroupAddon addonType="prepend">
																				<InputGroupText>
																					<i className="ni ni-email-83" />
																				</InputGroupText>
																			</InputGroupAddon>
																			<Input placeholder={i18n.t("Name")} type="text" name="name" onChange={this.handleInputChange} value={name} />
																		</InputGroup>
																		{addMediaOwnerErrors.name && (
																			<small style={{ color: "#f5365c" }}>
																				{" "}
																				<span className="fa fa-exclamation-circle"> </span> {addMediaOwnerErrors.name}
																			</small>
																		)}
																	</FormGroup>
																	<FormGroup className="mb-3">
																		<InputGroup className="input-group-alternative">
																			<InputGroupAddon addonType="prepend">
																				<InputGroupText>
																					<i className="ni ni-email-83" />
																				</InputGroupText>
																			</InputGroupAddon>
																			<Input placeholder={i18n.t("Email")} type="email" name="email" onChange={this.handleInputChange} value={email} />
																		</InputGroup>
																		{addMediaOwnerErrors.email && (
																			<small style={{ color: "#f5365c" }}>
																				{" "}
																				<span className="fa fa-exclamation-circle"> </span> {addMediaOwnerErrors.email}
																			</small>
																		)}
																	</FormGroup>
																	<FormGroup className="mb-3">
																		<InputGroup className="input-group-alternative">
																			<InputGroupAddon addonType="prepend">
																				<InputGroupText>
																					<i className="ni ni-email-83" />
																				</InputGroupText>
																			</InputGroupAddon>
																			<Input placeholder={i18n.t("Phone")} type="phone" name="phone" onChange={this.handleInputChange} value={phone} />
																		</InputGroup>
																		{addMediaOwnerErrors.phone && (
																			<small style={{ color: "#f5365c" }}>
																				{" "}
																				<span className="fa fa-exclamation-circle"> </span> {addMediaOwnerErrors.phone}
																			</small>
																		)}
																	</FormGroup>
																	<FormGroup className="mb-3">
																		<InputGroup className="input-group-alternative">
																			<InputGroupAddon addonType="prepend">
																				<InputGroupText>
																					<i className="ni ni-email-83" />
																				</InputGroupText>
																			</InputGroupAddon>
																			<Input
																				placeholder={i18n.t("Password")}
																				type="password"
																				name="password"
																				onChange={this.handleInputChange}
																				value={password}
																			/>
																		</InputGroup>
																		{addMediaOwnerErrors.password && (
																			<small style={{ color: "#f5365c" }}>
																				{" "}
																				<span className="fa fa-exclamation-circle"> </span> {addMediaOwnerErrors.password}
																			</small>
																		)}
																	</FormGroup>
																	<FormGroup className="mb-3">
																		<Select
																			placeholder={i18n.t("Country")}
																			className="input-group-alternative"
																			filterOption={createFilter({
																				ignoreAccents: false,
																			})} // this makes all the difference!
																			options={distCountries}
																			onChange={this.handleFieldChange.bind(this, "country")}
																		/>
																		{addMediaOwnerErrors.country && (
																			<small style={{ color: "#f5365c" }}>
																				{" "}
																				<span className="fa fa-exclamation-circle"> </span> {addMediaOwnerErrors.country}
																			</small>
																		)}
																	</FormGroup>
																	<FormGroup className="mb-3">
																		<Select
																			isMulti
																			placeholder={i18n.t("States")}
																			className="input-group-alternative"
																			filterOption={createFilter({
																				ignoreAccents: false,
																			})} // this makes all the difference!
																			options={distStates}
																			onChange={this.handleFieldChange.bind(this, "states")}
																		/>
																		{addMediaOwnerErrors.states && (
																			<small style={{ color: "#f5365c" }}>
																				{" "}
																				<span className="fa fa-exclamation-circle"> </span> {addMediaOwnerErrors.states}
																			</small>
																		)}
																	</FormGroup>
																</Form>
															</CardBody>
														</div>
														<div className="modal-footer">
															{/* <Button block color="primary" type="button">
                                        Add To Existing
                                      </Button>  */}
															<Button block color="primary" type="button" onClick={this.isValid}>
																{addingMediaOwner ? "Creating..." : "Create New"}
															</Button>
														</div>
													</Modal>
												</div>
											)}
										</ToolkitProvider>
									)}
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</>
		);
	}
}

const mapStateToProps = ({ inv }) => {
	//   console.log('inv',inv)
	const { all_vendors, distinct_city_data, add_media_owner_err } = inv;
	return {
		all_vendors,
		distinct_city_data,
		add_media_owner_err,
	};
};

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			fetchAllMediaOwners,
			fetchDistinctCityStates,
			addProperMediaOwner,
		},
		dispatch
	);

export default connect(mapStateToProps, mapDispatchToProps)(VendorManagement);
