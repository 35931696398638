import React from "react";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";

import DataGrid, {
	Column,
	Grouping,
	GroupPanel,
	Selection,
	RequiredRule,
	ColumnChooser,
	ColumnFixing,
	Editing,
	SearchPanel,
	Paging
} from "devextreme-react/data-grid";
import { numberWithCommas } from "../../helpers/helpers";
class ReactGrid2 extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			autoExpandAll: true,
			allMode: "allPages",
			checkBoxesMode: "onClick",
		};

		this.dataGridRef = React.createRef();
		this.onCheckBoxesModeChanged = this.onCheckBoxesModeChanged.bind(this);
		this.onAllModeChanged = this.onAllModeChanged.bind(this);
		this.onAutoExpandAllChanged = this.onAutoExpandAllChanged.bind(this);
		this.onSelectChange = this.onSelectChange.bind(this);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.selectedInv !== this.props.selectedInv && this.props.selectedInv && this.props.selectedInv.length === 0) {
			this.deselectVisibleRows.bind(this);
		}
	}

	onCheckBoxesModeChanged({ value }) {
		this.setState({ checkBoxesMode: value });
	}

	onAllModeChanged({ value }) {
		// console.log(value);
		this.setState({ allMode: value });
	}

	onSelectChange({ selectedRowsData }) {
		const { setSelectedInventory } = this.props;
		setSelectedInventory(selectedRowsData);
	}

	onRowUpdated(row) {
		const { updateInventory } = this.props;
		updateInventory(row.data);
	}

	deselectVisibleRows() {
		this.dataGridRef.current.instance.deselectAll();
	}

	onRowPrepared(e) {
		e.rowElement.style.height = "65px";
		if (e.rowType === "data" && e.data.planning_enabled === false) {
			// console.log("onRowPrepared", e.data);
			e.rowElement.style.backgroundColor = "#d49ea3";
			e.rowElement.className = e.rowElement.className.replace("dx-row-alt", "");
		}
	}

	render() {
		const { pColumns, inventory } = this.props;
		// console.log("pColumns", pColumns)

		const { checkBoxesMode } = this.state;

		const priceFields = [
			"additional_data.cost_1",
			"additional_data.cost_2",
			"additional_data.cost_3",
			"additional_data.sell_price_1",
			"additional_data.sell_price_2",
			"additional_data.sell_price_3",
			"additional_data.impression_cost",
			"additional_data.impression_sale_price",
			"additional_data.installation_cost",
		];

		return (
			<div>
				<DataGrid
					ref="dataGridRef"
					dataSource={inventory}
					allowColumnReordering={true}
					showBorders={true}
					showCheckBoxesMode={checkBoxesMode}
					allowColumnResizing={true}
					columnAutoWidth={true}
					onRowUpdated={this.onRowUpdated.bind(this)}
					onRowPrepared={this.onRowPrepared.bind(this)}
					onSelectionChanged={this.onSelectChange.bind(this)}
				>
					<Selection mode="multiple" showCheckBoxesMode={checkBoxesMode} />
					<Editing mode="cell" allowUpdating={true} />
					<GroupPanel visible={true} />
					{/* <SearchPanel visible={true} onOptionChanged={(e) => console.log(e.target.value)}/> */}
					<ColumnChooser enabled={true} mode="select" />
					<ColumnFixing enabled={true} />
					<Grouping autoExpandAll={this.state.autoExpandAll} />
					<Paging visible={false} enabled={false} />

					{pColumns.map((column_data, i) => {
						const { name, visible, title } = column_data;

						// if (i === 0) {
						// 	return <Column caption={title} key={name} dataField={name} dataType="string" groupIndex={0} />;
						// }

						if (visible === false) {
							if (priceFields.indexOf(name) !== -1) {
								return <Column key={name} dataField={name} caption={title} cellRender={(data) => numberWithCommas(data.value)} visible={false} />;
							} else {
								return <Column key={name} dataField={name} caption={title} visible={false} />;
							}
						}

						if (name === "site_map") {
							return (
								<Column
									caption={title}
									key={name}
									dataField={name}
									cellRender={(data) => {
										if (data.value) {
											return <img alt="site map" src={data.value} height="50" />;
										} else {
											return <div>Not Available</div>;
										}
									}}
									height="50"
								/>
							);
						}

						if (name === "inventory_cost") {
							return <Column key={name} dataField={name} caption={title} cellRender={(data) => numberWithCommas(data.value)} />;
						}

						return (
							<Column key={name} dataField={name} caption={title} dataType="string">
								<RequiredRule />
							</Column>
						);
					})}
				</DataGrid>
			</div>
		);
	}

	onAutoExpandAllChanged() {
		this.setState({
			autoExpandAll: !this.state.autoExpandAll,
		});
	}
}

export default ReactGrid2;
