import React from "react";
import {
	Container,
	Card,
	Row,
	Col,
	Button,
	CardBody,
	Alert,
	CardHeader,
} from "reactstrap";
import CompHeader from "components/Headers/CompHeader.js";
import {
	fetchApprovalInventory,
	approveInventory,
	fetchOpenChatUsers,
} from "actions/invActions";
import { fetchOpUserDetails } from "actions/userActions";
import ReactGrid2 from "views/campaigns/ReactGrid2";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Trans } from "react-i18next";
import Chat from "../book_inventory/chat/Chat";

const invColumns = [
	// { title: 'Sitemap', field: 'site_map', render: rowData => <img src={rowData.site_map} style={{width: 100}}/> },
	{ title: "Media Owner", name: "media_owner" },
	{ title: "Location", name: "location" },
	{ title: "Height", name: "height" },
	{ title: "Width", name: "width" },
	{ title: "City", name: "city" },
	{ title: "Media Type", name: "media_type" },
	{ title: "Booking Start", name: "booking_start" },
	{ title: "Booking End", name: "booking_end" },
];

class ApproveInventory extends React.Component {
	state = {
		selectedInv: [],
	};

	componentDidMount() {
		const { campId, userId } = this.props.match.params;
		const {
			fetchApprovalInventory,
			fetchOpenChatUsers,
			fetchOpUserDetails,
		} = this.props;

		fetchOpUserDetails({ userId });
		fetchApprovalInventory({ campId, userId });
		fetchOpenChatUsers({ userId, campId });
	}

	setSelectedInventory = (selectedRows) => {
		console.log("sec", selectedRows);
		this.setState({ selectedInv: selectedRows });
	};

	approveInventory = (key) => {
		const { approveInventory } = this.props;
		const { selectedInv } = this.state;
		const { campId, userId } = this.props.match.params;
		const invIds = selectedInv.map((s) => s._id);
		approveInventory({
			approval: key,
			invIds,
			campId,
			userId,
		});
		this.setState({ selectedInv: [] });
	};

	render() {
		const { approval_inventory, chat_users, user, camp } = this.props;
		const { selectedInv } = this.state;
		console.log(
			"chat_users",
			chat_users,
			"user",
			user,
			" selected",
			selectedInv
		);

		return (
			<>
				<CompHeader />
				<Container fluid>
					<Row className="mt-5">
						<Col className="mb-5 mb-xl-0" xl="9">
							<Card className="shadow">
								<CardHeader className="border-0">
									<Row className="align-items-center">
										<div className="col">
											<h2>
												{user.name} (<Trans>Media Owner</Trans>)
											</h2>
											<h4 className="mb-0">
												<Trans
													i18nKey="campaign_booking_confirmation"
													camp={camp}
												>
													Confirm Booking of {{ camp }} Campaign
												</Trans>
											</h4>
										</div>
										<div className="col text-right">
											<Button
												color="default"
												href="#pablo"
												onClick={(e) => e.preventDefault()}
												size="m"
											>
												<span className="fa fa-download"></span>
											</Button>
										</div>
									</Row>
								</CardHeader>
								<CardBody>
									{/* ----- Book Inventory Button ----- */}
									{selectedInv && selectedInv.length > 0 && (
										<Alert style={{ overflow: "hidden" }} color="info">
											<strong
												style={{ marginTop: "8px", position: "absolute" }}
											>
												{" "}
												{selectedInv.length} <Trans>Selected</Trans>
											</strong>

											<Button
												className="float-right"
												style={{ marginLeft: "10px" }}
												color="primary"
												type="button"
												onClick={() => this.approveInventory("approve")}
											>
												<span className="fa fa-check"> </span>
											</Button>
											<Button
												className="float-right"
												color="danger"
												type="button"
												onClick={() => this.approveInventory("disapprove")}
											>
												<span className="fa fa-times"> </span>
											</Button>
										</Alert>
									)}

									<ReactGrid2
										{...this.props}
										inventory={approval_inventory}
										pColumns={invColumns}
										setSelectedInventory={this.setSelectedInventory}
										selectedInv={selectedInv}
									/>
								</CardBody>
							</Card>
						</Col>
						<Col xl="3">
							{user && localStorage.getItem("user") && (
								<Chat chat_users={chat_users} />
							)}
						</Col>
					</Row>
				</Container>
			</>
		);
	}
}

const mapStateToProps = ({ inv, auth }) => {
	console.log("inv", inv);
	const { approval_inventory, chat_users, camp } = inv;
	const { user } = auth;
	return {
		approval_inventory,
		camp,
		chat_users,
		user,
	};
};

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			fetchApprovalInventory,
			approveInventory,
			fetchOpenChatUsers,
			fetchOpUserDetails,
		},
		dispatch
	);

export default connect(mapStateToProps, mapDispatchToProps)(ApproveInventory);
