import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";

const { SearchBar } = Search;
class BSTable extends React.Component {
	state = {};

	render() {
		const { columns, data, expandRowData } = this.props;
		return (
			<ToolkitProvider
				keyField="name"
				data={data}
				columns={columns}
				exportCSV
				condensed
				headerWrapperClasses="thead-light"
				wrapperClasses="table-responsive"
				search
			>
				{(props) => (
					<div>
						<SearchBar {...props.searchProps} />
						<hr />
						<BootstrapTable
							keyField="name"
							{...props.baseProps}
							rowStyle={{ backgroundColor: "white" }}
							expandRow={expandRowData}
							pagination={paginationFactory()}
						/>
					</div>
				)}
			</ToolkitProvider>
		);
	}
}

export default BSTable;
