import _ from "lodash";

export default (allowedPolicies) => {
	let user = localStorage.getItem("user");
	user = JSON.parse(user);

	if (!user) {
		return false;
	}

	const role = user.role;
	const policies = role.policies.map((p) => p.name);

	const commArr = _.intersection(allowedPolicies, policies);
	// console.log("Policies", policies,allowedPolicies,' comArr',commArr)
	if (commArr.length === 0) {
		return false;
	} else {
		return true;
	}
};
