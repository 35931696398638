import {
	FETCH_SUGG_INVENTORY,
	FETCH_NETWORKS,
	RESET_INVENTORY,
	FETCH_NEARBY_PLACES,
	FETCH_BOOKED_INVENTORY,
	FETCH_CHAT_USERS,
	FETCH_APPROVAL_INVENTORY,
	FETCH_BOOKED_INVENTORY_PENDING,
	FETCH_BOOKED_INVENTORY_SUCCESS,
	FETCH_MEDIA_OWNER_INVENTORY,
	ADD_INVENTORY,
	FETCH_ALL_MEDIA_OWNERS,
	UPLOAD_SITEMAPS_PROGRESS,
	SET_SITEMAPS_URLS,
	RESET_SITEMAPS_URLS,
	DELETE_PLANO_INVENTORY,
	FETCH_DISTINCT_CITY_STATES,
	ADD_PROPER_MEDIA_OWNER,
	FETCH_BOOKED_INVENTORY_ERROR,
	REMOVE_BOOKED_SITES,
	ADD_PROPER_MEDIA_OWNER_ERR,
	DISABLE_PLANO_INVENTORY,
} from "../actions/types";
import _ from "lodash";
const initialState = {
	error: null,
	fetching_booked: null,
	add_media_owner_err: null,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case FETCH_NETWORKS:
			return { ...state, error: "", networks: action.payload };

		case FETCH_SUGG_INVENTORY:
			return {
				...state,
				error: "",
				inventory: action.payload.plano_inventory,
				nearby_data: action.payload.nearby,
				total_count: action.payload.total_count,
				fetching_inventory: false,
			};

		case FETCH_BOOKED_INVENTORY_PENDING:
			return { ...state, fetching_booked: "pending" };

		case FETCH_BOOKED_INVENTORY_SUCCESS:
			return { ...state, fetching_booked: "completed", booking_err: null };

		case FETCH_BOOKED_INVENTORY_ERROR:
			return {
				...state,
				fetching_booked: "error",
				booking_err: action.payload,
			};

		case FETCH_BOOKED_INVENTORY:
			return {
				...state,
				booked_inventory: action.payload,
				fetching_booked: null,
			};

		case FETCH_NEARBY_PLACES:
			return { ...state, nearby_places: action.payload };

		case FETCH_CHAT_USERS:
			return { ...state, chat_users: action.payload };

		case RESET_INVENTORY:
			return { ...state, inventory: null, fetching_booked: null };

		case FETCH_APPROVAL_INVENTORY:
			return {
				...state,
				approval_inventory: action.payload.approvalInventory,
				camp: action.payload.camp,
			};

		case FETCH_MEDIA_OWNER_INVENTORY:
			return { ...state, vendor_inventory: action.payload };
		case ADD_INVENTORY:
			return {
				...state,
				added_inventory: action.payload.added,
				error_inventory: action.payload.error,
				vendor_inventory:
					action.payload.updated && action.payload.updated.length > 0
						? _.unionBy(
								action.payload.updated,
								state.vendor_inventory.concat(action.payload.added),
								"site_code"
						  )
						: state.vendor_inventory.concat(action.payload.added),
			};
		case FETCH_ALL_MEDIA_OWNERS:
			return { ...state, all_vendors: action.payload };
		case ADD_PROPER_MEDIA_OWNER:
			return {
				...state,
				all_vendors: state.all_vendors.concat(action.payload),
			};
		case ADD_PROPER_MEDIA_OWNER_ERR:
			return { ...state, add_media_owner_err: action.payload };
		case UPLOAD_SITEMAPS_PROGRESS:
			return { ...state, site_maps_upload_progress: action.payload };
		case SET_SITEMAPS_URLS:
			return { ...state, active_sitemaps_urls: action.payload };
		case RESET_SITEMAPS_URLS:
			return { ...state, active_sitemaps_urls: null };
		case DELETE_PLANO_INVENTORY:
			return {
				...state,
				vendor_inventory: [
					...state.vendor_inventory.filter(
						(f) => action.payload.indexOf(f._id) === -1
					),
				],
			};
		case DISABLE_PLANO_INVENTORY:
			return {
				...state,
				vendor_inventory: state.vendor_inventory.map((inv, i) =>
					action.payload.inventory.indexOf(inv._id) !== -1
						? { ...inv, planning_enabled: action.payload.planning_status }
						: inv
				),
			};
		case FETCH_DISTINCT_CITY_STATES:
			return { ...state, distinct_city_data: action.payload };
		case REMOVE_BOOKED_SITES:
			return {
				...state,
				booked_inventory: [
					...state.booked_inventory.filter(
						(f) => action.payload.indexOf(f._id) === -1
					),
				],
			};
		default: {
			return state;
		}
	}
};
