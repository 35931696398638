import React from "react";
import { Trans, Translation } from "react-i18next";

class ChatBox extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			message: "",
		};
	}

	onChange(e) {
		this.setState({
			message: e.target.value,
		});
	}

	onKeyUp(e) {
		if (e.key === "Enter") {
			if (this.state.message.length) {
				this.props.sendMessage({
					type: "message",
					text: this.state.message,
				});
				this.setState({ message: "" });
			} else {
				alert(<Trans>Please enter a message</Trans>);
			}
		}
	}

	render() {
		return (
			<div className="input-group chatbox">
				<Translation>
					{(t) => (
						<input
							className="form-control"
							placeholder={t("Type message")}
							value={this.state.message}
							onChange={this.onChange.bind(this)}
							onKeyUp={this.onKeyUp.bind(this)}
						/>
					)}
				</Translation>
			</div>
		);
	}
}

export default ChatBox;
