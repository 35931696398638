import React, { useRef, useEffect, useState } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
import Divider from "@material-ui/core/Divider";
import mapboxgl from "mapbox-gl";
import "../../assets/css/mapbox.css";

mapboxgl.accessToken =
	"pk.eyJ1Ijoic3B1bmVldDIzIiwiYSI6ImNraXBzczc0YTA3N28zMW55aTNzanQxZjkifQ.lmsF9R-Oml3Bl0BiAMwNZw";

const MapBox = (props) => {
	const mapContainerRef = useRef(null);

	const [zoom, setZoom] = useState(10);
	const [activeLayer, setLayer] = useState("Inventory");

	// Initialize map when component mounts
	useEffect(() => {
		// console.log("props", props);
		const map = new mapboxgl.Map({
			container: mapContainerRef.current,
			style: "mapbox://styles/mapbox/dark-v10",
			center: [Number(props.center.longitude), Number(props.center.latitude)],
			zoom: zoom,
		});

		// Add navigation control (the +/- zoom buttons)
		map.addControl(new mapboxgl.NavigationControl(), "top-right");

		map.on("move", () => {
			setZoom(map.getZoom().toFixed(2));
		});

		// ----- Show colorful dataset circles ----- //
		map.on("load", function () {
			if (activeLayer === "Inventory") {
				map.addSource("population", {
					type: "vector",
					url: "mapbox://spuneet23.ckiy5p0sh3ft325lf924yetk0-0z227",
				});

				var pop1 = ["<", ["get", "pop_index"], 0.2];
				var pop2 = [
					"all",
					[">=", ["get", "pop_index"], 0.2],
					["<", ["get", "pop_index"], 0.4],
				];
				var pop3 = [
					"all",
					[">=", ["get", "pop_index"], 0.4],
					["<", ["get", "pop_index"], 0.6],
				];
				var pop4 = [
					"all",
					[">=", ["get", "pop_index"], 0.6],
					["<", ["get", "pop_index"], 0.8],
				];

				var colors = ["#CDDC39", "#C0CA33", "#AFB42B", "#9E9D24", "#827717"];

				map.addLayer({
					id: "population",
					type: "circle",
					source: "population",
					"source-layer": "planov2",
					paint: {
						// make circles larger as the user zooms from z12 to z22
						"circle-radius": {
							base: 2,
							stops: [
								[12, 2],
								[22, 180],
							],
						},
						// color circles by ethnicity, using a match expression
						// https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-match
						"circle-color": [
							"case",
							pop1,
							colors[0],
							pop2,
							colors[1],
							pop3,
							colors[2],
							pop4,
							colors[3],
							colors[4],
						],
					},
				});

				map.on("click", function (e) {
					var features = map.queryRenderedFeatures(e.point, {
						layers: ["population"], // replace this with the name of the layer
					});

					if (!features.length) {
						return;
					}
				});
			} else {
				var vis_colors = [
					"#F5F5DC",
					"#FDF44E",
					"#B5A642",
					"#E4D00C",
					"#9ACD32",
				];
				colors = ["#CDDC39", "#C0CA33", "#AFB42B", "#9E9D24", "#827717"];

				pop1 = ["<", ["to-number", ["get", activeLayer]], 0.2];
				pop2 = [
					"all",
					[">=", ["to-number", ["get", activeLayer]], 0.2],
					["<", ["to-number", ["get", activeLayer]], 0.4],
				];
				pop3 = [
					"all",
					[">=", ["to-number", ["get", activeLayer]], 0.4],
					["<", ["to-number", ["get", activeLayer]], 0.6],
				];
				pop4 = [
					"all",
					[">=", ["to-number", ["get", activeLayer]], 0.6],
					["<", ["to-number", ["get", activeLayer]], 0.8],
				];

				map.addSource("cyber-hub", {
					type: "vector",
					url: "mapbox://spuneet23.ckj6qr4ph0dwa25qujhrcg48l-8j4j2",
				});

				map.addLayer({
					id: "cyber-hub",
					type: "circle",
					source: "cyber-hub",
					"source-layer": "cyber-hub",
					paint: {
						// make circles larger as the user zooms from z12 to z22
						"circle-radius": {
							base: 2,
							stops: [
								[12, 2],
								[22, 180],
							],
						},
						// color circles by ethnicity, using a match expression
						// https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-match
						"circle-color": [
							"case",
							pop1,
							vis_colors[0],
							pop2,
							vis_colors[1],
							pop3,
							vis_colors[2],
							pop4,
							vis_colors[3],
							vis_colors[4],
						],
					},
				});

				map.on("click", function (e) {
					var features = map.queryRenderedFeatures(e.point, {
						layers: ["cyber-hub"], // replace this with the name of the layer
					});

					if (!features.length) {
						return;
					}
				});
			}
		});

		// Clean up on unmount
		return () => map.remove();
	}, [activeLayer]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div>
			<div style={{ height: "800px", width: "100%" }} ref={mapContainerRef} />
			<div
				style={{
					width: "300px",
					background: "white",
					color: "rgb(51 16 16)",
					position: "absolute",
					top: "120px",
					borderRadius: "5px",
					left: "50px",
				}}
			>
				<List component="nav" aria-label="main mailbox folders">
					<ListItem button onClick={() => setLayer("Inventory")}>
						<ListItemText primary="Inventory" />
						{activeLayer === "Inventory" && (
							<ListItemIcon>
								<Icon>remove_red_eye</Icon>
							</ListItemIcon>
						)}
					</ListItem>
					<Divider />

					<ListItem button onClick={() => setLayer("Footfall")}>
						<ListItemText primary="Footfall" />
						{activeLayer === "Footfall" && (
							<ListItemIcon>
								<Icon>remove_red_eye</Icon>
							</ListItemIcon>
						)}
					</ListItem>
					<Divider />
					<ListItem button onClick={() => setLayer("Affluence")}>
						<ListItemText primary="Affluence" />
						{activeLayer === "Affluence" && (
							<ListItemIcon>
								<Icon>remove_red_eye</Icon>
							</ListItemIcon>
						)}
					</ListItem>
					<Divider />
					<ListItem button onClick={() => setLayer("Visibility")}>
						<ListItemText primary="Visibility" />
						{activeLayer === "Visibility" && (
							<ListItemIcon>
								<Icon>remove_red_eye</Icon>
							</ListItemIcon>
						)}
					</ListItem>
					<Divider />
					<ListItem button onClick={() => setLayer("Accessibility")}>
						<ListItemText primary="Accessibility" />
						{activeLayer === "Accessibility" && (
							<ListItemIcon>
								<Icon>remove_red_eye</Icon>
							</ListItemIcon>
						)}
					</ListItem>
					<Divider />
				</List>
			</div>
		</div>
	);
};

export default MapBox;
